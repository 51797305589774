import React from 'react';
import NewSwiper from './NewSwiper';

export default function New() {
    return (
        <section className='new'>
            <div className="container">
                <h3 className="new_title">GROUP TOURS 2024</h3>
                <NewSwiper />
            </div>
        </section>
    )
}
