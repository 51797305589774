import React from 'react'
import Header from '../Home/Header';
import OneDayBg from './OneDayBg';
import OneDayTours from './OneDayTours';
import RightModalOneDay from './RightModalOneDay';
import Footer from '../Home/Footer';
import '../../css/tours.css';
import BackToTopButton from '../Home/BackToTopButton'

export default function OneDay() {
    return (
        <div>
            <Header />
            <OneDayBg />
            <div className="container displaycontainer">
                <OneDayTours />
                <RightModalOneDay />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
