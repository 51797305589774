import React from 'react'
import Header from '../Home/Header'
import HorsebackBg from './HorsebackBg'
import HorsebackTours from './HorsebackTours'
import RightModalHorseback from './RightModalHorseback'
import Footer from '../Home/Footer'
import '../../css/tours.css'
import BackToTopButton from '../Home/BackToTopButton'

export default function Horseback() {
  return (
    <div>
      <Header />
      <HorsebackBg />
      <div className="container displaycontainer">
        <HorsebackTours />
        <RightModalHorseback />
      </div>
      <Footer />
      <BackToTopButton />
    </div>
  )
}
