import React from 'react';
import Header from '../../Home/Header';
import AroundBg from './ArounBg';
import AroundTravel from './AroundTravel';
import RightModalAround from './RifgtModalAround';
import Footer from '../../Home/Footer'
import '../../../css/kyrgizstan/kyrgyzstan.css';
import BackToTopButton from '../../Home/BackToTopButton';

export default function Around() {
    return (
        <div>
            <Header />
            <AroundBg />
            <div className="container displaycontainer">
                <AroundTravel />
                <RightModalAround />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
