import React from 'react'
import RightModal from '../RightModal';
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg4 from '../../images/CardImg/card_img_4.jpg';
import Cardimg5 from '../../images/CardImg/card_img_5.jpg';
import Cardimg6 from '../../images/CardImg/card_img_6.jpg';



export default function RightModalBlog() {
    return (

        <section className='rightmodalblog'>

            <RightModal />

            <h4 className="contact_title">Most popular tours</h4>

            <div className="contact_bg_block">
                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg4} alt="" />
                    </div>

                    <h1 className='contact_text'>Family traveling in Kyrgyzstan.</h1>
                    <p className='contact_suptitle'><p class="excerpt">Family tours</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">9 days</div>
                    </div>
                </div>

                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg5} alt="" />
                    </div>

                    <h1 className='contact_text'>Children’s Quiz Tour</h1>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">10 days</div>
                    </div>
                </div>

                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg6} alt="" />
                    </div>

                    <h1 className='contact_text'>Off road adventure in Kyrgyzstan</h1>
                    <p className='contact_suptitle'><p class="excerpt">Off road adventure</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">9 days</div>
                    </div>
                </div>
            </div>
        </section>
    )
}
