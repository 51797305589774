import React from 'react';
import Header from '../Home/Header';
import PhotoBg from './PhotoBg';
import PhotoTours from './PhotoTours';
import RightModalPhoto from './RightModalPhoto';
import Footer from '../Home/Footer';
import '../../css/tours.css';
import BackToTopButton from '../Home/BackToTopButton'

export default function Photo() {
    return (
        <div>
            <Header />
            <PhotoBg />
            <div className="container displaycontainer">
                <PhotoTours />
                <RightModalPhoto />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
