import React from 'react'
import '../../css/group_tours.css'
import Header from '../Home/Header'
import Upcoming from './Upcoming'
import Trip from './Trip'
import BookTour from './BookTour'
import LeftModal from './LeftModal'
import Footer from '../Home/Footer'
import BackToTopButton from '../Home/BackToTopButton'

export default function GroupTours() {
  return (
    <div>
      <Header />
      <Upcoming />
      <div className="container displaycontainer">
        <div>
          <Trip />
          <BookTour />
        </div>
        <LeftModal />
      </div>
      <Footer />
      <BackToTopButton />
    </div>
  )
}
