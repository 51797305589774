import React from 'react';
// react awesome
import { LuClock9 } from "react-icons/lu";
import { Link } from 'react-router-dom';

export default function Deals() {
    return (
        <section className='deals'>
            <div className="container">
                <h1 className='deals_title'>DEALS AND DISCOUNTS</h1>

                <div className="delas_scrol">
                    <div className="deals_block">
                        <Link to="/datas" className="deals_card_bg">
                            <div className="deals_img">
                                <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2019/03/Express-Trip-Songkol-660x360.jpg" alt="" />
                                <div className="deals_geen">
                                    <div className='deals_geen_text'>from <br />$200</div>
                                </div>
                            </div>

                            <h1 className='deals_text'>Express trip to the Lake Song-Kul.</h1>
                            <p className='deals_suptitle'>Horseback tour</p>

                            <hr className='deals_hr' />
                            <div className="deals_day_num">
                                <div className="deals_day"><LuClock9 color="#005271" size="18px" /></div>
                                <div className="deals_num">2 days</div>
                            </div>
                        </Link>

                        <Link to="/datas" className="deals_card_bg">
                            <div className="deals_img">
                                <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2019/03/n1-660x360.jpg" alt="" />
                                <div className="deals_geen">from <br />$150</div>
                            </div>

                            <h1 className='deals_text'>Express trip to the Lake Song-Kul.</h1>
                            <p className='deals_suptitle'>Horseback tour</p>

                            <hr className='deals_hr' />
                            <div className="deals_day_num">
                                <div className="deals_day"><LuClock9 color="#005271" size="18px" /></div>
                                <div className="deals_num">2 days</div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}
