import React from 'react';
import Header from '../../Home/Header';
import ContactusBg from './ContactusBg';
import ConctausTravel from './ConctausTravel';
import RightModalContactus from './RightModalContactus';
import Footer from '../../Home/Footer';
import '../../../css/kyrgizstan/kyrgyzstan.css'
import BackToTopButton from '../../Home/BackToTopButton';

export default function Contactus() {
    return (
        <div>
            <Header />
            <ContactusBg />
            <div className="container displaycontainer">
                <ConctausTravel />
                <RightModalContactus />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
