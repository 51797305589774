import React from 'react';
import Cardimg47 from '../../../images/CardImg/card_img_47.jpg';
import Carlogo from '../../../images/card_logo.png';
import { Link } from 'react-router-dom';

export default function AroundTravel() {
    return (
        <section className="aroundtravel">
            <div className="aroundtravel_bg">
                <div className="aroundtravel_bg_vidyo"><iframe width="100%" height="400" src="https://www.youtube.com/embed/twqrx2pbJnY?si=QibvCM0ILa_qe9a5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>

                <h4 className="aroundtravel_title">The transport system in Kyrgyzstan</h4>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">The transport system in <a href="" className="aroundhref">Kyrgyzstan</a>  is one of the best-developed ones. The movement in Kyrgyzstan is right-hand (steering wheel on the left).</p>
                    <p className="aroundtravelsuptitle">The most suitable way to travel around the country is by bus, taxis, and taxis. You can ride them both in large cities and between cities. Often, this is the only way to get to the right place.</p>
                    <p className="aroundtravelsuptitle">The bus fleet of <a href="" className="arounhref">Bishkek</a> was updated in 2009 and today is represented by quite comfortable buses.</p>
                    <p className="aroundtravelsuptitle">Taxis operate in all cities and towns of Kyrgyzstan. Tariffs are initially very low, it is recommended to call a corporate taxi, and not a private trader (more comfortable and safer).</p>
                    <p className="aroundtravelsuptitle">By the wat road network is poorly developed, good asphalt roads go from Bishkek to Osh, Almaty, and Balykchy, and a good asphalt road and the road to Torugart in the direction of China are also laid around Issyk-Kul Lake. In winter, the movement is hampered by drifts on the passes. The roads are either broken asphalt or graveled or just unpaved roads. However, mountain roads are usually specially converted private trucks (crews) drive.
                        Many places can only be reached by helicopter, on horseback or on foot.</p>
                </div>
                <h4 className="aroundtravel_title">AVIATION</h4>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">International airport “Manas” is located near the capital of Kyrgyzstan – Bishkek. Domestic flights to Osh, Batken, and Jalal-Abad are operated from Bishkek.</p>
                    <p className="aroundtravelsuptitle">Main part of the country’s fleet is the old, leased Boeing-737 or remaining from the time of the USSR – airplanes.</p>
                    <p className="aroundtravelsuptitle">Air Bishkek (www.air.kg)</p>
                    <p className="aroundtravelsuptitle">Air Kyrgyzstan (www.air.kg)</p>
                    <p className="aroundtravelsuptitle">Avia Traffic (www.aero.kg)</p>
                    <p className="aroundtravelsuptitle">Sky Bishkek (https://skybishkek.kg)</p>
                </div>
                <h4 className="aroundtravel_title">RAILWAY TRANSPORT</h4>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">Passenger train message was kept only in the north of the country, near Bishkek. From there, there are three long-distance trains to Moscow, Ekaterinburg, and Novokuznetsk, and there is a suburban connection to the west, to Kazakhstan (in the direction of Merke, before traveling 3639 km), to the east (to Tokmak station) and a local train to Rybachye (Balykchy) on the lake Issyk-Kul.</p>
                </div>
                <h4 className="aroundtravel_title">WATER CARRIAGE</h4>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">Since there are very few navigable rivers in the country, only small private boats and boats are present. On the <a href="" className="aroundhref">Issyk-Kul lake</a>  runs the ship.</p>
                </div>
                <h4 className="aroundtravel_title">CAR RENTAL</h4>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">As a rule, rental cars are provided without a driver. At the request of the tourist can rent a car with a driver.</p>
                    <p className="aroundtravelsuptitle">To get a car for rent you will need the following documents:</p>
                </div>
                <ul className="aroundul">
                    <li className='aroundli'>foreigners need a passport and a document confirming the legality of their stay in the country;</li>
                    <li className='aroundli'>International driver’s license.</li>

                </ul>
                <h5 className="around_acardion_title">
                    SHUTTLE BUSES
                </h5>

                {/* acardion_start */}

                <div className="acardion">
                    <div className="acardion_item">
                        <input className='acardion_input' type="checkbox" id='acardion_1' />
                        <label className='acardion_title' for="acardion_1"></label>
                        <div className="acardion_card">
                            <div className="acardion_left_right">
                                <div className="acardion_left">
                                    <h2 className="acardion_left_title">Every week bus from Bishkek to Songkol and around Issyk-Kol</h2>
                                    <div className="acardion_left_img">
                                        <div className='acardion_foto'>
                                            <img src={Cardimg47} alt="" />
                                        </div>
                                        <a href="#" className="acardion_left_img_title">Trip to Song-Kol & Karakol every week</a>
                                        <p className='acardion_left_img_suptitle'>Where the world meets You want to experience this special region of Kyrgyzstan. But what if you don’t have the …<a href="">Continue reading </a></p>
                                        <div className="acardion_logo_text">
                                            <Link to="/" className="acardion_logo">
                                                <img src={Carlogo} alt="" />
                                            </Link>
                                            <Link to="/" className="acardion_lo_text">Kyrgyzstan Tourism</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="acardion_right">
                                    <div className="acardion_right_text">
                                        <p className="acardion_right_suptitle">Where the World Meets</p>
                                        <p className="acardion_right_suptitle">You want to experience this special region of Kyrgyzstan. But what if you don’t have the time? We offer a week-long short trip to the most favorite destinations in the Song-Kol and Karakol regions at an affordable price.</p>
                                    </div>

                                    <div className="acardion_right_text">
                                        <p className="acardion_right_suptitle">With the Kyrgyz Tourism project “Trip to Song-Kol & Karakol Every Week,” you can combine all the must-visit places in the Song-Kol and Karakol region on a map and learn about transportation options from one place to another during your travel. Share your ideas, get advice from other travelers, and explore Kyrgyzstan.</p>
                                    </div>
                                    <div className="acardion_right_text">
                                        <p className="acardion_right_suptitle">Check out our offer now!</p>
                                    </div>
                                    <div className="acardion_right_text">
                                        <p className="acardion_right_suptitle">Itinerary: Bishkek – Burana Tower – Song-Kol Lake – Bokonbaevo – Karakol – Altyn-Arashan – Bishkek</p>
                                        <p className="acardion_right_suptitle">Accommodation: Two overnights in the yurt camp, two overnights in guesthouses Duration: 5 days</p>
                                        <p className="acardion_right_suptitle">Best Season: June – August</p>
                                        <p className="acardion_right_suptitle">Category: Combined Auto/Trek</p>
                                        <p className="acardion_right_suptitle">Level of Difficulty: Easy to medium</p>
                                        <p className="acardion_right_suptitle">Group Size: 4-16 people</p>
                                        <p className="acardion_right_suptitle">Highlights: Burana, Song-Kol Lake,</p>
                                        <p className="acardion_right_suptitle">Barskoon, Jeti-Oguz, Canyon Skazka,</p>
                                        <p className="acardion_right_suptitle">Altyn-Aсв rashan</p>
                                        <p className="acardion_right_suptitle">Requirements: Weatherproof warm clothes, trekking boots, hat, sunglasses, and sunscreen</p>
                                        <p className="acardion_right_suptitle">We will help you see the main highlights of Kyrgyzstan within a certain time and budget. Explore the route!</p>
                                    </div>
                                    <div className="acardion_right_text">
                                        <p className="acardion_right_suptitle">→ The tour will be activated if the minimum number of participants is at least four (4 people). If the number of participants does not reach 4 people, the tour will be canceled.</p>
                                    </div>
                                </div>
                            </div>

                            <iframe className='acardion_globe' src="https://www.google.com/maps/d/embed?mid=1ofhLjSJU_tQJHyIcqIbdlHbLFtuMglg&ehbc=2E312F&noprof=1" width="640"></iframe>
                        </div>
                    </div>
                </div>

                <div className="acardion_1">
                    <div className="acardion_item_1">
                        <input className='acardion_input_1' type="checkbox" id='acardion_2' />
                        <label className='acardion_title_1' for="acardion_2"></label>

                        <div className="acardion_card_1">
                            <div className="acardion_left_right_1">
                                <h3 className="acardion_1_left_suptitle">If you’re looking to travel from Bishkek to Osh, you can arrange for private transportation with us. The journey covers a road distance of approximately 700 kilometers and typically takes around 12-13 hours to complete, mainly due to the presence of mountain passes and valleys. Rest assured, we offer the services of skilled drivers and comfortable vehicles to ensure a safe and enjoyable trip.</h3>

                                <h3 className="acardion_1_right_suptitle">The schedule is agreed and the total cost of the price for the minivan is $800 USD. The van has 6 seats and the price can be shared among the passengers.</h3>

                            </div>

                            <iframe className='acardion_globe' src="https://www.google.com/maps/d/embed?mid=1LPJ3dWYb6fmYUxS-NC6VYfnC72RemSE&ehbc=2E312F&noprof=1" width="640"></iframe>
                        </div>
                    </div>
                </div>
                {/* acardion_end */}

                <h5 className="around_acardion_title">
                    PUBLIC TRANSPORT
                </h5>

                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">Train Station</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">Telephone: (0312) 92-68-54, (0312) 30-02-09, (0312) 92-68-40, (0312) 30-06-18</p>
                    <p className="aroundtravelsuptitle">Train schedule</p>
                    <p className="aroundtravelsuptitle">Suburban passenger train on the route “Bishkek I – Kara-Balta” “Kara-Balta – Bishkek II”
                        This route will run two times a day.</p>
                    <p className="aroundtravelsuptitle">Train No. 6059 “Bishkek I – Kara-Balta”, departure time from the station “Bishkek I” ( Pishpek ), local time at 08:13 a.m. and arrival at Kara-Balta station 09:49 a.m.</p>
                    <p className="aroundtravelsuptitle">Train No. 6061 “Bishkek II – Kara-Balta”, departure time from the station “Bishkek II” local time at 12:59 p.m. and arrival at Kara-Balta station at 02:47 p.m.</p>
                    <p className="aroundtravelsuptitle">From Kara-Balta station, train No.6060 Kara-Balta-Bishkek II, the departure time is 10:19 a.m. and the arrival time at Bishkek II station is 12:10 p.m.</p>
                    <p className="aroundtravelsuptitle">Train No. 6062 Kara-Balta – Bishkek I, the departure time is the local time at 03:22 p.m., and the arrival time at the station “Bishkek I” ( Pishpek ) is 05:01 p.m.</p>
                    <p className="aroundtravelsuptitle">Fare:</p>
                    <p className="aroundtravelsuptitle">Adult ticket: 26 soms</p>
                    <p className="aroundtravelsuptitle">Children’s ticket: 9 som ( from 5 to 10 years)</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">In addition, the Bishkek-Merke, Merke Bishkek, and Bishkek- Tokmok, Tokmok -Bishkek trains run daily.</p>
                    <p className="aroundtravelsuptitle">Train No. 6050 “Bishkek I – Tokmok ” time departed according to local time from the station “Bishkek I” ( Pishpek ) at 05:20 pm, the time of arrival at Tokmok station 07:37 p.m.</p>
                    <p className="aroundtravelsuptitle">Train No. 6051 “ Tokmok – Bishkek I” time departed local time from the station “ Tokmok ” at 05:03 a.m. and the time of arrival at the station “Bishkek I” ( Pishpek ) is 07:20 a.m.</p>
                    <p className="aroundtravelsuptitle">Fare:</p>
                    <p className="aroundtravelsuptitle">Adult ticket: 26 soms</p>
                    <p className="aroundtravelsuptitle">Children’s ticket: 09 som ( from 5 to 10 years)</p>
                </div>

                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">Train No. 6063 “Bishkek II – Merke” time departed according to local time from Bishkek II station at 05:31 p.m. and the time of arrival at the station “Merke” at 09:31 p.m.</p>
                    <p className="aroundtravelsuptitle">Train No. 6064 “Merke – Bishkek II” time departed local time from the station “Merke” at 03:34 a.m. and the time of arrived at the station “Bishkek II” at 07:31 a.m.</p>
                    <p className="aroundtravelsuptitle">Fare:</p>
                    <p className="aroundtravelsuptitle">Adult ticket: 57 soms</p>
                    <p className="aroundtravelsuptitle">Children’s ticket: 26 soms ( from 5 to 10 years)</p>
                </div>

                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">In the summer tourist season will run the tourist train “Bishkek – Balykchy”, “Balykchy – Bishkek”.</p>
                    <p className="aroundtravelsuptitle">From 16 June to 30 July, on Friday, Saturday, Sunday, and from 31 July to 31 August, daily.
                        Train No. 608 “Bishkek I – Balykchy” time departed according to local time from the station “Bishkek I” at 06:25 a.m. and the time of arrival at the station Balykchy is 11:20 a.m.</p>
                    <p className="aroundtravelsuptitle">Train No. 609 “Balykchy – Bishkek I” the departure time local time from Rybachye station at 05:08 p.m. and the arrival time at Bishkek I station is 21 hours 58 minutes.</p>
                    <p className="aroundtravelsuptitle">Fare:</p>
                    <p className="aroundtravelsuptitle">Adult ticket: 69 soms</p>
                    <p className="aroundtravelsuptitle">Children’s ticket: 34 soms ( from 5 to 10 years)</p>
                </div>

                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">Train Bishkek – Tashkent</p>
                </div>

                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">Train route Tashkent – Bishkek – Balykchy – Bishkek – Tashkent will run every week on Thursdays from the capital of Uzbekistan (Tashkent) and arrive on Friday in Bishkek and then go to Balykchy , and Saturday back to Bishkek further to Tashkent . On Sunday it will arrive in Tashkent.</p>
                    <p className="aroundtravelsuptitle">The train is designed for about 300 passengers, in its composition – 7 compartments, 3 economy-class and one car-restaurant. If there are more people willing to go on a trip, additional cars will be attached to it.</p>
                    <p className="aroundtravelsuptitle">Fare:</p>
                    <p className="aroundtravelsuptitle">In the second-class carriage 2500 soms (35 USD) per person.</p>
                    <p className="aroundtravelsuptitle">In compartment – 3378 soms (50 USD) per person.</p>
                    <p className="aroundtravelsuptitle">In the luxury car – from 6432 soms (90 USD) per person.</p>
                    <p className="aroundtravelsuptitle">In its final point – the town of Balykchy on the coast of Issyk-Kul – the composition comes in 24 hours. Not so fast, but comfortable and safe.</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">Bishkek West Bus Station</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">The state enterprise “ Bishkek Bus Station” takes orders for collective and tourist trips on comfortable buses to any locality of the Kyrgyz Republic and neighboring countries.</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">Schedule</p>
                    <p className="aroundtravelsuptitle">The cashier from 07:00 a.m. to 11:30 p.m. without a break</p>
                    <p className="aroundtravelsuptitle">Help desk from 07:00 a.m. to 18: 30 p.m.</p>
                    <p className="aroundtravelsuptitle">lunch break: 01:00 p.m. – 02:00 p.m.</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">Telephones :</p>
                    <p className="aroundtravelsuptitle">0312 344696 Information Office</p>
                    <p className="aroundtravelsuptitle">0312 344589 Dispatch</p>
                    <p className="aroundtravelsuptitle">0312 344835 Cashier</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">How to get to the western bus station?</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">Bus № 7, 35 stop “Western Bus Station”</p>
                    <p className="aroundtravelsuptitle">Trolleybus № 6 stop “Western Bus Station”</p>
                    <p className="aroundtravelsuptitle">Marshrutka (Minibus) № 111, 11 4 , 129, 132, 21 4, 224, 275, 326, 327,</p>
                    <p className="aroundtravelsuptitle">330, 344, 350, 358, 386, 908 stop “Western Bus Station”</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">Rules for using the services of the Bishkek West Bus Station</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">The Bishkek Bus Station is a transport infrastructure facility designed to provide services to passengers and carriers when carrying out regular passenger and baggage traffic.</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">Mode of operation of the Bishkek bus station around the clock.</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">Regular passenger and baggage transportation is carried out in accordance with the consolidated schedule located on the 1st floor of the bus station building.</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">Bishkek bus station includes the main building of the bus station and the technological area for transport and passengers.</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">The main bus station building houses:</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">– administration of the bus station;</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">– the ticket office for ticket sales and payment of baggage;</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">– a waiting room for passengers (cash room);</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">– reference service;</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">– room of mother and child;</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">– luggage storage;</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">– office for pre-trip,</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">inter-flight medical examination of drivers;</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">– Cafe;</p>
                </div>
                <div className="arountravel_text">
                    <p className="aroundtravelsuptitle">– kiosk,</p>
                </div>

                <hr className='arounhr' />
                <div className="aroun_btn">
                    <button>VIEW ALL TOURS</button>
                </div>
            </div>
        </section>
    )
}
