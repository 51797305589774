import React from 'react'

export default function BookTour() {
    return (
        <section id='book' className='booktour'>
            <h3 className='booktour_title'>Book tour</h3>
            <div className="booktour_inputs">
                <div className="booktour_tour">
                    <input type="search" placeholder='Tour' />
                </div>
                <div className="booktour_tour">
                    <input type="search" placeholder='Your name' />
                </div>
                <div className="booktour_tour">
                    <input type="search" placeholder='Your email' />
                </div>
                <div className="booktour_message">
                    <input type="search" placeholder='Your message' />
                </div>
                <div className="booktour_btn">
                    <button>Send</button>
                </div>
            </div>
        </section>
    )
}
