import React from 'react'

export default function TurkmenistanDestinations() {
    return (
        <section className="turkmenistan_destinations">
            <div className="turkmenistan_destinations_bg">
                <p className="turkmenistan_text">
                    Turkmenistan can boast a pretty unusual combination of diverse nature, great culture, and a large number of historical remnants. So, Turkmenistan tourism may be various. Tourists can visit the amazing cities where bazaars and festivals exist along with ancient buildings. This is where the past meets today.
                </p>

                <div className="turkmenistan_text">For nature lovers, there are fascinating sights of the Karakum Desert, Kyrk Molla Hill, and many others. Staying in the most comfortable hotels, setting up camps, and riding horses bring tons of great emotions and guarantee a great adventure.</div>
                <div className="turkmenistan_text">Reasons to make a trip to Turkmenistan</div>
                <div className="turkmenistan_text">Turkmenistan tourism is a great opportunity to have interesting and memorable holidays. It’s a wonderful land with a great spirit of old times and the Silk Road, which is in every corner of this place. There are a lot of advantages that may ensure anyone’s trip to Turkmenistan:</div>
                <div className="turkmenistan_block_text">
                    <div className="turkmenistan_text">The history. Many historical attractions of Turkmenistan have survived. Many cities of Turkmenistan contain some parts of their history. For example, you may visit the ancient city of Nisa which was a Parthian state capital thousands of years ago.</div>
                    <div className="turkmenistan_text">Numerous natural wonders. Turkmenistan has pretty interesting landscapes. There are many grand canyons, the Karakum Desert, and the Caspian Sea. An example is the famous always-burning Darvaza gas crater. It looks incomparable, especially at night or evening.</div>
                    <div className="turkmenistan_text">Amazing nation. People in Turkmenistan are very friendly and keep the old traditions. Be ready for a large amount of carpets, awesome meat and fish cuisine, horse riding, and many other great activities of the local people.</div>
                </div>
            </div>
        </section>
    )
}
