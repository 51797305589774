import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../../css/newswiper.css';
import { Pagination, Navigation } from 'swiper/modules';
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg47 from '../../images/CardImg/card_img_47.jpg';
import Cardimg13 from '../../images/CardImg/card_img_13.jpg';
import Cardimg51 from '../../images/CardImg/card_img_51.jpg';

export default function NewSwiper() {
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 760);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth >= 500);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <Swiper
                slidesPerView={1}
                breakpoints={{

                    760: {
                        slidesPerView: 2
                    },
                    990: {
                        slidesPerView: 3
                    }
                }}
                spaceBetween={-30}
                loop={true}
                navigation={isWideScreen}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <Link to="/datas" className="new_card_bg">
                        <div className="new_img">
                            <img src={Cardimg47} alt="" />
                            <div className="new_geen">from <br />$390</div>
                        </div>

                        <h1 className='new_text'>Trip to Song-Kol & Karakol every week</h1>
                        <p className='new_suptitle'><p class="excerpt"><p class="excerpt">Song-Kol &amp; Karakol</p></p></p>

                        <div className="new_hr_day">
                            <hr className='new_hr' />
                            <div className="new_day_num">
                                <div className="new_day"><LuClock9 color="#005271" size="18px" /></div>
                                <div className="new_num">5 days</div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>

                <SwiperSlide>
                    <Link to="/datas" className="new_card_bg">
                        <div className="new_img">
                            <img src={Cardimg13} alt="" />
                            <div className="new_geen">from <br />$150</div>
                        </div>

                        <h1 className='new_text'>Nomadic horse trek</h1>
                        <p className='new_suptitle'><p class="excerpt">Horseback tour</p></p>

                        <div className="new_hr_day">
                            <hr className='new_hr' />
                            <div className="new_day_num">
                                <div className="new_day"><LuClock9 color="#005271" size="18px" /></div>
                                <div className="new_num">9 days</div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>

                <SwiperSlide>
                    <Link to="/datas" className="new_card_bg">
                        <div className="new_img">
                            <img src={Cardimg51} alt="" />
                            <div className="new_geen">from <br />$150</div>
                        </div>

                        <h1 className='new_text'>“Discovering Chatkal: Unveiling Nature’s Hidden Treasures Expedition”</h1>
                        <p className='new_suptitle'><p class="excerpt">Expedition</p></p>

                        <div className="new_hr_day">
                            <hr className='new_hr' />
                            <div className="new_day_num">
                                <div className="new_day"><LuClock9 color="#005271" size="18px" /></div>
                                <div className="new_num">5 days</div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>
            </Swiper>
        </>
    )
}
