import React from 'react'
import '../../css/blog.css'
import Header from '../Home/Header'
import BlogBg from './BlogBg'
import Archive from './Archive'
import RightModalBlog from './RightModalBlog'
import Footer from '../Home/Footer'
import BackToTopButton from '../Home/BackToTopButton'

export default function Blog() {
  return (
    <div>
      <Header />
      <BlogBg />
      <div className="container displaycontainer">
        <Archive />
        <RightModalBlog />
      </div>
      <Footer />
      <BackToTopButton />
    </div>
  )
}
