import React from 'react';
import { Link } from 'react-router-dom';
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg36 from '../../images/CardImg/card_img_36.png';
import Cardimg37 from '../../images/CardImg/card_img_37.jpg';
import Cardimg20 from '../../images/CardImg/card_img_20.jpg';
import Cardimg22 from '../../images/CardImg/card_img_22.jpg';
import Cardimg11 from '../../images/CardImg/card_img_11.jpg';
import Cardimg31 from '../../images/CardImg/card_img_31.jpg';
import Cardimg23 from '../../images/CardImg/card_img_23.jpg';
import Cardimg6 from '../../images/CardImg/card_img_6.jpg';


export default function PhotoTours() {
    return (
        <section className='phototours'>

<h1 className="toptravel_title">Top Photo travel deals</h1>

<div className="toptravelscrol">

    <div className="toptravel_block">
        <h2 className="toptravel_departng">Departing</h2>
        <h2 className="toptravel_trip">Trip</h2>
        <h2 className="toptravel_fotos">Fotos</h2>
        <h2 className="toptravel_days">Days</h2>
        <h2 className="toptravel_from">From USD</h2>
    </div>

    <div className="toptravel_bg">

        <div className="toptravel_card">
            <div className="toptravel_card_num">02 Feb 2024</div>
            <div className="toptravel_card_trip">
                <h1 className="toptravel_card_title">Okavango Experience</h1>
                <p className="toptravel_card_suptitle">Johannesburg → Victoria Falls</p>
            </div>
            <div className="toptravel_card_img">
                <img src={Cardimg36} alt="" />
            </div>
            <div className="toptravel_card_day">10</div>
            <div className="toptravel_card_price">
                <span className="toptravel_card_discount">1,500</span>
                <div className="toptravel_card_cost">$1,299</div>
            </div>

            <div className="toptravel_card_view">View trip ›</div>
        </div>

        <div className="toptravel_card toptravel_card_border">
            <div className="toptravel_card_num">02 Feb 2024</div>
            <div className="toptravel_card_trip">
                <h1 className="toptravel_card_title">Okavango Experience</h1>
                <p className="toptravel_card_suptitle">Johannesburg → Victoria Falls</p>
            </div>
            <div className="toptravel_card_img">
                <img src={Cardimg37} alt="" />
            </div>
            <div className="toptravel_card_day">10</div>
            <div className="toptravel_card_price">
                <span className="toptravel_card_discount">1,500</span>
                <div className="toptravel_card_cost">$1,299</div>
            </div>

            <div className="toptravel_card_view">View trip ›</div>
        </div>
    </div>
</div>
            
            <div className="biketours_block ">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg36} alt="" />
                    </div>

                    <h1 className='biketours_text'>“Kyrgyzstan Unveiled: Photographic Odyssey”</h1>
                    <p className='biketours_suptitle'><p class="excerpt">photo tours</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">14 day</div>
                    </div>
                </Link>

                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg37} alt="" />
                    </div>

                    <h1 className='biketours_text'>Wildlife photography </h1>
                    <p className='biketours_suptitle'><p class="excerpt">Experience tours</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">12 day</div>
                    </div>
                </Link>

                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg20} alt="" />
                    </div>

                    <h1 className='biketours_text'>Express trip to the Lake Song-Kul.</h1>
                    <p className='biketours_suptitle'><p class="excerpt"></p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">2 day</div>
                    </div>
                </Link>
            </div>
            <div className="biketours_block pt-[30px]">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg22} alt="" />
                    </div>

                    <h1 className='biketours_text'>Pamir Highway</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Pamir</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">10 day</div>
                    </div>
                </Link>

                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg11} alt="" />
                    </div>

                    <h1 className='biketours_text'>Discover the Lakes Issyk-Kol, Song-Kol and Kel-Suu.</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Milti-active tour</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">11 day</div>
                    </div>
                </Link>

                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg31} alt="" />
                    </div>

                    <h1 className='biketours_text'>Trekking from Chui Valley to Issyk-Kul</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Horseback or trekking tour</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">3 day</div>
                    </div>
                </Link>
            </div>
            <div className="biketours_block pt-[30px]">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg23} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk-Road Tour: Kyrgyzstan & Uzbekistan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Kyrgyzstan & Uzbekistan</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">15 day</div>
                    </div>
                </Link>

                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg6} alt="" />
                    </div>

                    <h1 className='biketours_text'>Lake Kol-Ukok -nomad’s life</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Horseback tour</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">2 day</div>
                    </div>
                </Link>

            </div>
        </section>
    )
}
