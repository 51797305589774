import React from 'react';
import { Link } from 'react-router-dom';
// img
import BgToursimg5 from '../../images/BgToursImg/bgtoursimg_5.jpg'
import BgToursimg10 from '../../images/BgToursImg/bgtoursimg_10.jpg'
import BgToursimg7 from '../../images/BgToursImg/bgtoursimg_7.jpg'
import BgToursimg6 from '../../images/BgToursImg/bgtoursimg_6.jpg'
import BgToursimg12 from '../../images/BgToursImg/bgtoursimg_12.jpg'
import BgToursimg1 from '../../images/BgToursImg/bgtoursimg_1.jpg'
import BgToursimg3 from '../../images/BgToursImg/bgtoursimg_3.jpg'
import BgToursimg9 from '../../images/BgToursImg/bgtoursimg_9.jpg'
import BgToursimg4 from '../../images/BgToursImg/bgtoursimg_4.jpg'
import BgToursimg2 from '../../images/BgToursImg/bgtoursimg_2.jpg'
import BgToursimg8 from '../../images/BgToursImg/bgtoursimg_8.jpg'
import BgToursimg11 from '../../images/BgToursImg/bgtoursimg_11.png'

export default function Type() {
    return (
        <div>
            <section className='type'>
                <div className="container">
                    <center><h1 className='type_title'>TOUR TYPE</h1></center>

                    <div style={{ paddingTop: '80px' }} className="type_block">
                        <Link to="/horseback" className="type_card_bg">
                            <Link to="/horseback" className="type_card">
                                <img src={BgToursimg5} alt="" />
                            </Link>

                            <Link to="/horseback" className='type_text'>Horseback tours</Link>
                        </Link>

                        <Link to="/trekking" className="type_card_bg">
                            <Link to="/trekking" className="type_card">
                                <img src={BgToursimg10} alt="" />
                            </Link>

                            <Link to="/trekking" className='type_text'>Trekking Kyrgyzstan</Link>
                        </Link>

                        <Link to="/road" className="type_card_bg">
                            <Link to="/road" className="type_card">
                                <img src={BgToursimg7} alt="" />
                            </Link>

                            <Link to="/road" className='type_text'>Off road tours</Link>
                        </Link>
                    </div>

                    <div className="type_block">
                        <Link to="/multi" className="type_card_bg">
                            <Link to="/multi" className="type_card">
                                <img src={BgToursimg6} alt="" />
                            </Link>

                            <Link to="/multi" className='type_text'>Multi-active tourss</Link>
                        </Link>

                        <Link to="/winter" className="type_card_bg">
                            <Link to="/winter" className="type_card">
                                <img src={BgToursimg12} alt="" />
                            </Link>

                            <Link to="/winter" className='type_text'>Winter tours</Link>
                        </Link>

                        <Link to="/bike" className="type_card_bg">
                            <Link to="/bike" className="type_card">
                                <img src={BgToursimg1} alt="" />
                            </Link>

                            <Link to="/bike" className='type_text'>Bike tours</Link>
                        </Link>
                    </div>

                    <div className="type_block">
                        <Link to="/culture" className="type_card_bg">
                            <Link to="/culture" className="type_card">
                                <img src={BgToursimg3} alt="" />
                            </Link>

                            <Link to="/culture" className='type_text'>Culture tours</Link>
                        </Link>

                        <Link to="/photo" className="type_card_bg">
                            <Link to="/photo" className="type_card">
                                <img src={BgToursimg9} alt="" />
                            </Link>

                            <Link to="/photo" className='type_text'>Photo tours</Link>
                        </Link>

                        <Link to="/experience" className="type_card_bg">
                            <Link to="/experience" className="type_card">
                                <img src={BgToursimg4} alt="" />
                            </Link>

                            <Link to="/experience" className='type_text'>Experience tours</Link>
                        </Link>
                    </div>

                    <div className="type_block">
                        <Link to="/centralasia" className="type_card_bg">
                            <Link to="/centralasia" className="type_card">
                                <img src={BgToursimg2} alt="" />
                            </Link>

                            <Link to="/centralasia" className='type_text'>Central Asia tours</Link>
                        </Link>

                        <Link to="/OneDay" className="type_card_bg">
                            <Link to="/OneDay" className="type_card">
                                <img src={BgToursimg8} alt="" />
                            </Link>

                            <Link to="/OneDay" className='type_text'>One Day Tours</Link>
                        </Link>

                        <Link to="/wildlife" className="type_card_bg">
                            <Link to="/wildlife" className="type_card">
                                <img src={BgToursimg11} alt="" />
                            </Link>

                            <Link to="/wildlife" className='type_text'>Wildlife Kyrgyzstan</Link>
                        </Link>
                    </div>
                </div>

            </section>
        </div>
    )
}
