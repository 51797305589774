import React from 'react'

export default function
    () {
    return (
        <div>
            <section className='find'>
                <div className="container">
                    <div className="find_bg">
                        <h1 className='find_title'>Find Your next adventure</h1>

                        <div className="find_block">

                            <select className='find_card' name="" id="">
                                <option value="">All Destinations</option>
                                <option value="">Turkmenistan tourism</option>
                                <option value="">Kyrgyzstan</option>
                                <option value="">Kazakhstan tourism</option>
                                <option value="">Uzbekistan tourism</option>
                                <option value="">Tajikistan tourism</option>
                            </select>

                            <select className='find_card' name="" id="">
                                <option value="">All Months</option>
                                <option value="">January</option>
                                <option value="">February</option>
                                <option value="">March</option>
                                <option value="">April</option>
                                <option value="">May</option>
                                <option value="">June</option>
                                <option value="">July</option>
                                <option value="">August</option>
                                <option value="">September</option>
                                <option value="">October</option>
                                <option value="">November</option>
                                <option value="">December</option>
                            </select>
                            <select className='find_card' name="" id="">
                                <option value="">All Types</option>
                                <option value="">Horseback tours</option>
                                <option value="">Trekking Kyrgyzstan</option>
                                <option value="">Off road tours</option>
                                <option value="">Multi-active tours</option>
                                <option value="">Winter tours</option>
                                <option value="">Bike tours</option>
                                <option value="">Culture tours</option>
                                <option value="">Photo tours</option>
                                <option value="">Experience tours</option>
                                <option value="">Central Asia tours</option>
                                <option value="">One Day Tours</option>
                                <option value="">Wildlife Kyrgyzstan</option>
                            </select>

                            <button className='find_btn'>Find</button>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
}
