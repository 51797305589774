import React from 'react';
import RightModal from '../RightModal';
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg13 from '../../images/CardImg/card_img_13.jpg';
import Cardimg19 from '../../images/CardImg/card_img_19.jpg';
import Cardimg4 from '../../images/CardImg/card_img_4.jpg';

export default function RightModaltrekking() {
    return (
        <section className="right/modaltrekking">
            <RightModal />

            <h4 className="contact_title">Most popular tours</h4>

            <div className="contact_bg_block">
                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg13} alt="" />
                    </div>

                    <h1 className='contact_text'>Nomadic Horse Trek</h1>
                    <p className='contact_suptitle'><p class="excerpt">Horseback tour</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">9 days</div>
                    </div>
                </div>

                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg19} alt="" />
                    </div>

                    <h1 className='contact_text'>The tallest waterfall in Central Asia “Shar”.</h1>
                    <p className='contact_suptitle'><p class="excerpt">Milti-active tour</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">4 days</div>
                    </div>
                </div>

                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg4} alt="" />
                    </div>

                    <h1 className='contact_text'>Family traveling in Kyrgyzstan.</h1>
                    <p className='contact_suptitle'><p class="excerpt">Family tours</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">9 days</div>
                    </div>
                </div>

            </div>
        </section>
    )
}
