import React from 'react';
import { Link } from 'react-router-dom';


export default function ContactusBg() {
    return (
        <section className='contactusbg'>
            <div className="container">
                <div className='blog_hone_text'>
                    <Link className='blog_home' to="/">Home/</Link>
                    <div className="blog_home">Contact us</div>
                </div>
                <hr />
                <h1 className='blog_title'>Contact us</h1>
            </div>
        </section>
    )
}
