import React from 'react'
import { Link } from 'react-router-dom'

export default function DastLocation() {
    return (
        <section className='dastlocation'>
            <div className="datas_intereray_button">
                <Link to="/datas" className="datas_intereray_btn_2">
                    <button>ITINERARY</button>
                </Link>

                <div className="datas_intereray_btn_1">
                    <button>LOCATION</button>
                </div>

                <Link to="/dataspage3" className="datas_intereray_btn_2">
                    <button>PHOTOS</button>
                </Link>
            </div>

            <div className="dastlocationbg">
                <div className="dastlocationbg_globe">
                    <iframe src="https://www.google.com/maps/d/embed?mid=1ZvTOeNwZ-jGLHwgpgGamNXVDHDXFE8Q&ehbc=2E312F&noprof=1" width="640" height="480"></iframe>
                </div>
            </div>
        </section>
    )
}
