import React from 'react';
import { Link } from 'react-router-dom';

export default function ArchivePage() {
    return (
        <section className="archive">
            <div className="archive_block">
                <div className="archive_card">
                    <div className="archive_card_img">
                        <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2023/09/Samsa-660x360.jpg" alt="" />
                    </div>
                    <h2 className='archive_title'>Central Asian Delight: Samsa – Savory Silk Road Pastries</h2>
                    <p className="archive_suptitle">Samsa is a delectable savory pastry that holds a cherished place in [...]</p>
                    <p className='archive_href'>Read more →</p>
                </div>

                <div className="archive_card">
                    <div className="archive_card_img">
                        <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2023/05/SnowLeopard-660x360.png" alt="" />
                    </div>
                    <h2 className='archive_title'>Capturing the Elusive Snow Leopards: A Photo Tour in Kyrgyzstan</h2>
                    <p className="archive_suptitle">Introduction: Welcome to the mystical mountains of Kyrgyzstan, where [...]</p>
                    <p className='archive_href'>Read more →</p>
                </div>
            </div>

            <div className="archive_block">
                <div className="archive_card">
                    <div className="archive_card_img">
                        <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2023/09/Samsa-660x360.jpg" alt="" />
                    </div>
                    <h2 className='archive_title'>Central Asian Delight: Samsa – Savory Silk Road Pastries</h2>
                    <p className="archive_suptitle">Samsa is a delectable savory pastry that holds a cherished place in [...]</p>
                    <p className='archive_href'>Read more →</p>
                </div>

                <div className="archive_card">
                    <div className="archive_card_img">
                        <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2023/05/SnowLeopard-660x360.png" alt="" />
                    </div>
                    <h2 className='archive_title'>Capturing the Elusive Snow Leopards: A Photo Tour in Kyrgyzstan</h2>
                    <p className="archive_suptitle">Introduction: Welcome to the mystical mountains of Kyrgyzstan, where [...]</p>
                    <p className='archive_href'>Read more →</p>
                </div>
            </div>

            <div className="archive_block">
                <div className="archive_card">
                    <div className="archive_card_img">
                        <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2023/09/Samsa-660x360.jpg" alt="" />
                    </div>
                    <h2 className='archive_title'>Central Asian Delight: Samsa – Savory Silk Road Pastries</h2>
                    <p className="archive_suptitle">Samsa is a delectable savory pastry that holds a cherished place in [...]</p>
                    <p className='archive_href'>Read more →</p>
                </div>

                <div className="archive_card">
                    <div className="archive_card_img">
                        <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2023/05/SnowLeopard-660x360.png" alt="" />
                    </div>
                    <h2 className='archive_title'>Capturing the Elusive Snow Leopards: A Photo Tour in Kyrgyzstan</h2>
                    <p className="archive_suptitle">Introduction: Welcome to the mystical mountains of Kyrgyzstan, where [...]</p>
                    <p className='archive_href'>Read more →</p>
                </div>
            </div>

            <div className="archive_block">
                <div className="archive_card">
                    <div className="archive_card_img">
                        <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2023/09/Samsa-660x360.jpg" alt="" />
                    </div>
                    <h2 className='archive_title'>Central Asian Delight: Samsa – Savory Silk Road Pastries</h2>
                    <p className="archive_suptitle">Samsa is a delectable savory pastry that holds a cherished place in [...]</p>
                    <p className='archive_href'>Read more →</p>
                </div>

                <div className="archive_card">
                    <div className="archive_card_img">
                        <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2023/05/SnowLeopard-660x360.png" alt="" />
                    </div>
                    <h2 className='archive_title'>Capturing the Elusive Snow Leopards: A Photo Tour in Kyrgyzstan</h2>
                    <p className="archive_suptitle">Introduction: Welcome to the mystical mountains of Kyrgyzstan, where [...]</p>
                    <p className='archive_href'>Read more →</p>
                </div>
            </div>

            <div className="button_block">
                <Link to="/blog" className="btn_next">« Previous</Link>
                <Link to="/blog" className='btn_2'>1</Link>
                <button className='btn_1'>2</button>
            </div>
        </section>
    )
}
