import React from 'react';
import { Link } from 'react-router-dom';

export default function Destination() {
    return (
        <section className='destination'>
            <div className="container">
                <h1 className='destination_title'>DESTINATION</h1>

                <div style={{paddingTop: '70px'}} className="destination_block">
                    <Link to="/turkmenistan" className="destination_card">
                        <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2021/03/Turkmenistan-Ashgabat.jpg" alt="" />
                        <h1 className='destination_suptitle'>Turkmenistan tourism</h1>
                    </Link>

                    <Link to="/kyrgyzstan" className="destination_card">
                        <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2019/03/world-nomad-games-kyrgyzstan1-9b925c7fee3b.jpg" alt="" />
                        <h1 className='destination_suptitle'>Kyrgyzstan</h1>
                    </Link>

                    <Link to="/kazakstan" className="destination_card">
                        <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2019/03/acj-2301-kazakhstan-tourist-attractions-1.jpg" alt="" />
                        <h1 className='destination_suptitle'>Kazakhstan tourism</h1>
                    </Link>

                    <Link to="/uzbekistan" className="destination_card">
                        <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2019/03/khiva-highlight.jpg" alt="" />
                        <h1 className='destination_suptitle'>Uzbekistan tourism</h1>
                    </Link>
                </div>

                <div className="destination_block">
                    <Link to="/tajikistan" className="destination_card">
                        <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2019/03/Destin_Tadj.jpg" alt="" />
                        <h1 className='destination_suptitle'>Tajikistan tourism</h1>
                    </Link>
                </div>
            </div>
        </section>
    )
}
