import React from 'react';
import '../css/rightmodal.css';
import { Link } from 'react-router-dom';

export default function TagsModal() {
    return (
        <section className='tagsmodal'>
            <div className="tags_bg">
                <h4 className='group_bg_title'>Tags</h4>
                <hr />

                <div className="tags_bg_block">
                    <Link className="tags_bg_link">40 rays</Link>
                    <Link className="tags_bg_link">adventure</Link>
                    <Link className="tags_bg_link">Adventure tourism</Link>
                    <Link className="tags_bg_link">Afghanistan</Link>
                    <Link className="tags_bg_link">Aigul</Link>
                    <Link className="tags_bg_link">Ak-Beshim</Link>
                    <Link className="tags_bg_link">ala-kiyiz</Link>
                    <Link className="tags_bg_link">animals</Link>
                    <Link className="tags_bg_link">archeological site</Link>
                    <Link className="tags_bg_link">Award</Link>
                    <Link className="tags_bg_link">Backcountry ski tours</Link>
                    <Link className="tags_bg_link">backcountry skitours</Link>
                    <Link className="tags_bg_link">Badakhshan</Link>
                    <Link className="tags_bg_link">Balasagun</Link>
                    <Link className="tags_bg_link">Barskoon</Link>
                    <Link className="tags_bg_link">Batken</Link>
                    <Link className="tags_bg_link">Bazaar</Link>
                    <Link className="tags_bg_link">birdlife</Link>
                    <Link className="tags_bg_link">birdwatching</Link>
                    <Link className="tags_bg_link">Bishkek</Link>
                    <Link className="tags_bg_link">Bishkek History</Link>
                    <Link className="tags_bg_link">Burana</Link>
                    <Link className="tags_bg_link">Burana tower</Link>
                    <Link className="tags_bg_link">Capital</Link>
                    <Link className="tags_bg_link">Caravanserai</Link>
                    <Link className="tags_bg_link">Celestial Mountains</Link>
                    <Link className="tags_bg_link">ceremony</Link>
                    <Link className="tags_bg_link">China</Link>
                    <Link className="tags_bg_link">City tour</Link>
                    <Link className="tags_bg_link">clothings</Link>
                    <Link className="tags_bg_link">coronavirus</Link>
                    <Link className="tags_bg_link">costume</Link>
                    <Link className="tags_bg_link">covid-19</Link>
                    <Link className="tags_bg_link">COVID19</Link>
                    <Link className="tags_bg_link">CSR</Link>
                    <Link className="tags_bg_link">Culture</Link>
                    <Link className="tags_bg_link">Currency</Link>
                    <Link className="tags_bg_link">E-Visa</Link>
                    <Link className="tags_bg_link">eagle hunting</Link>
                    <Link className="tags_bg_link">Ecocertification</Link>
                    <Link className="tags_bg_link">endemic</Link>
                    <Link className="tags_bg_link">felt</Link>
                    <Link className="tags_bg_link">Foods</Link>
                    <Link className="tags_bg_link">freeride ski</Link>
                    <Link className="tags_bg_link">Fritillaria eduardii Regel</Link>
                    <Link className="tags_bg_link">Gorge</Link>
                    <Link className="tags_bg_link">health</Link>
                    <Link className="tags_bg_link">history</Link>
                    <Link className="tags_bg_link">horsemen</Link>
                    <Link className="tags_bg_link">Horses</Link>
                    <Link className="tags_bg_link">ibex</Link>
                    <Link className="tags_bg_link">Issyk-kul</Link>
                    <Link className="tags_bg_link">Jety-Oguz</Link>
                    <Link className="tags_bg_link">Karakhanid khanate</Link>
                    <Link className="tags_bg_link">Kozu Ulan</Link>
                    <Link className="tags_bg_link">Kyrgyz</Link>
                    <Link className="tags_bg_link">kyrgyz handicrafts</Link>
                    <Link className="tags_bg_link">Kyrgyz Visa</Link>
                    <Link className="tags_bg_link">Kyrgyzstan</Link>
                    <Link className="tags_bg_link">kyrgyzstan people</Link>
                    <Link className="tags_bg_link">Language</Link>
                    <Link className="tags_bg_link">legend</Link>
                    <Link className="tags_bg_link">Lenin Peak</Link>
                    <Link className="tags_bg_link">leopard</Link>
                    <Link className="tags_bg_link">Manas</Link>
                    <Link className="tags_bg_link">marcopolo</Link>
                    <Link className="tags_bg_link">Market</Link>
                    <Link className="tags_bg_link">Minaret</Link>
                    <Link className="tags_bg_link">nature</Link>
                    <Link className="tags_bg_link">new normality</Link>
                    <Link className="tags_bg_link">nomad style</Link>
                    <Link className="tags_bg_link">nomadic life</Link>
                    <Link className="tags_bg_link">Nomads</Link>
                    <Link className="tags_bg_link">outdoor</Link>
                    <Link className="tags_bg_link">Pamir</Link>
                    <Link className="tags_bg_link">phototours</Link>
                    <Link className="tags_bg_link">Politics</Link>
                    <Link className="tags_bg_link">Population</Link>
                    <Link className="tags_bg_link">Red Book</Link>
                    <Link className="tags_bg_link">Red color</Link>
                    <Link className="tags_bg_link">Regions of Kyrgyzstan</Link>
                    <Link className="tags_bg_link">Registration</Link>
                    <Link className="tags_bg_link">Religion</Link>
                    <Link className="tags_bg_link">Riding Basics</Link>
                    <Link className="tags_bg_link">Roof of the World</Link>
                    <Link className="tags_bg_link">Sarykol</Link>
                    <Link className="tags_bg_link">Short-term visas</Link>
                    <Link className="tags_bg_link">shyrdak</Link>
                    <Link className="tags_bg_link">ski trip in Kyrgyzstan</Link>
                    <Link className="tags_bg_link">skitouring</Link>
                    <Link className="tags_bg_link">snowleopard</Link>
                    <Link className="tags_bg_link">Sogdian merchants</Link>
                    <Link className="tags_bg_link">Song-Kul</Link>
                    <Link className="tags_bg_link">State symbols</Link>
                    <Link className="tags_bg_link">sustainability</Link>
                    <Link className="tags_bg_link">sustainable tourism</Link>
                    <Link className="tags_bg_link">symbol</Link>
                    <Link className="tags_bg_link">Tajikistan</Link>
                    <Link className="tags_bg_link">Tash-Rabat</Link>
                    <Link className="tags_bg_link">The epic of Manas</Link>
                    <Link className="tags_bg_link">The Flag</Link>
                    <Link className="tags_bg_link">the Great Silk Road</Link>
                    <Link className="tags_bg_link">The Kyrgyz Republic</Link>
                    <Link className="tags_bg_link">the saddle</Link>
                    <Link className="tags_bg_link">the Silk Road</Link>
                    <Link className="tags_bg_link">the State Border</Link>
                    <Link className="tags_bg_link">the stirrups</Link>
                    <Link className="tags_bg_link">The Sun</Link>
                    <Link className="tags_bg_link">tienshan </Link>
                    <Link className="tags_bg_link">Tokmok</Link>
                    <Link className="tags_bg_link">tours</Link>
                    <Link className="tags_bg_link">tradition</Link>
                    <Link className="tags_bg_link">traditional hunting</Link>
                    <Link className="tags_bg_link">Travelife</Link>
                    <Link className="tags_bg_link">Travelife partner</Link>
                    <Link className="tags_bg_link">Tunduk</Link>
                    <Link className="tags_bg_link">UNDP</Link>
                    <Link className="tags_bg_link">videoblog</Link>
                    <Link className="tags_bg_link">Visa Free Regime</Link>
                    <Link className="tags_bg_link">Wakhan Valley</Link>
                    <Link className="tags_bg_link">wildlife</Link>
                    <Link className="tags_bg_link">Winter Kyrgyzstan</Link>
                    <Link className="tags_bg_link">winter travel</Link>
                    <Link className="tags_bg_link">Yurt</Link>
                    <Link className="tags_bg_link">Zoroastrianism</Link>
                </div>
            </div>
        </section>
    )
}
