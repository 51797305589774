import React from 'react';
import Header from '../Home/Header';
import DataBg from './DataBg';
import DastLocation from './DastLocation';
import '../../css/datas.css';
import RightModalDatas from './RightModalDatas';
import Footer from '../Home/Footer';
import BackToTopButton from '../Home/BackToTopButton';

export default function DtasaPage2() {
    return (
        <div className='dataspage_2'>
            <Header />
            <DataBg />
            <div className="container displaycontainer">
                <DastLocation />
                <RightModalDatas />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
