import React from 'react';
import { Link } from 'react-router-dom';
import { FaGlobeAsia } from "react-icons/fa";

export default function MenuBurger() {
    return (
        <section className='menuburger'>
            <ul className='menunav'>
                <Link to="/" className='li'><Link to="/">home</Link></Link>
                <Link className='li'><Link>tours▾</Link>

                    <ul className="menudown">
                        <Link to="/bike" className='menunavdown_1'><a>bike tours</a></Link>
                        <Link to="/centralasia" className='menunavdown_1'><a>centralasia tours</a></Link>
                        <Link to="/culture" className='menunavdown_1'><a>Culture tours</a></Link>
                        <Link to="/experience" className='menunavdown_1'><a>Experience tours</a></Link>
                        <Link to="/horseback" className='menunavdown_1'><a>Horseback tours</a></Link>
                        <Link to="/multi" className='menunavdown_1'><a>Multi-active tours</a></Link>
                        <Link to="/road" className='menunavdown_1'><a>Off road tours</a></Link>
                        <Link to="/oneday" className='menunavdown_1'><a>One Day Tours</a></Link>
                        <Link to="/photo" className='menunavdown_1'><a>photo tours</a></Link>
                        <Link to="/trekking" className='menunavdown_1'><a>Trekking Kyrgyzstan</a></Link>
                        <Link to="/wildlife" className='menunavdown_1'><a>Wildlife Kyrgyzstan</a></Link>
                        <Link to="/winter" className='menunavdown_1'><a>Winter tours</a></Link>
                    </ul>
                </Link>
                <Link className='li'><Link to="">destionations▾</Link>
                    <ul className="menudown">
                        <Link to="/kazakstan" className="menunavdown_1"><Link to="/kazakstan">kazakhstan tourism</Link></Link>
                        <Link to="/kyrgyzstan" className="menunavdown_1"><Link to="/kyrgyzstan">kyrgyzstan</Link></Link>
                        <Link to="/tajikistan" className="menunavdown_1"><Link to="/tajikistan">tajicistan tourism</Link></Link>
                        <Link to="/turkmenistan" className="menunavdown_1"><Link to="/turkmenistan">turkmenistan tourism</Link></Link>
                        <Link to="/uzbekistan" className="menunavdown_1"><Link to="/uzbekistan">uzbecistan tourism</Link></Link>
                    </ul>
                </Link>
                <Link to="/grouptours" className='li'><Link to="/grouptours">grouptours</Link></Link>
                <Link to="/blog" className='li'><Link to="/blog">blog</Link></Link>
                <Link className='li'><Link to="">kyrgyzstan▾</Link>

                    <ul className="menudown">
                        <Link to="/kyrgyzstanabout" className="menunavdown_1"><Link to="/kyrgyzstanabout">About Kyrgyzstan</Link></Link>
                        <Link to="/events" className="menunavdown_1"><Link to="/events">Events 2024</Link></Link>
                        <Link to="/projects" className="menunavdown_1"><Link to="/projects">Projects</Link></Link>
                        <Link to="/around" className="menunavdown_1"><Link to="/around">Getting around</Link></Link>
                        <Link to="/permits" className="menunavdown_1"><Link to="/permits">Permits</Link></Link>
                        <Link to="/inspiration" className="menunavdown_1"><Link to="/inspiration">Inspiration for visiting Kyrgyzstan</Link></Link>
                        <Link to="/gallery" className="menunavdown_1"><Link to="/gallery">Photo gallery</Link></Link>
                    </ul>
                </Link>
                <Link className='li'><Link to="">about▾</Link>
                    <ul className="menudown">
                        <Link to="/aboutus" className="menunavdown_1"><Link to="/aboutus">About us</Link></Link>
                        <Link to="/sustainable" className="menunavdown_1"><Link to="/sustainable">Sustainable tourism</Link></Link>
                        <Link to="/contactus" className="menunavdown_1"><Link to="/contactus">Contact us</Link></Link>
                    </ul>
                </Link>
                <Link className='li'><Link className='nav_globe_icon'><FaGlobeAsia /> en ▾</Link>
                    <ul className="menudown">
                        <Link to="" className="menunavdown_1"><Link to="">de</Link></Link>
                    </ul>
                </Link>
            </ul>
        </section>
    )
}
