import React from 'react';
import { Link } from 'react-router-dom';
// react awesome
import { LuClock9 } from "react-icons/lu";
import Cardimg44 from '../../images/CardImg/card_img_44.jpg';
import Cardimg14 from '../../images/CardImg/card_img_14.jpg';
import Cardimg16 from '../../images/CardImg/card_img_16.jpg';
import Cardimg45 from '../../images/CardImg/card_img_45.jpg';

export default function WinterTour() {
    return (
        <section className='wintertours'>

<h1 className="toptravel_title">Top Winter travel deals</h1>

<div className="toptravelscrol">

    <div className="toptravel_block">
        <h2 className="toptravel_departng">Departing</h2>
        <h2 className="toptravel_trip">Trip</h2>
        <h2 className="toptravel_fotos">Fotos</h2>
        <h2 className="toptravel_days">Days</h2>
        <h2 className="toptravel_from">From USD</h2>
    </div>

    <div className="toptravel_bg">

        <div className="toptravel_card">
            <div className="toptravel_card_num">02 Feb 2024</div>
            <div className="toptravel_card_trip">
                <h1 className="toptravel_card_title">Okavango Experience</h1>
                <p className="toptravel_card_suptitle">Johannesburg → Victoria Falls</p>
            </div>
            <div className="toptravel_card_img">
                <img src={Cardimg14} alt="" />
            </div>
            <div className="toptravel_card_day">10</div>
            <div className="toptravel_card_price">
                <span className="toptravel_card_discount">1,500</span>
                <div className="toptravel_card_cost">$1,299</div>
            </div>

            <div className="toptravel_card_view">View trip ›</div>
        </div>

        <div className="toptravel_card toptravel_card_border">
            <div className="toptravel_card_num">02 Feb 2024</div>
            <div className="toptravel_card_trip">
                <h1 className="toptravel_card_title">Okavango Experience</h1>
                <p className="toptravel_card_suptitle">Johannesburg → Victoria Falls</p>
            </div>
            <div className="toptravel_card_img">
                <img src={Cardimg16} alt="" />
            </div>
            <div className="toptravel_card_day">10</div>
            <div className="toptravel_card_price">
                <span className="toptravel_card_discount">1,500</span>
                <div className="toptravel_card_cost">$1,299</div>
            </div>

            <div className="toptravel_card_view">View trip ›</div>
        </div>
    </div>
</div>

            <div className="biketours_block">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg44} alt="" />
                    </div>

                    <h1 className='biketours_text'>Backcountry ski tour: Karakol, Aksuu and Jergalan.</h1>
                    <p className='biketours_suptitle'><p class="excerpt">skitour</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">10 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg14} alt="" />
                    </div>

                    <h1 className='biketours_text'>Winter adventure tour in Kyrgyzstan.</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Horse trek</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">8 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg16} alt="" />
                    </div>

                    <h1 className='biketours_text'>Winter adventures in Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Family tours</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">12 days</div>
                    </div>
                </Link>
            </div>
            <div className="biketours_block">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg45} alt="" />
                    </div>

                    <h1 className='biketours_text'>Backcountry ski tour in northern Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Backcountry ski tour</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">12 days</div>
                    </div>
                </Link>
            </div>
        </section>
    )
}
