import React from 'react';
import Partner from '../../../images/partner.jpg';
import { Link } from 'react-router-dom';

export default function SustinableTravel() {
    return (
        <section className='sustinabletravel'>
            <div className="sustinabletravel_bg">
                <div className="sustinabletravel_text">
                    <h5 className="sustinabletravel_title">Sustainable development policy for LLC Kyrgyz Tourism</h5>
                </div>
                <ol className='sustinabletravel_ol'>
                    <li className='sustinabletravel_li'>The mission of the company</li>
                    <li className="sustinabletravel_li">The mission of sustainable development</li>
                    <li className="sustinabletravel_li">Company values</li>
                    <li className="sustinabletravel_li">Environmental code</li>
                    <li className="sustinabletravel_li">Ecotourist rules</li>
                    <li className="sustinabletravel_li">Sustainable management, social policy, and human rights</li>
                    <li className="sustinabletravel_li">Environment and ecology</li>
                    <li className="sustinabletravel_li">Green office practices Kyrgyz Tourism – Memo for employees and visitors</li>
                </ol>

                <ol className='sustinabletravel_ol_text'>
                    <li className='sustinabletravel_li_title'>The mission of the company</li>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">We are a team of local tourism experts with complementary skills in culture and adventure tourism. We collaborate with communities around the region to create and implement unique, customized tour products. This enables our guests to have unforgettable, exciting, enriching, immersive experiences while at the same time giving back through cross-cultural exchange, support for the preservation of traditions and ecology, and enhancement of regional and global integration.</p>
                    </div>
                    <li className='sustinabletravel_li_title'>The mission of the company</li>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">Our mission is to improve the welfare of the country through the development of active ecotourism, support communities in the regions, and preserve the heritage and resources of the planet for future generations.</p>
                    </div>
                    <div className="sustinabletravel_li_text">
                        <img src={Partner} alt="" />
                    </div>
                    <li className='sustinabletravel_li_title'>Company values</li>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">– Subject-matter Expertise</p>
                        <p className="sustinabletravel_li_suptitle">– Eco-friendliness</p>
                        <p className="sustinabletravel_li_suptitle">– Sustainability</p>
                    </div>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">Over the centuries Kyrgyz people have kept true to their nomadic culture. It has rich life experience with honest values and understanding; keeping ancient beliefs, worshiping ancestors and nature. In these days of globalization, we need to keep the best of our cultural, and historical heritage and share it.</p>
                    </div>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">We work with local tourism stakeholders first hand which gives local communities an opportunity to benefit directly. We encourage long-lasting cooperation and sustainability.</p>
                    </div>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">Kyrgyz Tourism is traditional Kyrgyz hospitality!</p>
                    </div>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">The miracles of nature and culture are waiting for you. Experience Kyrgyzstan with us and we will provide you with authentic Kyrgyz hospitality!</p>
                    </div>
                    <li className='sustinabletravel_li_title'>Environmental code Attach Code</li>
                    <li className='sustinabletravel_li_title'>Ecotourist rules Attach_ecotrekker</li>
                    <li className='sustinabletravel_li_title'>Sustainable management, social policy, and human rights</li>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">All work of the company is based on the principle of openness and trust. When recruiting and hiring an employee, we use a mentoring program. All legal and financial aspects are discussed with the employee, these issues are regulated by national legislation, and our company complies with the laws of the Kyrgyz Republic. Employees, service providers, and customers can contact on all issues, and the management of the company is open to recommendations, complaints, and suggestions. The absence of forced labor, the use of child labor, work in hazardous production, risks, and unequal opportunities are excluded from the work of the company. Issues of health and safety of employees are regulated by the Law of the Kyrgyz Republic on Public Health and the Labor Code of the Kyrgyz Republic. Social insurance is paid into the state fund according to the law. Insurance of the company’s activities is registered with CJSC Insurance Company Kyrgyzstan.</p>
                    </div>
                    <li className='sustinabletravel_li_title'>Environment and ecology</li>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">Our company adheres to the above-mentioned Rules and Codes for ecology. Working in ecotourism, it is important to adhere to the principles of sustainable tourism yourself! Kyrgyz Tourism complies with the national legislation of the Kyrgyz Republic on waste management.</p>
                    </div>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_managament">Waste management.</p>
                    </div>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">Waste management is a complex issue. Storing waste is not a sustainable solution, and its destruction leads to emissions and the formation of highly concentrated polluting residual materials. Most municipal and hazardous waste is disposed of in or on the ground. The environmental disadvantages of landfills are numerous. Legal waste disposal sites are already at the limit of their capacity. Heavy metals and toxins seep into adjacent groundwater and soil. Explosive and toxic gases are produced. There is an unknown but undoubtedly large number of illegal dumps whose environmental risks cannot be quantified. The main disposal method alternative to disposal, incineration, results in the formation of toxins and heavy metals. Expensive filters must be installed in waste incinerators to prevent their release. Used filters with high concentrations of pollutants and a quarter of the original weight of the waste are still subject to final disposal in landfills. Waste management is one of the key issues for the environment and sustainable management of natural resources. The optimal solution is to prevent the generation of waste and re-introduce it into the production cycle by recycling its components in cases where there are environmentally and economically sound methods for this.</p>
                    </div>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">Thus, the primary goals of waste management are: (a) prevention of waste generation by reducing the toxicity and volume of waste generated in various production and consumption processes; b) recycling and reuse by increasing the proportion of materials produced b) recycling and reuse by increasing the proportion of materials made from recycled materials; (c) Environmentally sound waste management in terms of disposal, including optimal final disposal and improved monitoring. (Ref: Assessment of the capacity of countries in Eastern Europe, the Caucasus, and Central Asia to develop statistics to measure sustainable development and environmental sustainability through the United Nations Development Account (UNDA), UNECE)</p>
                    </div>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_managament">Actions to reduce waste:</p>
                    </div>
                    <ol className='sustinabletravel_ol_ol'>
                        <li className='sustinabletravel_li_li'>Waste sorting for further processing. Separately collect plastic, glass, and paper. Separately collect hazardous waste – batteries, lightbulbs.</li>
                        <li className='sustinabletravel_li_li'>For plastic, there is a separate eco-urn from <Link to="https://www.instagram.com/begreen.kg/?igshid=1tnfghge33byl&hl=en">BeGreen</Link> </li>
                        <li className='sustinabletravel_li_li'>For paper, there is a separate eco box from <Link to="https://paper.kg/services/#ecobox">Alternative Trade</Link> </li>
                        <li className='sustinabletravel_li_li'>All collected waste is disposed of according to the instructions of the recycling companies at special collection points.</li>
                    </ol>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">Pollution Reduction-Purchasing</p>
                        <p className="sustinabletravel_li_suptitle">We minimally use safe and eco-certified products for the domestic needs of our office. All products that are purchased at our office or in the course of our activities are certified food, food, household, etc. With FSC, Fair Trademarking. The list of household chemical products must have special eco-labels. Office paper is used certified with marking (FSC – Paper from responsible sources – Paper from responsible sources FSC C013771), Elemental 100% Chlorine free bleached, ISO 9001:2008, ISO 14001:2004, OHSAS 18001:2007, GOST R ISO 9706- 2000)</p>
                    </div>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">Resource conservation</p>
                        <p className="sustinabletravel_li_suptitle">Use only the minimum required amount of water, products, materials, and resources necessary for the work and activities of the company during the tours.</p>
                    </div>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">Save energy – turn off all appliances when not in use or when not in use for more than 30 minutes, set to save energy, and auto-switch depending on daylight and energy.</p>
                    </div>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">Use of energy-efficient solutions for heating, heating, electricity, and water use. Modern household appliances with the Energy Star designation, energy-saving bulbs. Use of reusable cartridges and toners for copier and printer. Use of ceramic and glassware in the office.</p>
                    </div>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">Minimize the use of hazardous transport, if possible, use public transport, and eco-friendly modes of transport, and share transport costs in a team, on business trips, or outings in groups.</p>
                    </div>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">Teaching sustainable development and raising awareness of sustainable tourism</p>
                    </div>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">Our company is engaged in consulting services and training for residents of regions in Kyrgyzstan and Central Asia. We hold seminars and training, where, regardless of the topic, we always add a session on sustainable development and tourism, on ecology. We believe that awareness and education are the main tools for implementing the principles of sustainable development</p>
                    </div>
                    <li className='sustinabletravel_li_title'>Green Office practices Kyrgyz Tourism – Reminder for employees and visitors</li>
                    <div className="sustinabletravel_li_text">
                        <p className="sustinabletravel_li_suptitle">The goal is to reduce the negative impact of the company’s activities on the environment and promote the rational use of resources</p>
                    </div>
                    <ol className='sustinabletravel_ol_ol'>
                        <li className='sustinabletravel_li_li'>Save resources and take good care of resources and nature</li>
                        <li className='sustinabletravel_li_li'>Sort garbage – paper, glass, plastic, batteries, food waste</li>
                        <li className='sustinabletravel_li_li'>Conserve resources – water, electricity, paper, food, and non-food products</li>
                        <li className='sustinabletravel_li_li'>When leaving the office, check that all electrical appliances are turned off</li>
                        <li className='sustinabletravel_li_li'>When using, it is recommended to put all electrical appliances (laptop, phone) on the power-saving mode</li>
                        <li className='sustinabletravel_li_li'>Do not print the document unnecessarily. Use the duplex printing feature. 100 kg of waste paper can save 1 tree!</li>
                        <li className='sustinabletravel_li_li'>For internal documentation, use A4 drafts to print on the second blank side of the paper</li>
                        <li className='sustinabletravel_li_li'>For internal documentation, use A4 drafts to print on the second blank side of the paper</li>
                        <li className='sustinabletravel_li_li'>All utensils in the office are made of wood, glass, and ceramics, clean, and of the appropriate quality. There are textile napkins. Avoid using disposable tableware and excess use of paper napkins.</li>
                        <li className='sustinabletravel_li_li'>Use the predominantly sustainable mode of transport or public transport.</li>
                    </ol>
                    <li className='sustinabletravel_li_title_nine'> Confidentiality policy</li>
                    <Link to="https://kyrgyzstan-tourism.com/wp-content/uploads/2023/11/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0-%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7-%D0%A2%D1%83%D1%80%D0%B8%D0%B7%D0%BC.pdf" className="sustinabletravel_li_nine_suptitle">Политика конфиденциальности Кыргыз Туризм</Link>
                </ol>

                <hr className='sustinablehr' />
                <div className="sustinable_btn">
                    <button>VIEW ALL TOURS</button>
                </div>
            </div>
        </section>
    )
}
