import React, { useEffect, useState } from 'react';
import Mostswiper from './Mostswiper';


export default function Most() {

    return (

        <section className='most'>
            <div className="container">
                <h1 className='most_title'>MOST POPULAR KYRGYZSTAN TOURS</h1>
                <Mostswiper />
            </div>
        </section>


    )
}
