import React from 'react'
import Header from '../Home/Header'
import BikeBg from './BikeBg'
import BikeTours from './BikeTours'
import RightModalBike from './RightModalBike'
import Footer from '../Home/Footer'
import '../../css/tours.css'
import BackToTopButton from '../Home/BackToTopButton';


export default function Bike() {
  return (
    <div>
      <Header />
      <BikeBg />
      <div className="container displaycontainer">
        <BikeTours />
        <RightModalBike />
      </div>
      <Footer />
      <BackToTopButton />
    </div>
  )
}
