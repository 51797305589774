import React from 'react'
import '../css/rightmodal.css';

export default function RightModal() {
    return (
        <section className='rightmodal'>
            <div className="sights_bg">
                <h4 className='group_bg_title'>Sights</h4>
                <hr />
                <ul className='sight_text'>
                    <li><a href="" className="sight_title">Canyons</a></li>
                    <li><a href="" className="sight_title">San-Tash </a></li>
                    <li><a href="" className="sight_title">akbulung</a></li>
                    <li><a href="" className="sight_title">Bokonbay</a></li>
                    <li><a href="" className="sight_title">Saimaluu-Tash</a></li>
                    <li><a href="" className="sight_title">Kochkor</a></li>
                    <li><a href="" className="sight_title">Arslanbob</a></li>
                    <li><a href="" className="sight_title">Talas</a></li>
                    <li><a href="" className="sight_title">Batken</a></li>
                    <li><a href="" className="sight_title">Jalal-Abad</a></li>
                    <li><a href="" className="sight_title">Karakol</a></li>
                    <li><a href="" className="sight_title">National Park "Ala-Archa"</a></li>
                    <li><a href="" className="sight_title">Issyk-Kul Lake</a></li>
                    <li><a href="" className="sight_title">Naryn town</a></li>
                    <li><a href="" className="sight_title">Sary-Chelek Lake</a></li>
                    <li><a href="" className="sight_title">Osh town</a></li>
                    <li><a href="" className="sight_title">Bishkek</a></li>
                    <li><a href="" className="sight_title">Tash-Rabat Caravanserai</a></li>
                    <li><a href="" className="sight_title">Lake Song-Kol</a></li>
                    <li><a href="" className="sight_title">Lake Kel-Suu</a></li>
                </ul>
            </div>

            <div className="contact_bg">
                <h4 className="contact_bg_title">Contact with us</h4>
                <div className="contact_inputs">
                    <div className="contact_name">
                        <input type="search" placeholder='Your name' />
                    </div>
                    <div className="contact_name">
                        <input type="search" placeholder='Your email' />
                    </div>
                    <div className="contact_mesage">
                        <input type="search" placeholder='Your message' />
                    </div>

                    <div className="contact_btn">
                        <button>Send</button>
                    </div>
                </div>
            </div>
        </section>
    )
}
