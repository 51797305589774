import React from 'react'

export default function With() {
  return (
    <section className='with'>
      <div className="with_shadow">

        <div className="container">
          <h1 className='with_title'>CONTACT WITH US</h1>
          <hr className='with_hr' />

          <div className="with_card">

            <div className="with_your_name">
              <input type="search" placeholder='Your name' />
            </div>
            <div className="with_your_email">
              <input type="search" placeholder='Your email' />
            </div>
            <div className="with_your_message">
              <input type="search" placeholder='Your message' />
            </div>
            <center>
              <div className="with_btn">
                <button>send</button>
              </div>
            </center>

          </div>

        </div>
      </div>

    </section>
  )
}
