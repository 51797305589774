import React from 'react';
import { Link } from 'react-router-dom';

export default function ConctausTravel() {
    return (
        <section className='contactustravel'>
            <div className="contactustravel_bg">
                <Link to="tel:+996706363738" className="contact_number_1">+996 706 363738</Link>
                <br />
                <Link to="tel:+996777970779" className="contact_number_1">+996 777 970779</Link>

                <div className="contact_adres">info@kyrgyzstan-tourism.com</div>
                <div className="contact_adres">Kyrgyz Republic. Bishkek city, Abay Str.,21</div>
                <div className="contact_adres">Mon – Sat 9.00 – 18.00 Sunday CLOSED</div>
                <iframe className='contact_globe' src="https://www.google.com/maps/d/embed?mid=1nE9T2maGt8xeezdwIzaNX6AL1kcnIpA&ehbc=2E312F" ></iframe>

                <hr className='contactushr' />
                <div className="contactus_btn">
                    <button>VIEW ALL TOURS</button>
                </div>
            </div>
        </section>
    )
}
