import React from 'react';
import RightModal from '../RightModal'
import '../../css/rightmodal.css'
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg18 from '../../images/CardImg/card_img_18.jpg';
import Cardimg8 from '../../images/CardImg/card_img_8.jpg';
import Cardimg15 from '../../images/CardImg/card_img_15.jpg';

export default function RightModalDatas() {
    return (
        <section className='rightmodaldatas'>
            <RightModal />

            <h4 className="contact_title">Most popular tours</h4>

            <div className="contact_bg_block">
                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg18} alt="" />
                    </div>

                    <h1 className='contact_text'>Silk Road Tour: Kazakhstan and Kyrgyzstan</h1>
                    <p className='contact_suptitle'><p class="excerpt">Kyrgyzstan and Kazakhstan</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="contact_num">9 days</div>
                    </div>
                </div>

                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg8} alt="" />
                    </div>

                    <h1 className='contact_text'>Lake hopping: Issyk-Kul and Song-Kul</h1>
                    <p className='contact_suptitle'><p class="excerpt">Milti-active tour</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="contact_num">7 days</div>
                    </div>
                </div>

                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg15} alt="" />
                    </div>

                    <h1 className='contact_text'>Handicraft tour</h1>
                    <p className='contact_suptitle'><p class="excerpt">Milti-active tour</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="contact_num">9 days</div>
                    </div>
                </div>
            </div>
        </section>
    )
}
