import React from 'react';
// img
import Events_img_1 from '../../../images/events_img/events_img_1.jpg';
import Events_img_2 from '../../../images/events_img/events_img_2.jpg';
import Events_img_3 from '../../../images/events_img/events_img_3.jpg';
import Events_img_4 from '../../../images/events_img/events_img_4.jpg';
import Events_img_5 from '../../../images/events_img/events_img_5.jpg';
import Events_img_6 from '../../../images/events_img/events_img_6.jpg';
import Events_img_7 from '../../../images/events_img/events_img_7.jpg';
import Events_img_8 from '../../../images/events_img/events_img_8.jpg';
import Events_img_9 from '../../../images/events_img/events_img_9.jpg';
import Events_img_10 from '../../../images/events_img/events_img_10.jpg';
import Events_img_11 from '../../../images/events_img/events_img_11.jpg';
import Events_img_12 from '../../../images/events_img/events_img_12.jpg';
import Events_img_13 from '../../../images/events_img/events_img_13.jpg';
import Events_img_14 from '../../../images/events_img/events_img_14.jpg';
import Events_img_15 from '../../../images/events_img/events_img_15.jpg';
import Events_img_16 from '../../../images/events_img/events_img_16.jpg';
import Events_img_17 from '../../../images/events_img/events_img_17.jpg';
import Events_img_18 from '../../../images/events_img/events_img_18.jpg';
import Events_img_19 from '../../../images/events_img/events_img_19.jpg';
import Events_img_20 from '../../../images/events_img/events_img_20.jpg';

export default function EventsTravel() {
    return (
        <section className='eventstravel'>
            <div className="events_block">
                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_1} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">Aigul flower and Madygine geopark</h2>
                        <p className="events_strong">26-28 April</p>
                        <p className="events_strong">“Regional Tourist Forum-Exhibition “Sustainable Tourism Development in Batken Region, Promotion of Local Tourist Objects in the Geopark “Madigene” – a Potential UNESCO Geopark”, within the framework of the tourist festivals “Aigul Gulu”, “Kilem” and “Batken Rice”.”</p>
                        <p className="events_place"><span>Place:</span> Batken city, Kyrgyzstan</p>
                        <p className="events_place"><span>Organizers:</span>  Department of Tourism, tourist associations and destinations (by agreement), international and domestic partners for development</p>
                        <p className="events_text">“The promotion of sustainable tourism in the Batken region and the promotion of multicultural, national unity, accumulation of initiatives for friendship and harmony, strengthening of relationships between different nationalities living in the territory of Kyrgyzstan.”</p>
                        <p className="events_text">This statement suggests that the event aims to promote sustainable tourism practices in the Batken region, while also emphasizing the importance of cultural diversity and unity among the different ethnic groups living in Kyrgyzstan. The event hopes to encourage initiatives that promote friendship and harmony, while also strengthening relationships between different nationalities.</p>
                        <p className="events_place"><span>Contact:</span> +996 312 62 18 61; +996771 65 85 12,  Nazgul Akjolova</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_2} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">International tourism exhibition-fair ITF 2023</h2>
                        <p className="events_place"><span>Date:</span> May 14, 2023</p>
                        <p className="events_place"><span>Place:</span> Issyk-Kul region, Cholpon-Ata city.</p>
                        <p className="events_text">ITF 2021 is an annual meeting place for international travel companies, tour operators and agencies, representatives of government agencies for tourism development, airlines, hotels, carriers and the media sector.</p>
                        <p className="events_place"><span>Organizers:</span>  Department of Tourism</p>
                        <p className="events_place"><span>Contact:</span> +996 312 62 18 61; +996771 65 85 12,  Nazgul Akjolova</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_3} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">Kymyz Bazar</h2>
                        <p className="events_place"><span>Date:</span> May 20, 2023</p>
                        <p className="events_place"><span>Place:</span> Teahouse “Baibol”, st. Madieva, d. 18,  Kok-Jar, Bishkek city</p>
                        <p className="events_text">At the Kymyz Bazaar festival, conditions will be created for domestic producers to popularize the national drink and improve the quality of the product. The main goal of the event is to increase the awareness and demand for kymyz among the local population and tourists. The program includes traditional games, a folklore concert, and handicraft, and cooking classes.</p>
                        <p className="events_text">Kymyz is a traditional fermented drink that is popular in Kyrgyz culture. It is made from mare’s milk, which is fermented and then distilled to produce a slightly alcoholic drink. The fermentation process is carried out using lactobacillus bacteria, which convert the lactose in the mare’s milk into lactic acid.</p>
                        <p className="events_place"><span>Contact:</span> +996770333323, Mr. Mansur Abylaev</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_4} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">Tushoo Toy</h2>
                        <p className="events_place"><span>Date:</span> 01 June, 2023</p>
                        <p className="events_place"><span>Place:</span> Bokonbaevo village, Manjyly</p>
                        <p className="events_text">Tushoo Toy, also known as Tushuk-Torgoo, is a traditional Kyrgyz cultural event that is held during the winter months. The word “tushoo” means “meeting” or “gathering” in the Kyrgyz language, while “toy” means “celebration” or “festival”.</p>
                        <p className="events_text">Tushoo Toy is a time for families, friends, and communities to come together to celebrate the winter season and to enjoy traditional Kyrgyz food, music, and dance. The event usually takes place in a yurt, which is a portable, round tent made of felt and wooden poles, and is the traditional dwelling of the Kyrgyz people.</p>
                        <p className="events_text">During the Tushoo Toy festival, families will prepare traditional Kyrgyz dishes such as beshbarmak, a dish made with boiled meat and noodles, and kuurdak, a dish made with fried meat and potatoes. They will also serve tea and other hot beverages to keep warm in the cold winter weather.</p>
                        <p className="events_text">The festival also includes traditional music and dance performances, with people singing and playing musical instruments such as the komuz (a three-stringed instrument made from wood), the kyl-kiyak (a two-stringed instrument played with a bow), and the temir-ooy (a percussion instrument made from metal). Traditional Kyrgyz dances such as the kara-jorgo and the chopan-ata are also performed during the festival.</p>
                        <p className="events_text">Tushoo Toy is an important cultural event in Kyrgyz culture, and is a time for families and communities to come together, celebrate their traditions, and enjoy each other’s company during the winter months.</p>
                        <p className="events_place"><span>Contact:</span> +996 700 267 795; +996 778 267 795, Mr.Bakyt Choitonbaev</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_5} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">Festival “Bugu”</h2>
                        <p className="events_place"><span>Date:</span>June 20. 2023</p>
                        <p className="events_place"><span>Place:</span>Naryn region, Naryn State Reserve, Nursery “Bugu Maral”</p>
                        <p className="events_text">
                            <p>In order to develop and preserve the heritage of the traditions of the Kyrgyz people (handicrafts, traditions, customs, national cuisine, national games – burkut saluu, hunting with taigans, salbuurun).</p>
                            <p>Also, there will be a competition of national clothes.</p>
                            <p>Short films about “Bug Ene”, “Ak Ilbirs” will be shown, where a folklore and ethnographic concert will also be shown in parallel</p>
                        </p>
                        <p className="events_place"><span>Contact:</span> +996 700 267 795; +996 778 267 795, Mr.Bakyt Choitonbaev</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_6} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">International Ethnofestival “Motherland Karakush”</h2>
                        <p className="events_place"><span>Date:</span>25-27 June, 2023</p>
                        <p className="events_place"><span>Place:</span>Chui region, Chui district</p>
                        <p className="events_place"><span>Organizing by: </span> NGO “Kyrgyz Taigan Club” Chairman – Murataliev Bolot Anarbekovich & Department of Tourism. Tel; +996 755 736 933 ; +996 312 621 861</p>
                        <p className="events_text">
                            <p>Presentation of new tourist routes.</p>
                            <p>Promotion of local natural resources, attractions,</p>
                            <p>Dissemination of information and drawing the attention of contemporaries to the problem of biodiversity conservation</p>
                        </p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_7} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">AGRO-ETNO Festival</h2>
                        <p className="events_place"><span>Date:</span>25-27 June, 2023</p>
                        <p className="events_place"><span>Place:</span> Issyk-Kul region, South coast of Issyk-Kul Yurt camp “Zhaychy” near the village. Kok-Sai</p>
                        <p className="events_place"><span>Contact:</span> Mr. Baatyrbek Akmatove, +996 553622962; +996 557556455</p>
                        <p className="events_text">“A folk show, exhibition of domestic animals, woolen products, national dishes, and Kyrgyz national and equestrian games.”</p>
                        <p className="events_text">This statement suggests that there is an event taking place that includes several different activities and attractions. The “folk show” likely includes performances of traditional music, dance, and other forms of cultural expression. The “exhibition of domestic animals” may showcase various animals that are common in Kyrgyz culture, such as horses, sheep, or yaks. The “woolen products” likely refers to clothing or other items made from wool, which is a common material in Kyrgyz culture.</p>
                        <p className="events_text">The “national dishes” likely refers to traditional Kyrgyz cuisine, which includes dishes such as beshbarmak, lagman, and manti. Finally, the “Kyrgyz national and equestrian games” may include activities such as horse racing, Kok-boru (a game similar to polo), and wrestling.</p>
                        <p className="events_text">Overall, this statement suggests that the event is focused on showcasing and celebrating various aspects of Kyrgyz culture, including food, music, animals, and traditional games.</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_8} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">Festival – celebration of the world status – Issyk-Kul – Homeland of the yurt</h2>
                        <p className="events_place"><span>Date:</span>15 July, 2015 </p>
                        <p className="events_place"><span>Place:</span>Issyk-Kul region, Ton district, Kyzyl-Tuu village</p>
                        <p className="events_place"><span>Organizing by:</span>  Destinations UBIK Mrs;Gulzat, tel; +996558980990</p>
                        <p className="events_text">City of Yurt making Masters Kyzyl Tuu” is an annual festival held to develop tourism and handicrafts in the region.</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_9} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">Festival “Muras” (Heritage)</h2>
                        <p className="events_place"><span>Date:</span> July 21, 2023</p>
                        <p className="events_place"><span>Place:</span>Naryn region, jailoo Kichi Kara-Keche</p>
                        <p className="events_place"><span>Organizing by:</span>Zhumgal State Administration, Mrs; Omuraliyeva Fatima, tel; +996703814616</p>
                        <p className="events_text">The festivals that preserve the traditions and customs of the Kyrgyz people are colorful and vibrant celebrations that reflect the rich cultural heritage of this Central Asian country. These festivals are a time for the Kyrgyz people to come together to celebrate their unique cultural identity, share their customs, and honor their ancestors.</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_10} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">Eco-cultural action “KOL ENE”</h2>
                        <p className="events_place"><span>Date:</span>July 25, 2023</p>
                        <p className="events_place"><span>Place:</span> Issyk-Kul region, Ton district, Bokonbaevo village, Coastal zone “Manzhyly-Ata”</p>
                        <p className="events_place"><span>Organizing by:</span>Bokonbaevo-Manzhyly” Mr.Bakyt Choytonbaev. +996 700 267 795; +996 778 267 795</p>
                        <p className="events_text">
                            <p>Target:</p>
                            <p>– preservation of the eco-system of the southern coast of Issyk-Kul,</p>
                            <p>– water thanksgiving event;</p>
                            <p>– cleaning of the coastal zone</p>
                        </p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_11} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">II Large Regional Forum-Exhibition: Kyrgyzstan on the Branches of the Great Silk Road, Edelweiss Festival</h2>
                        <p className="events_place"><span>Date:</span>July 27, 2023</p>
                        <p className="events_place"><span>Place:</span>Osh region and Osh city, Alai district</p>
                        <p className="events_place"><span>Organizing by:</span>The Department of Tourism and Mayor’s Office of Osh city, tel ; +996312621861; +996 502 89 89 20 Mrs. Shirin</p>
                        <p className="events_text">Popularization of tourism potential in Osh region</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_12} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">Festival “OYMO”</h2>
                        <p className="events_place"><span>Date:</span>27-30 July, 2023</p>
                        <p className="events_place"><span>Place:</span>Issyk-Kul region Tamchy village</p>
                        <p className="events_place"><span>Organizing by:</span>Mrs,Koshoeva Meerim, tel; +996 555 960 577; +996 533 920 290</p>
                        <p className="events_text">Artisans from different countries supply their products on the shelves of the Craft Fair</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_13} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">Festival of Music and Arts in Central Asia “Kөl Fest”</h2>
                        <p className="events_place"><span>Date:</span>28-30 July 2023</p>
                        <p className="events_place"><span>Place:</span>28-30 July 2023</p>
                        <p className="events_place"><span>Organizing by:</span> LLC “Be travel” Mr.Batyrbekov Chyngyz, tel; +996556331106</p>
                        <p className="events_text">Kөl Fest is the first music and art festival in Central Asia (CA), bringing together people, music, and art from different countries. Kөl Fest is a place where you can take a break from everyday worries, and plunge into the culture and modern music of different countries.</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_14} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">National Horse Games “Kok-Boru”</h2>
                        <p className="events_place"><span>Date:</span> July 30. 2023</p>
                        <p className="events_place"><span>Place:</span>Chui region, Zhaiyl district, Kyzyl-Oi village, Suusamyr ayil</p>
                        <p className="events_place"><span>Organizing by:</span>Mr. Artyk Kulubaev, tel; +996 312 464 785;+ 996 555 417 847; + 996 559 568 838 </p>
                        <p className="events_text">Traditional equestrian games in Ulak-Tartysh, Kyz-Kuumai, Tyiyn-Enmei and Er-Enish. </p>
                        <p className="events_text">One of the most popular and well-known national horse games in Kyrgyzstan is Kok-Boru, which is also known as Buzkashi or Oglak Tartis. Kok-Boru is a traditional game that has been played by the Kyrgyz people for centuries and is considered an important part of their cultural heritage.</p>
                        <p className="events_text">In Kok-Boru, two teams of horse riders compete to score points by carrying a dead goat or sheep carcass (known as a “buzkashi”) and throwing it into the opposing team’s goal area. The game is played on a large field, and each team tries to control the carcass and carry it to their goal while fending off the opposing team’s riders.</p>
                        <p className="events_text">The game can be quite intense and physically demanding, as riders use their horses to block and tackle each other in an attempt to gain possession of the carcass. Despite its rough nature, Kok-Boru is also a very strategic game, and riders must work together and communicate effectively to succeed.</p>
                        <p className="events_text">Kok-Boru is not just a sport but also a cultural tradition that is deeply ingrained in the Kyrgyz way of life. The game is often played at special events and festivals, and it serves as a way to celebrate the country’s nomadic heritage and strong equestrian culture.</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_15} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">J-FEST SUMMER</h2>
                        <p className="events_place"><span>Date:</span>August 1, 2023</p>
                        <p className="events_place"><span>Place:</span>Issyk-Kul region, Ak-Suu district, Jyrgalan Valley</p>
                        <p className="events_place"><span>Organizing by:</span>Destination “Jyrgalan”, Mr. Ibakov Emil, tel; 996557207777</p>
                        <p className="events_text">Presentation of summer tourism products – horse, bicycle and hiking tours. Ethnocultural and folklore concert</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_16} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">Tangor Fest</h2>
                        <p className="events_place"><span>Date:</span>Date: August 2, 2023</p>
                        <p className="events_place"><span>Place:</span>Issyk-Kul region Jeti-Oguz district, Yrdyk village</p>
                        <p className="events_place"><span>Organizing by:</span>Destinations South Shore of Issyk-Kul, tel; +996558980990</p>
                        <p className="events_text">Annual gastro festival, in order to popularize the life, cuisine, and culture of tiny peoples</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_17} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">Felt carpet master “Shirdakchy”</h2>
                        <p className="events_place"><span>Date:</span> August 12, 2023</p>
                        <p className="events_place"><span>Place:</span>Teahouse “Baibol”, st. Madieva, d. 18, district Kok-Jar, Bishkek city</p>
                        <p className="events_place"><span>Organizing by:</span>Mr. Mansur Abylaev, tel; 996770333323</p>
                        <p className="events_text">Annual competition among craftswomen in shyrdak from all over the country. A holiday organized for shyrdak lovers in order to popularize the Kyrgyz handicraft technique. The program includes traditional games, folklore performances, craftsman classes, and more.</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_18} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">International cycling competition “Silk Road Mountain Race 2023”</h2>
                        <p className="events_place"><span>Date:</span>12-26 August 2023</p>
                        <p className="events_place"><span>Place:</span>Issyk-Kul region, Karakol, Cholpon-Ata</p>
                        <p className="events_place"><span>Organizing by:</span>Mr, Nelson Trees, 33615060806</p>
                        <p className="events_text">Ecotourism promotion</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_19} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">Festival “NUKURA”</h2>
                        <p className="events_place"><span>Date:</span>September 17, 2023</p>
                        <p className="events_place"><span>Place:</span>Naryn region, Naryn city</p>
                        <p className="events_place"><span>Organizing by:</span>Naryn administrating department, Mrs, Asangazy kyzy Elnura, tel; +996500535430</p>
                        <p className="events_text">Nukura” festival is held in order to attract both Kyrgyz and foreign tourists to the city of Naryn, and to get acquainted with the local culture and traditions that have existed for many years since the founding of the city of Naryn. Also, a demonstration of the traditions of the Kyrgyz people is envisaged.</p>
                    </div>
                </div>

                <div className="events_left_right">
                    <div className="events_left">
                        <img src={Events_img_20} alt="" />
                    </div>

                    <div className="events_right">
                        <h2 className="events_title">Autumn Nut Marathon – 2023</h2>
                        <p className="events_place"><span>Date:</span>October 7, 2023</p>
                        <p className="events_place"><span>Place:</span>Jalal-Abad region, Bazar-Korgon district, Arslanbob walnut-fruit forests</p>
                        <p className="events_place"><span>Organizing by:</span>Mr. Hayat Tarikov, tel; 996777342476</p>
                        <p className="events_text">Marathon in the world’s largest walnut forest.
                            During the holiday, you can see a lot of interesting traditional moments and participate in various competitions.</p>
                    </div>
                </div>
            </div>

        </section>
    )
}
