import React from 'react';
import { Link } from 'react-router-dom';
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg7 from '../../images/CardImg/card_img_7.jpg';
import Cardimg14 from '../../images/CardImg/card_img_14.jpg';
import Cardimg9 from '../../images/CardImg/card_img_9.jpg';
import Cardimg10 from '../../images/CardImg/card_img_10.jpg';
import Cardimg5 from '../../images/CardImg/card_img_5.jpg';
import Cardimg15 from '../../images/CardImg/card_img_15.jpg';
import Cardimg16 from '../../images/CardImg/card_img_16.jpg';
import Cardimg17 from '../../images/CardImg/card_img_17.jpg';
import Cardimg18 from '../../images/CardImg/card_img_18.jpg';
import Cardimg19 from '../../images/CardImg/card_img_19.jpg';
import Cardimg20 from '../../images/CardImg/card_img_20.jpg';
import Cardimg4 from '../../images/CardImg/card_img_4.jpg';
import Cardimg21 from '../../images/CardImg/card_img_21.jpg';
import Cardimg22 from '../../images/CardImg/card_img_22.jpg';
import Cardimg8 from '../../images/CardImg/card_img_8.jpg';
import Cardimg23 from '../../images/CardImg/card_img_23.jpg';
import Cardimg6 from '../../images/CardImg/card_img_6.jpg';



export default function CultureTours() {
    return (
        <section className='culturetours'>

            <h1 className="toptravel_title">Top Culture travel deals</h1>

            <div className="toptravelscrol">

                <div className="toptravel_block">
                    <h2 className="toptravel_departng">Departing</h2>
                    <h2 className="toptravel_trip">Trip</h2>
                    <h2 className="toptravel_fotos">Fotos</h2>
                    <h2 className="toptravel_days">Days</h2>
                    <h2 className="toptravel_from">From USD</h2>
                </div>

                <div className="toptravel_bg">

                    <div className="toptravel_card">
                        <div className="toptravel_card_num">02 Feb 2024</div>
                        <div className="toptravel_card_trip">
                            <h1 className="toptravel_card_title">Okavango Experience</h1>
                            <p className="toptravel_card_suptitle">Johannesburg → Victoria Falls</p>
                        </div>
                        <div className="toptravel_card_img">
                            <img src={Cardimg21} alt="" />
                        </div>
                        <div className="toptravel_card_day">10</div>
                        <div className="toptravel_card_price">
                            <span className="toptravel_card_discount">1,500</span>
                            <div className="toptravel_card_cost">$1,299</div>
                        </div>

                        <div className="toptravel_card_view">View trip ›</div>
                    </div>

                    <div className="toptravel_card toptravel_card_border">
                        <div className="toptravel_card_num">02 Feb 2024</div>
                        <div className="toptravel_card_trip">
                            <h1 className="toptravel_card_title">Okavango Experience</h1>
                            <p className="toptravel_card_suptitle">Johannesburg → Victoria Falls</p>
                        </div>
                        <div className="toptravel_card_img">
                            <img src={Cardimg22} alt="" />
                        </div>
                        <div className="toptravel_card_day">10</div>
                        <div className="toptravel_card_price">
                            <span className="toptravel_card_discount">1,500</span>
                            <div className="toptravel_card_cost">$1,299</div>
                        </div>

                        <div className="toptravel_card_view">View trip ›</div>
                    </div>
                </div>
            </div>

            <div className="biketours_block">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg7} alt="" />
                    </div>

                    <h1 className='biketours_text'>Road Adventure-KZ,KG,UZ</h1>
                    <p className='biketours_suptitle'><p class="excerpt"></p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">18 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg14} alt="" />
                    </div>

                    <h1 className='biketours_text'>Winter adventure tour in Kyrgyzstan.</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Horse trek</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">8 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg9} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road#4. Uzbekistan – Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Central Asia</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">14 days</div>
                    </div>
                </Link>
            </div>

            <div className="biketours_block pt-[30px]">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg10} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road#3. Kazakstan – Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Central Asia</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">12 days</div>
                    </div>
                </Link>
                <Link to="/datas"   className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg5} alt="" />
                    </div>

                    <h1 className='biketours_text'>Children’s Quiz Tour</h1>
                    <p className='biketours_suptitle'><p class="excerpt"></p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">7 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg15} alt="" />
                    </div>

                    <h1 className='biketours_text'>Handicraft tour</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Milti-active tour</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">9 days</div>
                    </div>
                </Link>
            </div>

            <div className="biketours_block pt-[30px]">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg16} alt="" />
                    </div>

                    <h1 className='biketours_text'>Winter adventures in Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Family tours</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">12 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg17} alt="" />
                    </div>

                    <h1 className='biketours_text'>Discovery Kyrgyzstan. Bis</h1>
                    <p className='biketours_suptitle'><p class="excerpt"></p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">12 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg18} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Tour: Kazakhstan and Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Kyrgyzstan and Kazakhstan</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">9 days</div>
                    </div>
                </Link>
            </div>

            <div className="biketours_block pt-[30px]">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg19} alt="" />
                    </div>

                    <h1 className='biketours_text'>The tallest waterfall in Central Asia “Shar”.</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Milti-active tour</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">4 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg20} alt="" />
                    </div>

                    <h1 className='biketours_text'>Kyrgyz Cuisine Tour</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Family tours</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">7 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg4} alt="" />
                    </div>

                    <h1 className='biketours_text'>Family traveling in Kyrgyzstan.</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Family tours</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">9 days</div>
                    </div>
                </Link>
            </div>

            <div className="biketours_block pt-[30px]">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg21} alt="" />
                        <div className="deals_geen">from <br />$200</div>
                    </div>

                    <h1 className='biketours_text'>Express trip to the Lake Song-Kul.</h1>
                    <p className='biketours_suptitle'><p class="excerpt"></p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">2 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg22} alt="" />
                    </div>

                    <h1 className='biketours_text'>Pamir Highway</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Pamir</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">10 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg8} alt="" />
                    </div>

                    <h1 className='biketours_text'>Lake hopping: Issyk-Kul and Song-Kul</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Milti-active tour</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">7 days</div>
                    </div>
                </Link>
            </div>

            <div className="biketours_block pt-[30px] pb-[100px]">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg23} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk-Road Tour: Kyrgyzstan & Uzbekistan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Kyrgyzstan & Uzbekistan</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">15 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg6} alt="" />
                        <div className="deals_geen">from <br />$150</div>
                    </div>

                    <h1 className='biketours_text'>Lake Kol-Ukok -nomad’s life</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Horseback tour</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">2 days</div>
                    </div>
                </Link>

            </div>

        </section>
    )
}
