import React from 'react';
import RightModal from '../RightModal'
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg6 from '../../images/CardImg/card_img_6.jpg';
import Cardimg17 from '../../images/CardImg/card_img_17.jpg';
import Cardimg16 from '../../images/CardImg/card_img_16.jpg';

export default function RightNModalWildlife() {
    return (
        <section className="rightmodalwillife">
            <RightModal />

            <h4 className="contact_title">Most popular tours</h4>

            <div className="contact_bg_block">
                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg6} alt="" />
                        <div className="deals_geen">from <br /> $150</div>
                    </div>

                    <h1 className='contact_text'>Pamir Highway</h1>
                    <p className='contact_suptitle'><p class="excerpt">Pamir</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">2 days</div>
                    </div>
                </div>

                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg17} alt="" />
                    </div>

                    <h1 className='contact_text'>Discovery Kyrgyzstan. Bishkek & Osh.</h1>
                    <p className='contact_suptitle'><p class="excerpt"></p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">12 days</div>
                    </div>
                </div>



                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg16} alt="" />
                    </div>

                    <h1 className='contact_text'>Winter adventures in Kyrgyzstan</h1>
                    <p className='contact_suptitle'><p class="excerpt">Family tours</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">12 days</div>
                    </div>
                </div>
            </div>
        </section>
    )
}
