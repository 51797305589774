import React from 'react'
import RightModal from '../RightModal';
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg23 from '../../images/CardImg/card_img_23.jpg';
import Cardimg30 from '../../images/CardImg/card_img_30.jpg';
import Cardimg17 from '../../images/CardImg/card_img_17.jpg';

export default function RightModalRoad() {
    return (
        <section className="rightmodalroad">
            <RightModal />

            <h4 className="contact_title">Most popular tours</h4>

            <div className="contact_bg_block">
                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg23} alt="" />
                    </div>

                    <h1 className='contact_text'>Silk-Road Tour: Kyrgyzstan & Uzbekistan</h1>
                    <p className='contact_suptitle'><p class="excerpt">Kyrgyzstan & Uzbekistan</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">15 days</div>
                    </div>
                </div>


                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg30} alt="" />
                    </div>

                    <h1 className='contact_text'>Travelling from Kichik-Alay to Pamir-Alay.</h1>
                    <p className='contact_suptitle'><p class="excerpt">Alay mountains, Lenin peak panorama</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">7 days</div>
                    </div>
                </div>

                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg17} alt="" />
                    </div>

                    <h1 className='contact_text'>Discovery Kyrgyzstan. Bishkek & Osh.</h1>
                    <p className='contact_suptitle'><p class="excerpt"></p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">12 days</div>
                    </div>
                </div>
            </div>
        </section>
    )
}
