import React from 'react';
import { Link } from 'react-router-dom';
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg25 from '../../images/CardImg/card_img_25.jpg';
import Cardimg35 from '../../images/CardImg/card_img_35.jpg';
import Cardimg38 from '../../images/CardImg/card_img_38.jpg';
import Cardimg27 from '../../images/CardImg/card_img_27.jpg';
import Cardimg24 from '../../images/CardImg/card_img_24.jpg';
import Cardimg39 from '../../images/CardImg/card_img_39.jpg';
import Cardimg28 from '../../images/CardImg/card_img_28.jpg';
import Cardimg29 from '../../images/CardImg/card_img_29.jpg';
import Cardimg40 from '../../images/CardImg/card_img_40.jpg';
import Cardimg41 from '../../images/CardImg/card_img_41.jpg';
import Cardimg12 from '../../images/CardImg/card_img_12.jpg';
import Cardimg8 from '../../images/CardImg/card_img_8.jpg';
import Cardimg42 from '../../images/CardImg/card_img_42.jpg';
import Cardimg30 from '../../images/CardImg/card_img_30.jpg';
import Cardimg31 from '../../images/CardImg/card_img_31.jpg';

export default function TrekkingTour() {
    return (
        <section className='trekkingtours'>

            <h1 className="toptravel_title">Top Trekking travel deals</h1>

            <div className="toptravelscrol">

                <div className="toptravel_block">
                    <h2 className="toptravel_departng">Departing</h2>
                    <h2 className="toptravel_trip">Trip</h2>
                    <h2 className="toptravel_fotos">Fotos</h2>
                    <h2 className="toptravel_days">Days</h2>
                    <h2 className="toptravel_from">From USD</h2>
                </div>

                <div className="toptravel_bg">

                    <div className="toptravel_card">
                        <div className="toptravel_card_num">02 Feb 2024</div>
                        <div className="toptravel_card_trip">
                            <h1 className="toptravel_card_title">Okavango Experience</h1>
                            <p className="toptravel_card_suptitle">Johannesburg → Victoria Falls</p>
                        </div>
                        <div className="toptravel_card_img">
                            <img src={Cardimg40} alt="" />
                        </div>
                        <div className="toptravel_card_day">10</div>
                        <div className="toptravel_card_price">
                            <span className="toptravel_card_discount">1,500</span>
                            <div className="toptravel_card_cost">$1,299</div>
                        </div>

                        <div className="toptravel_card_view">View trip ›</div>
                    </div>

                    <div className="toptravel_card toptravel_card_border">
                        <div className="toptravel_card_num">02 Feb 2024</div>
                        <div className="toptravel_card_trip">
                            <h1 className="toptravel_card_title">Okavango Experience</h1>
                            <p className="toptravel_card_suptitle">Johannesburg → Victoria Falls</p>
                        </div>
                        <div className="toptravel_card_img">
                            <img src={Cardimg41} alt="" />
                        </div>
                        <div className="toptravel_card_day">10</div>
                        <div className="toptravel_card_price">
                            <span className="toptravel_card_discount">1,500</span>
                            <div className="toptravel_card_cost">$1,299</div>
                        </div>

                        <div className="toptravel_card_view">View trip ›</div>
                    </div>
                </div>
            </div>

            <div className="biketours_block ">
                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg25} alt="" />
                    </div>

                    <h1 className='biketours_text'>“Tranquil Treasures: Discovering Kol-Ukok and Taldy Valley in the Kyrgyz Mountains”</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Trekking</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">4 days</div>
                    </div>
                </Link>

                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg35} alt="" />
                    </div>

                    <h1 className='biketours_text'>Lake Ala-Kol. 3 days trekking tour.</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Trekking</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">3 days</div>
                    </div>
                </Link>

                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg38} alt="" />
                    </div>

                    <h1 className='biketours_text'>Merzbacher Lake and Enilchek- 6 days</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Trekking</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">6 days</div>
                    </div>
                </Link>
            </div>
            <div className="biketours_block pt-[30px]">
                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg27} alt="" />
                    </div>

                    <h1 className='biketours_text'>Alay Heights</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Trekking</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">6 days</div>
                    </div>
                </Link>

                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg24} alt="" />
                    </div>

                    <h1 className='biketours_text'>Climbing peak Palatka, 4 650m</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Trekking</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">11 days</div>
                    </div>
                </Link>

                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg39} alt="" />
                    </div>

                    <h1 className='biketours_text'>From Belogorka waterfall to the Lake Song-Kol (11 days).</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Trekking</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">11 days</div>
                    </div>
                </Link>
            </div>
            <div className="biketours_block pt-[30px]">
                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg28} alt="" />
                    </div>

                    <h1 className='biketours_text'>The mountain Lake Kol-Ukok</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Horse trek</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">4 days</div>
                    </div>
                </Link>

                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg29} alt="" />
                    </div>

                    <h1 className='biketours_text'>Lake Alatay & Sary-Chelek, horse trek.</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Horse trek</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">6 days</div>
                    </div>
                </Link>


                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg40} alt="" />
                    </div>

                    <h1 className='biketours_text'>Tour to Engilchek Base Camp via Merzbacher Lake</h1>
                    <p className='biketours_suptitle'><p class="excerpt"></p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">10 days</div>
                    </div>
                </Link>
            </div>
            <div className="biketours_block pt-[30px]">
                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg41} alt="" />
                    </div>

                    <h1 className='biketours_text'>Trekking from Jyrgalan village to the Lake Alakol.</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Trekking</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">8 days</div>
                    </div>
                </Link>

                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg12} alt="" />
                    </div>

                    <h1 className='biketours_text'>Trekking to the Lake Kel-Suu</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Trekking</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">7 days</div>
                    </div>
                </Link>


                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg8} alt="" />
                    </div>

                    <h1 className='biketours_text'>Jailoo Hopping to the Lake Song-Kul for 9 days</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Trekking</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">9 days</div>
                    </div>
                </Link>
            </div>
            <div className="biketours_block pt-[30px]">
                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg42} alt="" />
                    </div>

                    <h1 className='biketours_text'>Jailoo hiking “Lake Song-Kul”</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Horseback tour</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">3 days</div>
                    </div>
                </Link>

                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg30} alt="" />
                    </div>

                    <h1 className='biketours_text'>Travelling from Kichik-Alay to Pamir-Alay.</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Alay mountains, Lenin peak panorama</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">7 day</div>
                    </div>
                </Link>

                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg31} alt="" />
                    </div>

                    <h1 className='biketours_text'>Trekking from Chui Valley to Issyk-Kul</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Horseback or trekking tour</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" />  </div>
                        <div className="biketours_num">3 day</div>
                    </div>
                </Link>
            </div>

            <div className="trekking_bg_card">
                <p>Kyrgyzstan is the perfect country for hiking. It is connected to the two largest and highest mountain ranges – Pamir and Tien Shan. Many tourists consider these mountains to be the most beautiful in the world!</p>
                <p>There is a wide variety of trekking tours in Kyrgyzstan, varying in complexity and type. The tours are not limited to mountains. For example, horseback riding such as jailoo hikes to Lake Song-Kul or trekking from Chui Valley to Issyk-Kul can be offered. During this period, travelers visited natural parks (Chong-Kemin), lakes (Kol-Kogur, Issyk-Kul, etc.), etc. It is the best approach to unity with nature.</p>
                <p>Cost of trekking tours in Kyrgyzstan</p>
                <p>The cost of a hike in Kyrgyzstan varies depending on the tour, as the details, routes and locations can vary greatly. The customer also has the option of choosing a group trip or designing a personal trip according to their wishes. There are some of the most popular trekking tours that are worth checking out.</p>
                <p>Trekking from Jyrgalan village to Lake Alakol.</p>
                <p>Probably the best 8 day tour for all mountain lovers. From the village of Jyrgalan, travelers will discover all the beauty and charm of Lake Alakol. Such a trip costs $80 for a group trip and $100 for an individual trip. You will encounter crystal clear mountain lakes, breathtaking landscapes and unforgettable memories.</p>
                <p>Tour to the Engilchek base camp across Lake Merzbach</p>
                <p>Another great 10 day tour that costs $80 for a group tour and $100 for an individual tour. Amazing way to visit Tengri country and high altitude pass. Incredible nature, camping and adventure with the goal of reaching Khan Tengri and Victory Peaks. The breathtaking places where time stands still.</p>
                <p>Benefits of Trek in Kyrgyzstan</p>
                <p>Trekking is another level of travel. It offers numerous benefits that attract many travelers and is considered one of the best active vacation types.</p>
                <p>Nothing can showcase the beauty of such places like hiking. Try it yourself. See the untouched nature up close.<br />
                    Great adventures. Visit and reach mountains, set up camp near mountain rivers and lakes. Get to know real nomads. These memories will stay with you for a lifetime. It&#8217;s also a great way to make new friends.<br />
                    It doesn&#8217;t matter if you are an experienced traveler or want to try this activity for the first time &#8211; Kyrgyzstan has numerous best and proven routes that make trekking an amazing and comfortable adventure.<br />
                    Get rid of stress and get a good fitness workout. Nature and its great views will relax you. Your body experiences an effective improvement in cardiovascular strength when you walk for several days.<br />
                    Get to know the true culture of Central Asia. Typically, trekking tours include some Kyrgyzstan pastoral camps. Find out how they live in yurts and organize jailoos (summer pastures).</p>

            </div>
        </section>
    )
}
