import React from 'react'
import '../../css/tours.css';
import { Link } from 'react-router-dom';

export default function BikeBg() {
    return (
        <section className='bikebg'>
            <div className="container">
                <div className='blog_hone_text'>
                    <Link className='blog_home' to="/">Home/</Link>
                    <div className="blog_home">Tours/</div>
                    <div className="blog_home">Bike tours</div>
                </div>
                <hr />
                <h1 className='blog_title'>Kyrgyzstan Bike tours</h1>
                <p className='bike_siptitle'>Create unforgettable memories with Bike tours in Kyrgyzstan</p>
            </div>
        </section>
    )
}
