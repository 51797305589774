import React from 'react';
import Header from '../Home/Header';
import TrekkingBg from './TrekkingBg';
import TrekkingTour from './TrekkingTour';
import RightModaltrekking from './RightModaltrekking';
import Footer from '../Home/Footer';
import '../../css/tours.css';
import BackToTopButton from '../Home/BackToTopButton'

export default function Trekking() {
    return (
        <div>
            <Header />
            <TrekkingBg />
            <div className="container displaycontainer">
                <TrekkingTour />
                <RightModaltrekking />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
