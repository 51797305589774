import React from 'react';
import Header from '../Home/Header';
import WildlifeBg from './WildlifeBg';
import WildlifeTours from './WildlifeTours';
import RightNModalWildlife from './RightNModalWildlife';
import Footer from '../Home/Footer';
import '../../css/tours.css';
import BackToTopButton from '../Home/BackToTopButton'

export default function Wildlife() {
    return (
        <div>
            <Header />
            <WildlifeBg />
            <div className="container displaycontainer">
                <WildlifeTours />
                <RightNModalWildlife />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
