import React from 'react';
import { Link } from 'react-router-dom';

export default function EventsBg() {
    return (
        <section className='eventsbg'>
            <div className="container">
                <div className='blog_hone_text'>
                    <Link className='blog_home' to="/">Home/</Link>
                    <div className="blog_home">Events</div>
                </div>
                <hr />
                <h1 className='blog_title'>Events & Festivals</h1>
            </div>
        </section>
    )
}
