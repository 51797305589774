import React from 'react';
import Header from '../../Home/Header';
import GalleyBg from './GalleyBg';
import GalleryTravel from './GalleryTravel';
import RightModalGallery from './RightModalGallery';
import Footer from '../../Home/Footer';
import '../../../css/kyrgizstan/kyrgyzstan.css';
import BackToTopButton from '../../Home/BackToTopButton';

export default function Gallery() {
    return (
        <div>
            <Header />
            <GalleyBg />
            <div className="container displaycontainer">
                <GalleryTravel />
                <RightModalGallery />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
