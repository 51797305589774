import React from 'react'
import Header from '../Home/Header'
import CultureBg from './CultureBg'
import CultureTours from './CultureTours'
import RightModalCulture from './RightModalCulture'
import Footer from '../Home/Footer'
import '../../css/tours.css'
import BackToTopButton from '../Home/BackToTopButton'

export default function Culture() {
    return (
        <div>
            <Header />
            <CultureBg />
            <div className="container displaycontainer">
                <CultureTours />
                <RightModalCulture />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
