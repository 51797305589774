import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import '../../css/Swipercss/landswiper.css';
import { Pagination } from 'swiper/modules';
import { FaArrowCircleRight, FaArrowCircleLeft } from "react-icons/fa";
// mui 
import Button from '@mui/material/Button';


export default function Land() {
    return (
        <div className="container">

            <center><h1 className="type_title">Ways to travel</h1></center>


            <section className='lanswiper'>
                <div className="land_block">
                    <div id='start' className="cardland">
                        <div className="cardland_img"><img src="https://ibc-static.broad.msu.edu/sites/globaledge/blog/55748.jpg" alt="" /></div>

                        <h1 className="cardland_title">Walking & Trekking tours1</h1>
                    </div>
                    <div className="cardland">
                        <div className="cardland_img"><img src="https://ibc-static.broad.msu.edu/sites/globaledge/blog/55748.jpg" alt="" /></div>

                        <h1 className="cardland_title">Walking & Trekking tours2</h1>
                    </div>
                    <div className="cardland">
                        <div className="cardland_img"><img src="https://ibc-static.broad.msu.edu/sites/globaledge/blog/55748.jpg" alt="" /></div>

                        <h1 className="cardland_title">Walking & Trekking tours3</h1>
                    </div>
                    <div className="cardland">
                        <div className="cardland_img"><img src="https://ibc-static.broad.msu.edu/sites/globaledge/blog/55748.jpg" alt="" /></div>

                        <h1 className="cardland_title">Walking & Trekking tours4</h1>
                    </div>
                    <div className="cardland">
                        <div className="cardland_img"><img src="https://ibc-static.broad.msu.edu/sites/globaledge/blog/55748.jpg" alt="" /></div>

                        <h1 className="cardland_title">Walking & Trekking tours</h1>
                    </div>
                    <h1 id='end' className="cardland_title_btn">
                        <Button variant="contained" disableElevation>
                        View more themes ▸
                        </Button>
                    </h1>
                </div>
            </section>

            <div className="land_buttons">
                <a href="#start"><button className='land_btn_1'><FaArrowCircleLeft className='btnarow' /></button></a>
                <a href="#end"><button className='land_btn_1'><FaArrowCircleRight className='btnarow' /></button></a>
            </div>

        </div>
    )
}
