import React from 'react';
import Header from '../Home/Header';
import DataBg from './DataBg';
import DatasIniterary from './DatasIniterary';
import '../../css/datas.css';
import DatasIniteraryBook from './DatasIniteraryBook';
import RightModalDatas from './RightModalDatas';
import Footer from '../Home/Footer';
import BackToTopButton from '../Home/BackToTopButton';

export default function DatasPage1() {
    return (
        <div>
            <Header />
            <DataBg />
            <div className="container displaycontainer">
                <div>
                    <DatasIniterary />
                    <DatasIniteraryBook />
                </div>
                <RightModalDatas />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
