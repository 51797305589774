import React from 'react';
import Header from '../Home/Header';
import WinterBg from './WinterBg';
import WinterTour from './WinterTour';
import RightModalWinter from './RightModalWinter';
import Footer from '../Home/Footer';
import '../../css/tours.css';
import BackToTopButton from '../Home/BackToTopButton'

export default function () {
    return (
        <div>
            <Header />
            <WinterBg />
            <div className="container displaycontainer">
                <WinterTour />
                <RightModalWinter />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
