import React from 'react';
import Header from '../Home/Header';
import MultiBg from './MultiBg';
import MultiTour from './MultiTour';
import RightModalMulti from './RightModalMulti';
import Footer from '../Home/Footer';
import '../../css/tours.css';
import BackToTopButton from '../Home/BackToTopButton'

export default function Multi() {
    return (
        <div>
            <Header />
            <MultiBg />
            <div className="container displaycontainer">
                <MultiTour />
                <RightModalMulti />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
