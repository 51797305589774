import React from 'react';
import Header from '../Home/Header';
import RoadBg from './RoadBg';
import RoadTour from './RoadTour';
import RightModalRoad from './RightModalRoad';
import Footer from '../Home/Footer';
import '../../css/tours.css';
import BackToTopButton from '../Home/BackToTopButton'

export default function Road() {
    return (
        <div>
            <Header />
            <RoadBg />
            <div className="container displaycontainer">
                <RoadTour />
                <RightModalRoad />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
