import React from 'react';
import Header from '../../Home/Header';
import KyrgyzstanBg from './KyrgyzstanToursBg';
import KyrgyzstranDestinationsPage from './KyrgyzstranToursDestinationsPage';
import RightModalKyrgyzstan from './RightModalKyrgyzstanTours';
import Footer from '../../Home/Footer';
import '../../../css/destinations/destinations.css';
import BackToTopButton from '../../Home/BackToTopButton';

export default function KyrgyzstanPage() {
    return (
        <div>
            <Header />
            <KyrgyzstanBg />
            <div className="container displaycontainer">
                <KyrgyzstranDestinationsPage />
                <RightModalKyrgyzstan />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
