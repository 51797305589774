import React from 'react'
import { Link } from 'react-router-dom'

export default function Upcoming() {
  return (
    <section className='upcoming'>
      <div className="container">
        <div className='blog_hone_text'>
          <Link className='blog_home' to="/">Home/</Link>
          <div className="blog_home">Group Tours 2023</div>
        </div>
        <hr />
        <h1 className='blog_title'>Upcoming group tours 2024</h1>
      </div>
    </section>
  )
}
