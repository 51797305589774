import React from 'react';
import Header from '../../Home/Header';
import SustainableBg from './SustainableBg';
import SustinableTravel from './SustinableTravel';
import RigtModalSustainable from './RigtModalSustainable';
import Footer from '../../Home/Footer';
import '../../../css/kyrgizstan/kyrgyzstan.css'
import BackToTopButton from '../../Home/BackToTopButton';

export default function Sustainabel() {
    return (
        <div>
            <Header />
            <SustainableBg />
            <div className="container displaycontainer">
                <SustinableTravel />
                <RigtModalSustainable />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
