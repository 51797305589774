import React from 'react'

export default function KyrgyzstanAboutTravel() {
  return (
    <section className="kurgyzstanabouttravel">
      <div className="kurgyzstanabouttravelbg">
        <iframe className='kurgyzstanabouttravel_globe' src="https://www.google.com/maps/d/u/0/embed?mid=1_O_iQOh1iG6jVw1qUUmEV0T6U6Bivzc&ehbc=2E312F&noprof=1" width="640" height="480"></iframe>

        <div className="kurgyzstanabouttravel_block">
          <div className="kurgyzstanabouttravel_card">
            <div className="kurgyzstanabouttravel_title">Kyrgyzstan</div>
            <p className='kurgyzstanabouttravel_suptitle'>
              Kyrgyzstan is a country at the heart of Central Asia and a favorite travel destination on the <a href="" className='ahref'>Great Silk Road.</a>  Nomad culture of Kyrgyz people lading Tien-Shan over the centuries gives a chance to stay overnight in a yurt, ride on horseback by the shepherd trails and taste the local homemade bread boorsok with honey and sour cream. All these by the beautiful surrounding of nature – snow-capped mountains, high land lakes and rivers, alpine meadows and true Kyrgyz hospitality.
            </p>
            <div className="kurgyzstanabouttravel_read">
              <a href="" className='ahref'>Read more</a>
            </div>
          </div>

          <div className="kurgyzstanabouttravel_card">
            <div className="kurgyzstanabouttravel_title">Visa</div>
            <p className='kurgyzstanabouttravel_suptitle'>
              <a href="" className='ahref'>Visa-free regime </a> is accepted for more than 60 countries, up to 60 days of staying in Kyrgyzstan. For those who need a visa, we have an E-visa system to get a more simple way to reach our country. An effective initiative of hospitality in the visa apply was taken by the other Central Asian countries. The new initiative of the Silk Visa will give a chance to visit Kazakhstan, Uzbekistan, and Kyrgyzstan with one visa application.
            </p>
            <div className="kurgyzstanabouttravel_read">
              <a href="" className='ahref'>Read more</a>
            </div>
          </div>
        </div>

        <div className="kurgyzstanabouttravel_block">
          <div className="kurgyzstanabouttravel_card">
            <div className="kurgyzstanabouttravel_title">Public transport</div>
            <p className='kurgyzstanabouttravel_suptitle'>
              Driving by car is the most convenient <a href="" className="ahref">transportation in Kyrgyzstan.</a> Public transport is popular among locals and guests. At Western and Eastern bus stations possible to take a sit on bus, minibus and shared taxi for different destinations. Some routes are scheduled, but most of them start the way when the car is full. Possible to rent a car with driver or without a driver. There is a domestic flight connection between <a href="" className="ahref">Bishkek</a> and Osh city. Bishkek-Balykchy train is available in the summertime in June-September.
            </p>
            <div className="kurgyzstanabouttravel_read">
              <a href="" className='ahref'>Read more</a>
            </div>
          </div>

          <div className="kurgyzstanabouttravel_card">
            <div className="kurgyzstanabouttravel_title">Registration</div>
            <p className='kurgyzstanabouttravel_suptitle'>
              There is an order for <a href="" className="ahref">registration</a> of foreign citizens arriving in the Kyrgyz Republic. The registration is made by the territorial units of passport issuing office of the State Registration Service under the government of the Kyrgyz Republic. For most of the Western countries representatives’ duration of stay without registration is 30-60 days. Please keep the registration form, it would be checked on departure. A foreign citizen who violates fixed terms of their stay in the Kyrgyz Republic will be fined.
            </p>
            <div className="kurgyzstanabouttravel_read">
              <a href="" className='ahref'>Read more</a>
            </div>
          </div>
        </div>

        <div className="kurgyzstanabouttravel_block">
          <div className="kurgyzstanabouttravel_card">
            <div className="kurgyzstanabouttravel_title">Accommodation</div>
            <p className='kurgyzstanabouttravel_suptitle'>
              Hotels for the wide range cost could be found in <a href="" className="ahref">Bishkek</a>  and other big cities of the country. Recently hostels became popular and found its admirers. Yurt stay is a traditional nomads dwelling, you can try to in Kyrgyzstan too. At the regions possible to stay at cozy guesthouses and homestays.
            </p>
          </div>

          <div className="kurgyzstanabouttravel_card">
            <div className="kurgyzstanabouttravel_title">How to get?</div>
            <p className='kurgyzstanabouttravel_suptitle'>
              International <a href="" className='ahref'>Airport Manas</a> in Bishkek and Airport in <a href="" className='ahref'>Osh town</a> receives daily international flights. Open Skies policy officially start working from January 2019. Being on the crossroad Kyrgyzstan has international highway connections with China, Kazakhstan, Uzbekistan, and Tajikistan. Possible to travel by train from Russia, Almaty, and Tashkent.
            </p>
            <div className="kurgyzstanabouttravel_read">
              <a href="" className='ahref'>Read more</a>
            </div>
          </div>
        </div>

        <div className="kurgyzstanabouttravel_block">
          <div className="kurgyzstanabouttravel_card">
            <div className="kurgyzstanabouttravel_title">Geography and climate</div>
            <p className='kurgyzstanabouttravel_suptitle'>
              The Kyrgyz Republic is located in the northeast of Central Asia in the center of the Eurasian continent. Its territory covers an area of ​​187.5 thousand km2. Length from west to east – 900 km, from north to south – 450 km. The Kyrgyz Republic has common borders with the Republic of Kazakhstan, the People’s Republic of China, the Republic of Tajikistan, and the Uzbek Republic.
            </p>
            <div className="kurgyzstanabouttravel_read">
              <a href="" className='ahref'>Read more</a>
            </div>
          </div>
        </div>

        <hr className='kurgyzstanabouttravel_hr' />

        <div className="kurgyzstanabouttravel_btn">
          <button>VIEW ALL TOURS</button>
        </div>
      </div>
    </section>
  )
}
