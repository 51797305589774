import React from 'react';
import Header from '../../Home/Header';
import KyrgyzstanAboutBg from './KyrgyzstanAboutBg';
import KyrgyzstanAboutTravel from './KyrgyzstanAboutTravel';
import RightModalKyrgyzstanAbout from './RightModalKyrgyzstanAbout';
import Footer from '../../Home/Footer';
import '../../../css/kyrgizstan/kyrgyzstan.css'
import BackToTopButton from '../../Home/BackToTopButton';

export default function KyrgyzstanAbout() {
  return (
    <div>
      <Header />
      <KyrgyzstanAboutBg />
      <div className="container displaycontainer">
        <KyrgyzstanAboutTravel />
        <RightModalKyrgyzstanAbout />
      </div>
      <Footer />
      <BackToTopButton />
    </div>
  )
}
