import React from 'react';
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg7 from '../../images/CardImg/card_img_7.jpg';
import Cardimg24 from '../../images/CardImg/card_img_24.jpg';
import Cardimg25 from '../../images/CardImg/card_img_25.jpg';

export default function DatasIniteraryBook() {
    return (
        <section id='books' className='datasiniterarybook'>
            <div className="datasiniterarybookbg">
                <h3 className='booktour_title'>Book tour</h3>
                <div className="booktour_inputs">
                    <div className="booktour_tour">
                        <input type="search" placeholder='Tour' />
                    </div>
                    <div className="booktour_tour">
                        <input type="search" placeholder='Your name' />
                    </div>
                    <div className="booktour_tour">
                        <input type="search" placeholder='Your email' />
                    </div>
                    <div className="booktour_message">
                        <input type="search" placeholder='Your message' />
                    </div>
                    <div className="booktour_btn">
                        <button>Send</button>
                    </div>
                </div>
            </div>

            <h3 className="datas_like_title">You May Also Like</h3>

            <div className="biketours_block">
                <div className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg7} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Adventure-KZ,KG,UZ</h1>
                    <p className='biketours_suptitle'><p class="excerpt"></p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">18 days</div>
                    </div>
                </div>
                <div className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg24} alt="" />
                    </div>

                    <h1 className='biketours_text'>Climbing peak Palatka, 4 650m</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Trekking</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">11 days</div>
                    </div>
                </div>
                <div className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg25} alt="" />
                    </div>

                    <h1 className='biketours_text'>Golden Eagle hunting</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Experience tours</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">14 days</div>
                    </div>
                </div>
            </div>
        </section>
    )
}
