import React from 'react';
import { Link } from 'react-router-dom';
// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
// img
import Datasimg1 from '../../images/DatasImg/datas_img_1.jpg';
import Datasimg2 from '../../images/DatasImg/datas_img_2.png';
import Datasimg3 from '../../images/DatasImg/datas_img_3.jpg';
import Datasimg4 from '../../images/DatasImg/datas_img_4.jpg';
import Datasimg5 from '../../images/DatasImg/datas_img_5.jpg';
import Datasimg6 from '../../images/DatasImg/datas_img_6.jpg';
import Datasimg7 from '../../images/DatasImg/datas_img_7.jpg';
import Datasimg8 from '../../images/DatasImg/datas_img_8.jpg';
import Datasimg9 from '../../images/DatasImg/datas_img_9.jpg';
import Datasimg10 from '../../images/DatasImg/datas_img_10.jpg';


export default function DatasFotos() {
    return (
        <section className='datasfotos'>
            <div className="datas_intereray_button">
                <Link to="/datas" className="datas_intereray_btn_2">
                    <button>ITINERARY</button>
                </Link>

                <Link to="/dataspage2" className="datas_intereray_btn_2">
                    <button>LOCATION</button>
                </Link>

                <div className="datas_intereray_btn_1">
                    <button>PHOTOS</button>
                </div>
            </div>

            <div className="datasfotosbg">
                <div className="datasfotosbg_block">
                    <div className="datasfotosbg_card">
                        <div className="datasfotosbg_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Datasimg1} alt="" />
                            <div className="datasfotosbg_shadow"></div>
                        </div>
                    </div>
                    <div className="datasfotosbg_card">
                        <div className="datasfotosbg_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Datasimg2} alt="" />
                            <div className="datasfotosbg_shadow"></div>
                        </div>
                    </div>
                    <div className="datasfotosbg_card">
                        <div className="datasfotosbg_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Datasimg3} alt="" />
                            <div className="datasfotosbg_shadow"></div>
                        </div>
                    </div>
                    <div className="datasfotosbg_card">
                        <div className="datasfotosbg_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Datasimg4} alt="" />
                            <div className="datasfotosbg_shadow"></div>
                        </div>
                    </div>
                    <div className="datasfotosbg_card">
                        <div className="datasfotosbg_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Datasimg5} alt="" />
                            <div className="datasfotosbg_shadow"></div>
                        </div>
                    </div>
                    <div className="datasfotosbg_card">
                        <div className="datasfotosbg_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Datasimg6} alt="" />
                            <div className="datasfotosbg_shadow"></div>
                        </div>
                    </div>
                    <div className="datasfotosbg_card">
                        <div className="datasfotosbg_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Datasimg7} alt="" />
                            <div className="datasfotosbg_shadow"></div>
                        </div>
                    </div>
                    <div className="datasfotosbg_card">
                        <div className="datasfotosbg_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Datasimg8} alt="" />
                            <div className="datasfotosbg_shadow"></div>
                        </div>
                    </div>
                    <div className="datasfotosbg_card">
                        <div className="datasfotosbg_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Datasimg9} alt="" />
                            <div className="datasfotosbg_shadow"></div>
                        </div>
                    </div>
                    <div className="datasfotosbg_card">
                        <div className="datasfotosbg_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Datasimg10} alt="" />
                            <div className="datasfotosbg_shadow"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
