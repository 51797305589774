import React from 'react';
import { Link } from 'react-router-dom';
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg1 from '../../images/CardImg/card_img_1.jpg';
import Cardimg2 from '../../images/CardImg/card_img_2.jpg';
import Cardimg3 from '../../images/CardImg/card_img_3.jpg';

export default function BikeTours() {
    return (
        <section className="biketours">

            <h1 className="toptravel_title">Top Bike travel deals</h1>

            <div className="toptravelscrol">

                <div className="toptravel_block">
                    <h2 className="toptravel_departng">Departing</h2>
                    <h2 className="toptravel_trip">Trip</h2>
                    <h2 className="toptravel_fotos">Fotos</h2>
                    <h2 className="toptravel_days">Days</h2>
                    <h2 className="toptravel_from">From USD</h2>
                </div>

                <div className="toptravel_bg">

                    <div className="toptravel_card">
                        <div className="toptravel_card_num">02 Feb 2024</div>
                        <div className="toptravel_card_trip">
                            <h1 className="toptravel_card_title">Okavango Experience</h1>
                            <p className="toptravel_card_suptitle">Johannesburg → Victoria Falls</p>
                        </div>
                        <div className="toptravel_card_img">
                            <img src={Cardimg1} alt="" />
                        </div>
                        <div className="toptravel_card_day">10</div>
                        <div className="toptravel_card_price">
                            <span className="toptravel_card_discount">1,500</span>
                            <div className="toptravel_card_cost">$1,299</div>
                        </div>

                        <div className="toptravel_card_view">View trip ›</div>
                    </div>

                    <div className="toptravel_card toptravel_card_border">
                        <div className="toptravel_card_num">02 Feb 2024</div>
                        <div className="toptravel_card_trip">
                            <h1 className="toptravel_card_title">Okavango Experience</h1>
                            <p className="toptravel_card_suptitle">Johannesburg → Victoria Falls</p>
                        </div>
                        <div className="toptravel_card_img">
                            <img src={Cardimg2} alt="" />
                        </div>
                        <div className="toptravel_card_day">10</div>
                        <div className="toptravel_card_price">
                            <span className="toptravel_card_discount">1,500</span>
                            <div className="toptravel_card_cost">$1,299</div>
                        </div>

                        <div className="toptravel_card_view">View trip ›</div>
                    </div>
                </div>
            </div>

            <div className="biketours_block">
                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg1} alt="" />
                    </div>

                    <h1 className='biketours_text'>“Singletrack Expedition: Mountain Biking Marvels in Kyrgyzstan”</h1>
                    <p className='biketours_suptitle'><p class="excerpt">MTB Single-track</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">9 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg2} alt="" />
                    </div>

                    <h1 className='biketours_text'>MTB Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Bike</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">8 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg3} alt="" />
                    </div>

                    <h1 className='biketours_text'>Mountain Bike Tour Pro</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Bike</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">13 days</div>
                    </div>
                </Link>
            </div>

            <div className="bike_text">
                <p className="bike_text_suptitle">
                    Did you know that Kyrgyzstan is the country with the best-preserved nature in Central Asia? There are high peaks up to 7,400 m above sea level and a massive Tien Shan mountain range. What kind of wild animals are there in their vast forests? Did you know that between its secret gorges and on mountain ranges lie nomadic villages and crystal-clear mountain lakes, that life there follows its natural, traditional, calm rhythm, and that people get to know a stranger as a dear guest?
                    <br />
                    This is Kyrgyzstan. Not the ones with massive advertising tempting all-inclusive hotel comforts in resorts. Not the one that leads through the crowded streets of the 21st century. This is a different world.
                    <br />
                    Kyrgyzstan expects you to put a backpack on your back, ride a bike or horse, and explore its unexplored corners.
                    <br />
                    Ancient ruins from the Great Silk Road era, deep lakes and sweeping views of towering mountains, herds of free horses on the slopes, mineral springs, and the culture of the nomadic people – all this can be found in the Kyrgyz countryside.
                    <br />
                    Choose a tour – hiking, cycling, horse riding – and experience adventure with “Kirgyz Tourism” – your travel agency for active recreation!
                    <br />
                    The MTB tour will be very popular in Kyrgyzstan and for your attention, we offer challenging mountain bike trail tours for advanced cyclists.
                </p>
            </div>
        </section>
    )
}
