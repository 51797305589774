import React from 'react';
// img
import Cardimg7 from '../../../images/CardImg/card_img_7.jpg';
import Cardimg10 from '../../../images/CardImg/card_img_10.jpg';
import Cardimg18 from '../../../images/CardImg/card_img_18.jpg';
// react awesome
import { LuClock9 } from "react-icons/lu";
import { Link } from 'react-router-dom';


export default function UzbekistanDestinations() {
    return (
        <section className="uzbekistandestinations">
            <div className="biketours_block">
                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg7} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Adventure-KZ,KG,UZ</h1>
                    <p className='biketours_suptitle'><p class="excerpt"></p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">18 days</div>
                    </div>
                </Link>
                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg10} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road#3. Kazakstan – Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Central Asia</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">12 days</div>
                    </div>
                </Link>
                <Link to="/datas" className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg18} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Tour:Kazakhstan and Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Kyrgyzstan and Kazakhstan</p></p>
                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">9 days</div>
                    </div>
                </Link>
            </div>

            <div className="uzbekistancard">
                <p>Uzbekistan is a country with a great and extensive history. Local people have become accustomed, but for a traveler, it would be amazing to see these amazing cities of the Silk Road – Samarkand, Bukhara, and Khiva. It’s hard to realize, but they were established more than 2500 years ago. All the culture and routine of Uzbekistan are imbued with the spirit of the Golden Age of Islam and other great times of the past. Tours in Uzbekistan provide a great possibility to see the legacy of Central Asia history.</p>
                <p>Besides the strong connection with ancient times, Uzbekistan tourism is popular due to the gorgeous nature and picturesque views of these lands. It’s impressive to see such diverse landscapes, as there are not only green forests and high mountains, but even a desert! A major river, the Amu Darya, the gigantic Kyzylkum Desert, the wonderful Sangardak waterfall, and many other miracles of nature are located in Uzbekistan.</p>
            </div>
        </section>
    )
}
