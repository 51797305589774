import React from 'react';
import Cardimg48 from '../../../images/CardImg/card_img_48.jpg';
import Cardimg49 from '../../../images/CardImg/card_img_49.jpg';
import Cardimg50 from '../../../images/CardImg/card_img_50.jpg';

export default function GalleryTravel() {
    return (
        <section className='gallerytravel'>
            <div className="gallerytravel_bg_block">
                <div className="gallerytravel_card_bg">
                    <div className="gallerytravel_img">
                        <img src={Cardimg48} alt="" />
                    </div>

                    <h1 className='gallerytravel_text'>Winter Kyrgyzstan</h1>
                    <p className="gallerttravel_more">View more ›</p>
                </div>

                <div className="gallerytravel_card_bg">
                    <div className="gallerytravel_img">
                        <img src={Cardimg49} alt="" />
                    </div>

                    <h1 className='gallerytravel_text'>Eagle hunters</h1>
                    <p className="gallerttravel_more">View more ›</p>
                </div>

                <div className="gallerytravel_card_bg">
                    <div className="gallerytravel_img">
                        <img src={Cardimg50} alt="" />
                    </div>

                    <h1 className='gallerytravel_text'>Kyrgyzstan Landscape</h1>
                    <p className="gallerttravel_more">View more ›</p>
                </div>
            </div>
        </section>
    )
}
