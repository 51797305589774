import React from 'react';
import Header from '../../Home/Header';
import EventsBg from './EventsBg';
import EventsTravel from './EventsTravel';
import RightModalEvents from './RigtModalEvents';
import Footer from '../../Home/Footer';
import '../../../css/kyrgizstan/kyrgyzstan.css';
import BackToTopButton from '../../Home/BackToTopButton';

export default function Events() {
    return (
        <div>
            <Header />
            <EventsBg />
            <div className="container displaycontainer">
                <EventsTravel />
                <RightModalEvents />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
