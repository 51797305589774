import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../../css/newswiper.css';
import { Pagination, Navigation } from 'swiper/modules';
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg5 from '../../images/CardImg/card_img_5.jpg';
import Cardimg7 from '../../images/CardImg/card_img_7.jpg';
import Cardimg19 from '../../images/CardImg/card_img_19.jpg';
import Cardimg15 from '../../images/CardImg/card_img_15.jpg';
import Cardimg16 from '../../images/CardImg/card_img_16.jpg';
import Cardimg12 from '../../images/CardImg/card_img_12.jpg';
import Cardimg17 from '../../images/CardImg/card_img_17.jpg';
import Cardimg45 from '../../images/CardImg/card_img_45.jpg';
import Cardimg18 from '../../images/CardImg/card_img_18.jpg';
import Cardimg6 from '../../images/CardImg/card_img_6.jpg';




export default function Mostswiper() {
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 760);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth >= 550);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <Swiper
                slidesPerView={1}
                breakpoints={{

                    760: {
                        slidesPerView: 2,

                    },
                    990: {
                        slidesPerView: 3,
                    }
                }}
                spaceBetween={-20}
                loop={false}
                navigation={isWideScreen}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <Link to="/datas" className="new_card_bg mt-[60px]" style={{border: 'none'}}>
                        <div className="new_img">
                            <img src={Cardimg5} alt="" />
                        </div>

                        <h1 className='new_text'>Children’s Quiz Tour</h1>
                        <p className='new_suptitle'><p class="excerpt"></p></p>

                        <div className="new_hr_day">
                            <hr className='new_hr' />
                            <div className="new_day_num">
                                <div className="new_day"><LuClock9 color="#005271" size="18px" /></div>
                                <div className="new_num">7 days</div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>

                <SwiperSlide>
                    <Link to="/datas" className="new_card_bg mt-[60px]" style={{border: 'none'}}>
                        <div className="new_img">
                            <img src={Cardimg15} alt="" />
                        </div>

                        <h1 className='new_text'>Handicraft tour</h1>
                        <p className='new_suptitle'><p class="excerpt">Milti-active tour</p></p>

                        <div className="new_hr_day">
                            <hr className='new_hr' />
                            <div className="new_day_num">
                                <div className="new_day"><LuClock9 color="#005271" size="18px" /></div>
                                <div className="new_num">9 days</div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>

                <SwiperSlide>
                    <Link to="/datas" className="new_card_bg mt-[60px]" style={{border: 'none'}}>
                        <div className="new_img">
                            <img src={Cardimg16} alt="" />
                        </div>

                        <h1 className='new_text'>Winter adventures in Kyrgyzstan</h1>
                        <p className='new_suptitle'><p class="excerpt">Family tours</p></p>

                        <div className="new_hr_day">
                            <hr className='new_hr' />
                            <div className="new_day_num">
                                <div className="new_day"><LuClock9 color="#005271" size="18px" /></div>
                                <div className="new_num">12 days</div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>

                <SwiperSlide>
                    <Link to="/datas" className="new_card_bg mt-[60px]" style={{border: 'none'}}>
                        <div className="new_img">
                            <img src={Cardimg12} alt="" />
                        </div>

                        <h1 className='new_text'>Trekking to the Lake Kel-Suu</h1>
                        <p className='new_suptitle'><p class="excerpt">Trekking</p></p>

                        <div className="new_hr_day">
                            <hr className='new_hr' />
                            <div className="new_day_num">
                                <div className="new_day"><LuClock9 color="#005271" size="18px" /></div>
                                <div className="new_num">7 days</div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>

                <SwiperSlide>
                    <Link to="/datas" className="new_card_bg mt-[60px]" style={{border: 'none'}}>
                        <div className="new_img">
                            <img src={Cardimg17} alt="" />
                        </div>

                        <h1 className='new_text'>Discovery Kyrgyzstan. Bishkek & Osh.</h1>
                        <p className='new_suptitle'><p class="excerpt"></p></p>

                        <div className="new_hr_day">
                            <hr className='new_hr' />
                            <div className="new_day_num">
                                <div className="new_day"><LuClock9 color="#005271" size="18px" /></div>
                                <div className="new_num">12 days</div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>

                <SwiperSlide>
                    <Link to="/datas" className="new_card_bg mt-[60px]" style={{border: 'none'}}>
                        <div className="new_img">
                            <img src={Cardimg45} alt="" />
                        </div>

                        <h1 className='new_text'>Backcountry ski tour in northern Kyrgyzstan</h1>
                        <p className='new_suptitle'><p class="excerpt">Backcountry ski tour</p></p>

                        <div className="new_hr_day">
                            <hr className='new_hr' />
                            <div className="new_day_num">
                                <div className="new_day"><LuClock9 color="#005271" size="18px" /></div>
                                <div className="new_num">10 days</div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>

                <SwiperSlide>
                    <Link to="/datas" className="new_card_bg mt-[60px]" style={{border: 'none'}}>
                        <div className="new_img">
                            <img src={Cardimg19} alt="" />
                        </div>

                        <h1 className='new_text'>Lake Ala-Kol. 3 days trekking tour.</h1>
                        <p className='new_suptitle'><p class="excerpt">Trekking</p></p>

                        <div className="new_hr_day">
                            <hr className='new_hr' />
                            <div className="new_day_num">
                                <div className="new_day"><LuClock9 color="#005271" size="18px" /></div>
                                <div className="new_num">3 days</div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>

                <SwiperSlide>
                    <Link to="/datas" className="new_card_bg mt-[60px]" style={{border: 'none'}}>
                        <div className="new_img">
                            <img src={Cardimg18} alt="" />
                        </div>

                        <h1 className='new_text'>Lake Ala-Kol. 3 days trekking tour.</h1>
                        <p className='new_suptitle'><p class="excerpt">Trekking</p></p>

                        <div className="new_hr_day">
                            <hr className='new_hr' />
                            <div className="new_day_num">
                                <div className="new_day"><LuClock9 color="#005271" size="18px" /></div>
                                <div className="new_num">3 days</div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>

                <SwiperSlide>
                    <Link to="/datas" className="new_card_bg mt-[60px]" style={{border: 'none'}}>
                        <div className="new_img">
                            <img src={Cardimg6} alt="" />
                            <div className="new_geen">from <br />$150</div>
                        </div>

                        <h1 className='new_text'>Lake Ala-Kol. 3 days trekking tour.</h1>
                        <p className='new_suptitle'><p class="excerpt">Trekking</p></p>

                        <div className="new_hr_day">
                            <hr className='new_hr' />
                            <div className="new_day_num">
                                <div className="new_day"><LuClock9 color="#005271" size="18px" /></div>
                                <div className="new_num">3 days</div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>

                <SwiperSlide>
                    <Link to="/datas" className="new_card_bg mt-[60px]" style={{border: 'none'}}>
                        <div className="new_img">
                            <img src={Cardimg7} alt="" />
                        </div>

                        <h1 className='new_text'>Silk Road Adventure-KZ,KG,UZ</h1>
                        <p className='new_suptitle'><p class="excerpt">Expedition</p></p>

                        <div className="new_hr_day">
                            <hr className='new_hr' />
                            <div className="new_day_num">
                                <div className="new_day"><LuClock9 color="#005271" size="18px" /></div>
                                <div className="new_num">18 days</div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>

              

            </Swiper>
        </>
    )
}
