import React from 'react';
import { Link } from 'react-router-dom';

export default function SustainableBg() {
    return (
        <section className='sustainablebg'>
            <div className="container">
                <div className='blog_hone_text'>
                    <Link className='blog_home' to="/">Home/</Link>
                    <div className="blog_home">Sustainable tourism</div>
                </div>
                <hr />
                <h1 className='blog_title'>Sustainable tourism</h1>
            </div>
        </section>
    )
}
