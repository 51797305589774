import React from 'react';
import { Link } from 'react-router-dom';

export default function TurkmenistanBg() {
    return (
        <section className='turkmenistanbg'>
            <div className="container">
                <div className='kazakstanbg_hone_text'>
                    <Link className='kazakstanbg_home' to="/">Home/</Link>
                    <div className="kazakstanbg_home">Tours/</div>
                    <div className="kazakstanbg_home">Turkmenistan tourism</div>
                </div>
                <hr />
                <h1 className='kazakstanbg_title'>Kyrgyzstan Turkmenistan tourism</h1>
                <p className='kazakstanbg_siptitle'>Create unforgettable memories with Turkmenistan tourism in Kyrgyzstan</p>
            </div>
        </section>
    )
}
