import React from 'react';
import { Link } from 'react-router-dom';


export default function GalleyBg() {
  return (
    <section className="gaellerybg">
      <div className="container">
        <div className='blog_hone_text'>
          <Link className='blog_home' to="/">Home/</Link>
          <div className="blog_home">Galleries</div>
        </div>
        <hr />
        <h1 className='blog_title'>Galleries - Archive</h1>
      </div>
    </section>
  )
}
