import React from 'react'
import '../../css/style.css'
import Header from './Header'
import Find from './Find'
import Land from './Land'
import New from './New'
import Type from './Type'
import Most from './Most'
import Deals from './Deals'
import Destination from './Destination'
import Latest from './Latest'
import With from './With'
import Footer from './Footer'
import MainSwiper from './MainSwiper'
import BackToTopButton from './BackToTopButton'

export default function Home() {
    return (
        <div className='Home'>
            <Header />
            <MainSwiper />
            <Find />
            <Land />
            <New />
            <Type />
            <Most />
            <Deals />
            <Destination />
            <Latest />
            <With />
            <Footer />
            <BackToTopButton />
        </div>
    )
}
