import React from 'react';
import Header from '../../Home/Header';
import PermitsBg from './PermitsBg';
import PermitsTravel from './PermitsTravel';
import RigtModalPermits from './RigtModalPermits';
import Footer from '../../Home/Footer';
import '../../../css/kyrgizstan/kyrgyzstan.css';
import BackToTopButton from '../../Home/BackToTopButton';

export default function Permits() {
    return (
        <div>
            <Header />
            <PermitsBg />
            <div className="container displaycontainer">
                <PermitsTravel />
                <RigtModalPermits />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
