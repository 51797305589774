import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// react icons
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
// img
import Datas1 from '../../images/datas_1.jpg';
import Foto1 from '../../images/Acardionfotos/acardion_foto_1.jpg';
import Foto2 from '../../images/Acardionfotos/acardion_foto_2.jpg';
import Foto3 from '../../images/Acardionfotos/acardion_foto_3.jpg';
import Foto4 from '../../images/Acardionfotos/acardion_foto_4.jpg';
import Foto5 from '../../images/Acardionfotos/acardion_foto_5.jpg';
import Foto6 from '../../images/Acardionfotos/acardion_foto_6.jpg';
import Foto7 from '../../images/Acardionfotos/acardion_foto_7.jpg';
import Foto8 from '../../images/Acardionfotos/acardion_foto_8.jpg';
// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
// img foto
import Datasimg1 from '../../images/DatasImg/datas_img_1.jpg';
import Datasimg2 from '../../images/DatasImg/datas_img_2.png';
import Datasimg3 from '../../images/DatasImg/datas_img_3.jpg';
import Datasimg4 from '../../images/DatasImg/datas_img_4.jpg';
import Datasimg5 from '../../images/DatasImg/datas_img_5.jpg';
import Datasimg6 from '../../images/DatasImg/datas_img_6.jpg';
import Datasimg7 from '../../images/DatasImg/datas_img_7.jpg';
import Datasimg8 from '../../images/DatasImg/datas_img_8.jpg';
import Datasimg9 from '../../images/DatasImg/datas_img_9.jpg';
import Datasimg10 from '../../images/DatasImg/datas_img_10.jpg';

export default function DatasIniterary() {

    const [isExpandedFoto, setIsExpandedFoto] = useState(false);

    const toggleAccordionFoto = () => {
        setIsExpandedFoto(!isExpandedFoto);
    };

    const accordionData = [
        {
            title: 'Day 1',
            title_one: 'Bishkek, Ala-Archa National Park',
            suptitle_1: 'Today is an enriching acclimatizing day as we explore Bishkek, Ala-Archa Park, and Chong-Kemin. After a delightful stroll in Chaar-Archa National Park and a visit to the iconic Burana Tower, we’ll transfer to Chong-Kemin Gorge. Upon reaching the Boo-Jetpes guesthouse, enjoy a warm welcome, dinner, and settle into your accommodation.',
            suptitle_2: 'As an added bonus, enthusiasts can assemble their bikes and prepare for the exciting biking adventures ahead. It’s a perfect blend of cultural exploration, natural wonders, and gearing up for the thrilling biking experiences that await on the morrow.',
        },
        {
            title: 'Day 2',
            title_one: 'Chong-Kemin to Kochkor',
            suptitle_1: 'Wake up to a hearty breakfast and gear up for an exhilarating mountain biking adventure. Embark on an MTB journey along scenic single tracks, covering approximately 15 km to reach the stunning Boom Gorge. As you conquer the trails, enjoy the company of horseback riders escorting you, adding a unique touch to your biking experience.',
            suptitle_2: 'At the end of the gorge, your transport awaits, ready to whisk you away. Indulge in a picnic lunch surrounded by the untamed wilderness. In the afternoon, transition from the rugged beauty of Boom to the charming town of Kochkor. By late afternoon, settle into your guesthouse, where a hot shower and a delicious dinner await. It’s a day packed with biking thrills, natural splendor, and the warm comforts of Kochkor.',
            img1: <img src={Foto1} alt="" />
        },
        {
            title: 'Day 3',
            title_one: 'Kochkor, Kol-Ukok, Kochkor',
            suptitle_1: 'Start your day with a satisfying breakfast before embarking on an unforgettable day trip to Kol-Ukok. Board comfortable 4×4 cars for a scenic transfer to the mountainous terrain of Kol-Ukok, where a shepherd’s yurt camp awaits.',
            suptitle_2: 'Gear up for an exhilarating cycling adventure up to the mesmerizing Kol-Ukok mountain lake via scenic single tracks. Immerse yourself in the natural beauty of the surroundings and explore the area at your own pace. Return to the yurt camp for a late lunch, enjoying the warm hospitality of the shepherd’s abode.',
            suptitle_3: 'In the afternoon, brace yourself for a thrilling downhill cycling experience, descending from the mountain to the quaint village of Kochkor. As the day winds down, find comfort in the guesthouse, where a delicious dinner awaits. It’s a day filled with breathtaking landscapes, mountain biking escapades, and the authentic charm of Kochkor.',
            img2: <img src={Foto2} alt="" />
        },
        {
            title: 'Day 4',
            title_one: 'Kochkor to Songkol',
            suptitle_1: 'Rise and shine with a 7:00 am breakfast, gearing up for an early adventure to the stunning Songkol Valley. Your journey kicks off with a scenic car ride to the Kalmak Ashu pass, where the excitement begins with an exhilarating single-track descent into the picturesque Songkol Valley.',
            suptitle_2: 'Upon reaching the lake, follow the road to the welcoming yurt camp Jaman-Echki. Enjoy a delicious lunch and some well-deserved relaxation before the real biking challenge begins. Post-lunch, brace yourself for a panorama single-track biking adventure, navigating the breathtaking terrain.',
            suptitle_3: 'As the day progresses, your transport awaits to take you to the top of the Tuz Ashuu pass. From here, embark on an MTB journey, tracing the shepherd’s path along the Song-Kol Mountain ridge. Descend through the enchanting Songkol Valley, making your way back to the yurt camp Jaman-Echki.',
            suptitle_4: 'Conclude your day with a delightful dinner and a peaceful night’s sleep in the cozy yurts, surrounded by the serene beauty of Songkol.',
            img3: <img src={Foto3} alt="" />,
            suptitle_5: 'The second part of the single-track biking afternoon. Depending on the weather conditions we will decide on the itinerary.',
            img4: <img src={Foto4} alt="" />,
        },
        {
            title: 'Day 5',
            title_one: 'Explore the Tranquil Charms of Songkol',
            suptitle_1: 'Embark on a leisurely biking adventure around the pristine shores of Lake Songkol. Delve into the heart of the valley, traversing from the northern lakeshore to the serene landscapes of the southern lakeshore. Today’s journey offers a unique opportunity to witness the nomadic horse game Ulak-Tartysh and connect with local nomad families in their traditional yurts.',
            suptitle_2: 'As you pedal through the breathtaking scenery, take in the nomadic way of life and experience the warm hospitality of the locals. By afternoon, arrive at the welcoming yurt camp on the southern part of Lake Songkol, where a delicious dinner and rejuvenating hot steam shower facilities await.',
            suptitle_3: 'Dine under the vast open sky, surrounded by the tranquil beauty of Songkol, and retreat to the cozy yurts for a peaceful night’s sleep. It’s a day of cultural immersion, scenic exploration, and the simple pleasures of nomadic life by the shimmering waters of Songkol.',
            img5: <img src={Foto5} alt="" />
        },
        {
            title: 'Day 6',
            title_one: 'Songkol to Kochkor',
            suptitle_1: 'Start your day with a thrilling bike tour directly from the yurt camp, descending along the winding 32-serpentine pass. Traverse a mix of dirt and gravel mountain roads, cycling through picturesque landscapes. Enjoy the challenge and adventure of the journey, with the ultimate goal of reaching the highway Naryn-Kochkor.',
            suptitle_2: 'Upon completing the cycling stretch, bid adieu to your bikes and transition to a comfortable transfer back to Kochkor. Arrive at the guesthouse, where a warm dinner and cozy accommodations await, providing the perfect conclusion to your Songkol biking expedition. Reflect on the incredible landscapes and experiences as you settle in for a well-deserved rest in Kochkor.',
            img6: <img src={Foto6} alt="" />
        },
        {
            title: 'Day 7',
            title_one: 'Kochkor to Bokonbaevo',
            suptitle_1: 'Fuel up with breakfast before embarking on a transfer to Semiz-Bel, marking the starting point for an exciting MTB adventure into the Issyk-Kul region. Enjoy a picnic lunch in the wild, surrounded by nature’s beauty.',
            suptitle_2: 'Cycle your way through the scenic landscape until you reach the village of Tuura-Suu. From here, transition to a comfortable car ride to Bokonbaevo village, where a welcoming guesthouse awaits your arrival. Settle in and unwind as you reflect on the day’s cycling exploits. It’s a journey filled with picturesque routes and the promise of tranquil accommodations in Bokonbaevo.”',
            img7: <img src={Foto7} alt="" />
        },
        {
            title: 'Day 8',
            title_one: 'Bokonbaevo to Tosor and Back',
            suptitle_1: 'Embark on a day filled with cultural encounters and biking thrills. Begin your journey with a transfer to Boz-Salkyn Gorge, where a 30km biking adventure awaits along the mountainous dirt roads leading to Tosor. Along the way, pause to witness the remarkable tradition of Golden Eagle hunting. Take an hour to enjoy an eagle hunting show and learn about the fascinating world of Kyrgyz traditional hunters.',
            suptitle_2: 'As you descend to the lakeshore, cap off your biking experience with a refreshing swim at a secluded beach. In the afternoon, journey back to Bokonbaevo, making a pitstop at the enchanting Skazka canyons for a brief but captivating sightseeing session.',
            suptitle_2: 'Arrive at the guesthouse for a warm dinner and a special treat – a folklore show by a local music family band. Immerse yourself in the rich cultural tapestry of Kyrgyzstan as you settle in for the night, reflecting on a day filled with adventure and traditional charm.',
            img8: <img src={Foto8} alt="" />
        },
        {
            title: 'Day 9',
            title_one: 'Bokonbaevo to Bishkek',
            suptitle_1: 'Start your day with breakfast, followed by a transfer to the vibrant city of Bishkek. Arrive in Bishkek just in time for a late lunch, setting the stage for an afternoon exploration of the bustling Osh market.',
            suptitle_2: 'After immersing yourself in the local sights and sounds, make your way to the hotel for a comfortable accommodation. As the day winds down, join us for an unforgettable farewell dinner, reflecting on the incredible memories and experiences from your Kyrgyzstan adventure. It’s a fitting end to a journey filled with cultural discoveries, natural wonders, and the warm hospitality of this captivating Central Asian destination.”',
        },

    ];

    const [activeIndexes, setActiveIndexes] = useState([]);
    const [isAllExpanded, setIsAllExpanded] = useState(false);

    const toggleAccordion = (index) => {
        setActiveIndexes((prevIndexes) =>
            prevIndexes.includes(index)
                ? prevIndexes.filter((i) => i !== index)
                : [...prevIndexes, index]
        );
    };

    const toggleAllAccordions = () => {
        setIsAllExpanded(!isAllExpanded);
        setActiveIndexes((prevIndexes) =>
            !isAllExpanded ? accordionData.map((_, index) => index) : []
        );
    };


    return (
        <section className='datas_intereray'>
            <div className="datas_intereray_button">
                <div className="datas_intereray_btn_1">
                    <button>ITINERARY</button>
                </div>

                <Link to="/dataspage2" className="datas_intereray_btn_2">
                    <button>LOCATION</button>
                </Link>

                <Link to="/dataspage3" className="datas_intereray_btn_2">
                    <button>PHOTOS</button>
                </Link>
            </div>

            <div className="datas_intereray_bg">
                <div className="datas_intereray_text">
                    <p className="datas_intereray_paragraph">Let’s go on a mountain biking expedition that unveils the hidden gems of Kyrgyzstan’s landscapes, where ancient shepherd trails have become the canvas for our unique singletrack adventures. Discover the thrill of riding paths once tread only by nomadic shepherds, transforming these historic routes into adrenaline-pumping trails for avid mountain bikers.</p>
                    <p className="datas_intereray_paragraph"><span>Trailblazing Heritage: </span>Experience the allure of singletrack biking on trails steeped in history. These paths, once traversed by shepherds leading their flocks through the mountains, now serve as a testament to Kyrgyzstan’s rich nomadic heritage. Ride through landscapes that echo with the stories of generations past, connecting with the cultural tapestry of this captivating region.</p>
                    <p className="datas_intereray_paragraph"><span>Shepherd’s Secrets: </span>As you navigate these ancient routes, witness the untouched beauty of the Kyrgyz mountains. Marvel at the same panoramic views that captivated shepherds for centuries, each turn revealing a new vista of snow-capped peaks, alpine meadows, and pristine lakes. This is a journey into the heart of a living landscape, where the essence of Kyrgyzstan unfolds with every pedal stroke.</p>
                    <p className="datas_intereray_paragraph"><span>Technical Marvels:</span> Challenge your biking skills on trails that wind through varying terrain, from rocky descents to narrow ridgelines. The singletrack paths offer a technical marvel for riders, demanding both finesse and courage as you navigate the twists and turns that define these unique routes. Each ride is an opportunity to conquer the untamed beauty of Kyrgyzstan.</p>
                    <p className="datas_intereray_paragraph"><span>Nomadic Encounters:</span>Immerse yourself in the living traditions of Kyrgyzstan as you encounter modern-day nomads along the way. Engage with local shepherds, share a cup of traditional tea, and gain insights into their nomadic lifestyle. It’s a cultural exchange that adds depth to your biking adventure, bridging the gap between past and present.</p>
                    <p className="datas_intereray_paragraph"><span>Sustainable Exploration:</span> Our commitment to preserving the natural beauty of Kyrgyzstan is reflected in our sustainable approach to adventure. Ride responsibly through this pristine wilderness, leaving minimal impact on the environment and ensuring these historic trails remain a treasure for generations to come.</p>
                    <p className="datas_intereray_paragraph"><span>Elevate Your Experience:</span> Elevate your mountain biking experience by choosing a journey that not only challenges your skills but also connects you to the soul of Kyrgyzstan. Join us on a singletrack adventure where the echoes of shepherds’ footsteps harmonize with the hum of your bike tires, creating a symphony of exploration in the heart of Central Asia.</p>
                    <p className="datas_intereray_paragraph"><span>Duration:</span> 9 days</p>
                    <p className="datas_intereray_paragraph"><span>Season:</span> July, August</p>
                    <p className="datas_intereray_paragraph"><span>Tour:</span> MTB Adventure</p>
                    <p className="datas_intereray_paragraph"><span>Accommodations:</span> guesthouses, yurt camp</p>
                    <p className="datas_intereray_paragraph"><span>Group size: 6-8 pax</span></p>
                    <p className="datas_intereray_paragraph"><span>Requirements:</span> MTB bike, helmets, spare parts, warm clothes, rain jackets,</p>
                </div>

                <div className='datas_img'>
                    <img src={Datas1} alt="" />
                </div>
                <div className="datas_icons">
                    <Link to="https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3Dhttps%253A%252F%252Fkyrgyzstan-tourism.com%252Ftours%252Fsingletrack-expedition-mountain-biking-marvels-in-kyrgyzstan%252F&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=ru_RU"><FaFacebookF className='datas_react_icons' /></Link>
                    <Link to="https://twitter.com/intent/tweet?via=NativeKyrgyzst1&text=%22Singletrack%20Expedition%3A%20Mountain%20Biking%20Marvels%20in%20Kyrgyzstan%22&url=https%3A%2F%2Fkyrgyzstan-tourism.com%2Ftours%2Fsingletrack-expedition-mountain-biking-marvels-in-kyrgyzstan%2F"><FaTwitter className='datas_react_icons' /></Link>
                    <Link to="https://www.instagram.com/kyrgyzstan_tourism/"><FaInstagram className='datas_react_icons' /></Link>
                    <Link to="https://kyrgyzstan-tourism.com/tours/singletrack-expedition-mountain-biking-marvels-in-kyrgyzstan/"><FaPinterestP className='datas_react_icons' /></Link>
                    <Link to="https://api.whatsapp.com/send?text=%22Singletrack%20Expedition%3A%20Mountain%20Biking%20Marvels%20in%20Kyrgyzstan%22%20https%3A%2F%2Fkyrgyzstan-tourism.com%2Ftours%2Fsingletrack-expedition-mountain-biking-marvels-in-kyrgyzstan%2F "><FaWhatsapp className='datas_react_icons' /></Link>
                </div>

                <center>
                    <a href="#books" className="datas_book_btn">
                        <button>BOOK</button>
                    </a>
                </center>

                <h1 className="datas_itinerary">ITINERARY</h1>

                <div className="datas_acar_btn">
                    <button onClick={toggleAllAccordions}>
                        {isAllExpanded ? 'Collapse all' : 'Expand all'}
                    </button>
                </div>


                {accordionData.map((item, index) => (
                    <div className='acardion' key={index}>
                        <div
                            onClick={() => toggleAccordion(index)}
                            className='datas_acardion_title'
                        >
                            <strong className='datas_acardion_title_arrow'>
                                <a href='' className='datas_acardion_title'>{item.title}</a>
                                <div className='datas_acardion_arrow'><IoIosArrowDown className='text-[30px]' /></div>
                            </strong>
                        </div>

                        <div style={{ display: activeIndexes.includes(index) ? 'block' : 'none' }}>
                            <p className='datas_acardion_content'>
                                <h2 className="datas_acardion_title_one">{item.title_one}</h2>
                                <p className="datas_acardion_suptitle">{item.suptitle_1}</p>
                                <p className="datas_acardion_suptitle">{item.suptitle_2}</p>
                                <p className="datas_acardion_suptitle">{item.suptitle_3}</p>
                                <p className="datas_acardion_suptitle">{item.suptitle_4}</p>
                                {item.img1}
                                {item.img2}
                                {item.img3}
                                <p className="datas_acardion_suptitle">{item.suptitle_5}</p>
                                {item.img4}
                                {item.img5}
                                {item.img6}
                                {item.img7}
                                {item.img8}
                            </p>

                        </div>
                    </div>
                ))}
                <div className="datas_intenery_globe">
                    <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1mtkEYoS0xRitR39iBADMDkhsQ-qFl08&ehbc=2E312F&noprof=1" width="640" height="480"></iframe>
                </div>

                <div className="datas_goto_acardion">
                    <div onClick={toggleAccordionFoto} className="accordion_foto">
                        <h3 className='accordion_foto_title'>Photos</h3>
                        <div className='datas_acardion_arrow'><IoIosArrowDown className='text-[30px] text-[#005271]' /></div>
                    </div>
                    {isExpandedFoto && (
                        <div className="accordion_foto_content">

                            <div className="datasfotosbg_block_foto">
                                <div className="datasfotosbg_card">
                                    <div className="datasfotosbg_img_foto">
                                        <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                                        <img src={Datasimg1} alt="" />
                                        <div className="datasfotosbg_shadow"></div>
                                    </div>
                                </div>
                                <div className="datasfotosbg_card">
                                    <div className="datasfotosbg_img_foto">
                                        <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                                        <img src={Datasimg2} alt="" />
                                        <div className="datasfotosbg_shadow"></div>
                                    </div>
                                </div>
                                <div className="datasfotosbg_card">
                                    <div className="datasfotosbg_img_foto">
                                        <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                                        <img src={Datasimg3} alt="" />
                                        <div className="datasfotosbg_shadow"></div>
                                    </div>
                                </div>
                                <div className="datasfotosbg_card">
                                    <div className="datasfotosbg_img_foto">
                                        <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                                        <img src={Datasimg4} alt="" />
                                        <div className="datasfotosbg_shadow"></div>
                                    </div>
                                </div>
                                <div className="datasfotosbg_card">
                                    <div className="datasfotosbg_img_foto">
                                        <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                                        <img src={Datasimg5} alt="" />
                                        <div className="datasfotosbg_shadow"></div>
                                    </div>
                                </div>
                                <div className="datasfotosbg_card">
                                    <div className="datasfotosbg_img_foto">
                                        <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                                        <img src={Datasimg6} alt="" />
                                        <div className="datasfotosbg_shadow"></div>
                                    </div>
                                </div>
                                <div className="datasfotosbg_card">
                                    <div className="datasfotosbg_img_foto">
                                        <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                                        <img src={Datasimg7} alt="" />
                                        <div className="datasfotosbg_shadow"></div>
                                    </div>
                                </div>
                                <div className="datasfotosbg_card">
                                    <div className="datasfotosbg_img_foto">
                                        <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                                        <img src={Datasimg8} alt="" />
                                        <div className="datasfotosbg_shadow"></div>
                                    </div>
                                </div>
                                <div className="datasfotosbg_card">
                                    <div className="datasfotosbg_img_foto">
                                        <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                                        <img src={Datasimg9} alt="" />
                                        <div className="datasfotosbg_shadow"></div>
                                    </div>
                                </div>
                                <div className="datasfotosbg_card">
                                    <div className="datasfotosbg_img_foto">
                                        <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                                        <img src={Datasimg10} alt="" />
                                        <div className="datasfotosbg_shadow"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}
