import React from 'react'
import RightModal from '../RightModal';
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg32 from '../../images/CardImg/card_img_32.jpg';
import Cardimg6 from '../../images/CardImg/card_img_6.jpg';
import Cardimg22 from '../../images/CardImg/card_img_22.jpg';


export default function RightModalMulti() {
    return (
        <section className="rightmodalmulti">
            <RightModal />

            <h4 className="contact_title">Most popular tours</h4>

            <div className="contact_bg_block">
                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg32} alt="" />
                    </div>

                    <h1 className='contact_text'>Lake Kol-Ukok -nomad’s life</h1>
                    <p className='contact_suptitle'><p class="excerpt">Horseback tour</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">2 days</div>
                    </div>
                </div>


                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg6} alt="" />
                        <div className="deals_geen">from <br /> $150</div>
                    </div>

                    <h1 className='contact_text'>Pamir Highway</h1>
                    <p className='contact_suptitle'><p class="excerpt">Pamir</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">2 days</div>
                    </div>
                </div>

                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg22} alt="" />
                    </div>

                    <h1 className='contact_text'>Family traveling in Kyrgyzstan.</h1>
                    <p className='contact_suptitle'><p class="excerpt">Family tours</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">10 days</div>
                    </div>
                </div>
            </div>
        </section>
    )
}
