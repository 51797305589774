import React from 'react';
import Header from '../../Home/Header';
import TurkmenistanBg from './TurkmenistanBg';
import TurkmenistanDestinations from './TurkmenistanDestinations';
import RightMdoalTurkmenistan from './RightMdoalTurkmenistan';
import Footer from '../../Home/Footer';
import '../../../css/destinations/destinations.css';
import BackToTopButton from '../../Home/BackToTopButton';

export default function Turkmenistan() {
    return (
        <div>
            <Header />
            <TurkmenistanBg />
            <div className="container displaycontainer">
                <TurkmenistanDestinations />
                <RightMdoalTurkmenistan />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
