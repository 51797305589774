import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IoLogoWhatsapp } from "react-icons/io5";
// modal
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
// icons
import { IoIosClose } from "react-icons/io";
// img
import ModalAvtar1 from '../../images/Modal_img/modal_avatar_1.jpg';
import ModalAvtar2 from '../../images/Modal_img/modal_avatar_2.png';
import ModalAvtar3 from '../../images/Modal_img/modal_avatar_3.jpg';
import ModalAvtar4 from '../../images/Modal_img/modal_avatar_4.jpg';
import Watsap from '../../images/Modal_img/Watsap_iocn.png'
import '../../css/modal.css'


const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // modal

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {isVisible && (
        <button className="back_to_top" onClick={scrollToTop}>
          <ExpandLessIcon />
        </button>
      )}
      {isVisible && (

        <button onClick={handleOpen} className='back_to_top_1'><IoLogoWhatsapp className='back_to_top_icon' />How can I help you?</button>
      )}

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="modalbox" >
          <div className="modalbox_header">
            <div><IoIosClose onClick={handleClose} className='modalClose' /></div>
            <h1 className='modal_title'>Hello!</h1>
            <p className="modal_suptitle">Click one of our contacts below to chat on WhatsApp</p>
          </div>

          <div className="modal_card">
            <a href='https://wa.me/996554111262' className="modal_avbatar_text">
              <div className="modal_avatar">
                <img src={ModalAvtar1} alt="" />
                <div className="modal_watsap"><img src={Watsap} alt="" /></div>
              </div>

              <div className="modal_text">
                <div className="modal_speaking">Tour manager (Eng.speaking)</div>
                <h1 className="modal_name">Aisha Mambetalieva</h1>
                <p className="modal_available">Available from 8:00 to 19:00</p>
              </div>
            </a>

            <hr />

            <a href='https://wa.me/996554111262' className="modal_avbatar_text">
              <div className="modal_avatar">
                <img src={ModalAvtar2} alt="" />
                <div className="modal_watsap"><img src={Watsap} alt="" /></div>
              </div>

              <div className="modal_text">
                <div className="modal_speaking">Tourmanager (Deutschsprachig)</div>
                <h1 className="modal_name">Timur Kabanbaev</h1>
                <p className="modal_available">Available from 8:00 to 19:00</p>
              </div>
            </a>

            <hr />

            <a href='https://wa.me/996554111262' className="modal_avbatar_text">
              <div className="modal_avatar">
                <img src={ModalAvtar3} alt="" />
                <div className="modal_watsap"><img src={Watsap} alt="" /></div>
              </div>
              <div className="modal_text">
                <div className="modal_speaking">Tour Guide (Eng.speaking)</div>
                <h1 className="modal_name">Ulan Umetov</h1>
                <p className="modal_available">Available from 8:00 to 19:00</p>
              </div>
            </a>

            <hr />

            <a href='https://wa.me/996554111262' className="modal_avbatar_text">
              <div className="modal_avatar">
                <img src={ModalAvtar4} alt="" />
                <div className="modal_watsap"><img src={Watsap} alt="" /></div>
              </div>

              <div className="modal_text">
                <div className="modal_speaking">Türkçe konuşan</div>
                <h1 className="modal_name">Aizhamal Alikenova</h1>
                <p className="modal_available">Available from 8:00 to 19:00</p>
              </div>
            </a>
          </div>

          <div className="modal_footer">Working hours: 08:00 am -07:00 pm (Bishkek time)</div>
        </Box>
      </Modal>

    </div>
  );
};

export default BackToTopButton;
