import React from 'react';
import RightModal from '../RightModal';
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg18 from '../../images/CardImg/card_img_18.jpg';
import Cardimg35 from '../../images/CardImg/card_img_35.jpg';
import Cardimg5 from '../../images/CardImg/card_img_5.jpg';

export default function RightModalPhoto() {
    return (
        <section className="rightmodalphoto">
            <RightModal />

            <h4 className="contact_title">Most popular tours</h4>

            <div className="contact_bg_block">
                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg18} alt="" />
                    </div>

                    <h1 className='contact_text'>Silk Road Tour: Kazakhstan and Kyrgyzstan</h1>
                    <p className='contact_suptitle'><p class="excerpt">Kyrgyzstan and Kazakhstan</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">9 days</div>
                    </div>
                </div>

                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg5} alt="" />
                    </div>

                    <h1 className='contact_text'>Children’s Quiz Tour</h1>
                    <p className='contact_suptitle'><p class="excerpt"></p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">7 days</div>
                    </div>
                </div>

                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg35} alt="" />
                    </div>

                    <h1 className='contact_text'>Lake Ala-Kol. 3 days trekking tour.</h1>
                    <p className='contact_suptitle'><p class="excerpt">Trekking</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="contact_num">3 days</div>
                    </div>
                </div>

            </div>
        </section>
    )
}
