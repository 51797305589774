import React from 'react';
// react awesome
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { TfiYoutube } from "react-icons/tfi";
import { FaSquareTwitter } from "react-icons/fa6";
import { LiaTripadvisor } from "react-icons/lia";
import { FaPinterest } from "react-icons/fa";
import { Link } from 'react-router-dom';

export default function HeaderTwo() {
    return (
        <header>
            <div className="container">
                <div className="header_left_right">
                    <div className="header_left"><Link to="tel:+996 706 363738">+996 706 363738</Link></div>

                    <div className='header_right'>
                        <Link to="https://www.facebook.com/kgTourism/"><FaFacebookF className="header_icon_1" /></Link>
                        <Link to="https://www.instagram.com/kyrgyztourism_/"><FaInstagram className="header_icon_1" /></Link>
                        <Link to="https://www.youtube.com/channel/UCUpfM9AjbXgLe_IGKSDtqRA"><TfiYoutube className="header_icon_1" /></Link>
                        <Link to="https://twitter.com/NativeKyrgyzst1"><FaSquareTwitter className="header_icon_1" /></Link>
                        <Link to="https://www.tripadvisor.ru/Attraction_Review-g293948-d17433303-Reviews-Kyrgyz_Tourism-Bishkek.html"><LiaTripadvisor className="header_icon_1" /></Link>
                        <Link to="https://www.pinterest.com/myrza79/"><FaPinterest className="header_icon_1" /></Link>
                        <div className="header_icon_socila"></div>
                        <div className="headertocome">Войти</div>
                    </div>
                </div>

                <hr />

                <div className="headertwo_left_right">
                    <Link to="/" className="logo"><img src="https://kyrgyzstan-tourism.com/wp-content/themes/native/img/logo_white.png" alt="" /></Link>
                    <div className="headertwo_text">КЫРГЫЗ ТУРИЗМ ДОЛБООРЛОРУУ<br /> ПРОЕКТЫ  КЫРГЫЗ ТУРИЗМА</div>
                </div>
            </div>
        </header>
    )
}
