import React from 'react';
import Header from '../Home/Header';
import KgMainBg from '../KgMain/KgMainBg';
import KgMainTours from './KgMainTours';
import RightMiodalKgMain from './RightMiodalKgMain';
import Footer from '../Home/Footer'
import BackToTopButton from '../Home/BackToTopButton'

export default function KgMain() {
  return (
    <div>
      <Header />
      <KgMainBg />
      <div className="container displaycontainer">
        <KgMainTours />
        <RightMiodalKgMain />
      </div>
      <Footer />
      <BackToTopButton />
    </div>
  )
}
