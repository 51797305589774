import React from 'react';
import Header from '../../Home/Header';
import KazakstanBg from './KazakstanBg';
import KazakstanDestinations from './KazakstanDestinations';
import RightModalKazaksatn from './RightModalKazaksatn';
import '../../../css/destinations/destinations.css';
import Footer from '../../Home/Footer';
import BackToTopButton from '../../Home/BackToTopButton';

export default function Kazakstan() {
  return (
    <div>
      <Header />
      <KazakstanBg />
      <div className="container displaycontainer">
        <KazakstanDestinations />
        <RightModalKazaksatn />
      </div>
      <Footer />
      <BackToTopButton />
    </div>
  )
}
