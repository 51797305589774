import React from 'react';
import { Link } from 'react-router-dom';

export default function DataBg() {
    return (
        <section className="data_bg">
            <div className="container">
                <div className="container">
                    <div className='blog_hone_text'>
                        <Link className='blog_home' to="/">Home/</Link>
                        <div className="blog_home">Tours/</div>
                        <div className="blog_home">“Singletrack Expedition: Mountain Biking Marvels in Kyrgyzstan”</div>
                    </div>
                    <hr />
                    <h1 className='data_bg_title'>“Singletrack Expedition: Mountain Biking Marvels in Kyrgyzstan”</h1>
                </div>
            </div>
        </section>
    )
}
