import React from 'react';
import RightModal from '../../RightModal';
import '../../../css/rightmodal.css';
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg4 from '../../../images/CardImg/card_img_19.jpg';
import Cardimg17 from '../../../images/CardImg/card_img_11.jpg';
import Cardimg8 from '../../../images/CardImg/card_img_13.jpg';


export default function RightModalContactus() {
    return (
        <section className='rightmodalabout'>
            <RightModal />

            <h4 className="contact_title">Most popular tours</h4>

            <div className="contact_bg_block">
                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg4} alt="" />
                    </div>

                    <h1 className='contact_text'>Family traveling in Kyrgyzstan.</h1>
                    <p className='contact_suptitle'><p class="excerpt">Family tours</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="contact_num">9 days</div>
                    </div>
                </div>

                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg17} alt="" />
                    </div>

                    <h1 className='contact_text'>Discovery Kyrgyzstan. Bishkek & Osh.</h1>
                    <p className='contact_suptitle'><p class="excerpt"></p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="contact_num">12 days</div>
                    </div>
                </div>

                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg8} alt="" />
                    </div>

                    <h1 className='contact_text'>Jailoo Hopping to the Lake Song-Kul for 9 days</h1>
                    <p className='contact_suptitle'><p class="excerpt">Trekking</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="contact_num">9 days</div>
                    </div>
                </div>
            </div>
        </section>
    )
}
