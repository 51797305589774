import React from 'react';
import Header from '../../Home/Header';
import TajikistanBg from './TajikistanBg';
import TajikistanDestinations from './TajikistanDestinations';
import RightModalTajikistan from './RightModalTajikistan';
import Footer from '../../Home/Footer';
import '../../../css/destinations/destinations.css';
import BackToTopButton from '../../Home/BackToTopButton';

export default function Tajikistan() {
    return (
        <div>
            <Header />
            <TajikistanBg />
            <div className="container displaycontainer">
                <TajikistanDestinations />
                <RightModalTajikistan />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
