import React from 'react'
import { Link } from 'react-router-dom'

export default function MultiBg() {
    return (
        <section className="multibg">
            <div className="container">
                <div className='blog_hone_text'>
                    <Link className='blog_home' to="/">Home/</Link>
                    <div className="blog_home">Tours/</div>
                    <div className="blog_home">Multi-active tours</div>
                </div>
                <hr />
                <h1 className='blog_title'>Kyrgyzstan Multi-active tours</h1>
                <p className='bike_siptitle'>Create unforgettable memories with Multi-active tours in Kyrgyzstan</p>
            </div>
        </section>
    )
}
