import React from 'react';
import Header from '../../Home/Header';
import InpirationBg from './InpirationBg';
import InspirationTravel from './InspirationTravel';
import RightModalInspiration from './RightModalInspiration';
import Footer from '../../Home/Footer';
import '../../../css/kyrgizstan/kyrgyzstan.css';
import BackToTopButton from '../../Home/BackToTopButton';

export default function Inspiration() {
    return (
        <div>
            <Header />
            <InpirationBg />
            <div className="container displaycontainer">
                <InspirationTravel />
                <RightModalInspiration />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
