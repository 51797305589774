import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FaSearch } from "react-icons/fa";
import '../../css/search.css'



export default function Search() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div>
            <FaSearch className="header_icon_2" onClick={handleOpen} />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="search_box">
                    <div className="search_box_input">
                        <input type="text" />
                    </div>
                    <FaSearch className='search_box_icon' />
                </Box>
            </Modal>
        </div>

    )
}
