import React from 'react';
import Permits5 from '../../../images/PermisImgs/permits_img_5.jpg';
import Permits6 from '../../../images/PermisImgs/permits_img_6.jpg';
import Permits7 from '../../../images/PermisImgs/permits_img_7.jpg';
import Permits8 from '../../../images/PermisImgs/permits_img_8.gif';

export default function ProjectTravel() {
    return (
        <section className='projectstravel'>
            <div className="container">
                <h3 className="projectstravel_title">НАШИ ПРОЕКТЫ</h3>
                <div className='projectstravel_hr'></div>

                <div className="projects_foto_text">
                    <div className="projects_img"><img src={Permits5} alt="" /></div>

                    <div className="projects_text">
                        <h4 className="projects_title">Повышение доходов местных сообществ за счет развития эко-туризма для сокращения антропогенного воздействия и вовлечения их в охрану государственного природного заповодника «Кулун-Ата»</h4>
                        <p className="projects_suptitle">"Защита экосистем целевого ландшафта, ОФ-7".</p>
                    </div>
                </div>

                <div className="projects_foto_text">
                    <div className="projects_img"><img src={Permits6} alt="" /></div>

                    <div className="projects_text">
                        <h4 className="projects_title">Проект “Содействие торговле”.    ЦДС “Приключенческий Туризм”</h4>
                        <p className="projects_suptitle">ПРООН в Кыргызской Республике Проект “Содействие торговле”. ЦДС “Приключенческий Туризм”</p>
                    </div>
                </div>

                <div className="projects_foto_text">
                    <div className="projects_img"><img src={Permits7} alt="" /></div>

                    <div className="projects_text">
                        <h4 className="projects_title">Проект ЕБРР (Европейский Банк Реконструкции и Развития)</h4>
                        <p className="projects_suptitle">Обучение гидов и хозяев гостевого дома клиентоориентированному сервису в туризме, гостеприимству и продвижению туристических продуктов ОсОО «Сон-Куль».</p>
                    </div>
                </div>

                <div className="projectstravel_gif">
                    <img src={Permits8} alt="" />
                </div>

            </div>
        </section>
    )
}
