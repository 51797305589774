import React from 'react';
// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
// img
import Foto1 from '../../../images/Foto/foto_1.jpg';
import Foto2 from '../../../images/Foto/foto_2.jpg';
import Foto3 from '../../../images/Foto/foto_3.jpg';
import Foto4 from '../../../images/Foto/foto_4.jpg';
import Foto5 from '../../../images/Foto/foto_5.jpg';
import Foto6 from '../../../images/Foto/foto_6.jpg';
import Foto7 from '../../../images/Foto/foto_7.jpg';
import Foto8 from '../../../images/Foto/foto_8.jpg';
import Foto9 from '../../../images/Foto/foto_9.jpg';
import Foto10 from '../../../images/Foto/foto_10.jpg';
import Foto11 from '../../../images/Foto/foto_11.jpg';
import Foto12 from '../../../images/Foto/foto_12.jpg';
import Foto13 from '../../../images/Foto/foto_13.jpg';
import Foto14 from '../../../images/Foto/foto_14.jpg';
import Foto15 from '../../../images/Foto/foto_15.jpg';


export default function AboutusTravel() {
    return (
        <section className='aboutustravel'>
            <div className="aboutustravelbg">
                <h5 className="aboutus_title">Our Mission Statement</h5>
                <div className="aboutus_text">
                    <p className="aboutus_suptitle">We are a team of local tourism experts with complementary skills in culture and adventure tourism. We collaborate with communities around the region to create and implement unique, customized tour products. This enables our guests to have unforgettable, exciting, enriching, immersive experiences while at the same time giving back through cross-cultural exchange, support for the preservation of traditions and ecology, and enhancement of regional and global integration.</p>
                </div>
                <h5 className="aboutus_title">Our Story</h5>
                <div className="aboutus_text">
                    <p className="aboutus_suptitle">Kyrgyz Tourism is a travel company providing tours in Kyrgyzstan and Central Asia.</p>
                </div>
                <div className="aboutus_text">
                    <p className="aboutus_suptitle">We began our venture into tourism 18 years ago. For over 10 years our work was devoted to Community Based Tourism in Kyrgyzstan. During this time, we have developed from local tour guides to reliable tourism professionals. Our experience and strong convictions give us an opportunity to make our tour products diverse, authentic, and genuine experiences.</p>
                </div>
                <div className="aboutus_text">
                    <p className="aboutus_suptitle">Growing up in the village, our days were full of joy and energy, our evenings spent listening to the colorful world of Grandma’s stories and tales. We understand our people, nature, culture, and lifetimes.</p>
                </div>
                <div className="aboutus_text">
                    <p className="aboutus_suptitle">We believe you will enjoy your stay in Kyrgyzstan experiencing our new, multi-active and fascinating tour-products, sharing our adventures.</p>
                </div>
                <div className="aboutus_text">
                    <p className="aboutus_suptitle">Join us for an original and vivid experience!</p>
                </div>
                <h5 className="aboutus_title">Our Values</h5>
                <div className="aboutus_text">
                    <p className="aboutus_suptitle">– Subject-matter Expertise</p>
                    <p className="aboutus_suptitle">– Eco-friendliness</p>
                    <p className="aboutus_suptitle">– Sustainability</p>
                </div>
                <div className="aboutus_text">
                    <p className="aboutus_suptitle">Over the centuries Kyrgyz people have kept true to their nomadic culture. It has rich life experience with honest values and understanding; keeping ancient beliefs, worshiping ancestors and nature. In these days of globalization, we need to keep the best of our cultural, and historical heritage and share it.   </p>
                </div>
                <div className="aboutus_text">
                    <p className="aboutus_suptitle">We work with local tourism stakeholders first hand which gives local communities an opportunity to benefit directly. We encourage long-lasting cooperation and sustainability.</p>
                </div>
                <div className="aboutus_text">
                    <p className="aboutus_suptitle">Kyrgyz Tourism is traditional Kyrgyz hospitality!</p>
                </div>
                <div className="aboutus_text">
                    <p className="aboutus_suptitle">The miracles of nature and culture are waiting for you. Experience Kyrgyzstan with us and we will provide you with authentic Kyrgyz hospitality!</p>
                </div>



                <div className="aboutus_block">
                    <div className="aboutus_img_card">
                        <div className="aboutus_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Foto1} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>
                    <div className="aboutus_img_card">
                        <div className="aboutus_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Foto2} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>
                    <div className="aboutus_img_card">
                        <div className="aboutus_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Foto3} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>
                    <div className="aboutus_img_card">
                        <div className="aboutus_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Foto4} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>
                    <div className="aboutus_img_card">
                        <div className="aboutus_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Foto5} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>
                    <div className="aboutus_img_card">
                        <div className="aboutus_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Foto6} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>
                    <div className="aboutus_img_card">
                        <div className="aboutus_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Foto7} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                    <div className="aboutus_img_card">
                        <div className="aboutus_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Foto8} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>
                    <div className="aboutus_img_card">
                        <div className="aboutus_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Foto9} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>
                    <div className="aboutus_img_card">
                        <div className="aboutus_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Foto10} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>
                    <div className="aboutus_img_card">
                        <div className="aboutus_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Foto11} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>
                    <div className="aboutus_img_card">
                        <div className="aboutus_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Foto12} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>
                    <div className="aboutus_img_card">
                        <div className="aboutus_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Foto13} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>
                    <div className="aboutus_img_card">
                        <div className="aboutus_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Foto14} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>
                    <div className="aboutus_img_card">
                        <div className="aboutus_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={Foto15} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>
                </div>

                <hr className='aboutushr' />
                <div className="aboutus_btn">
                    <button>VIEW ALL TOURS</button>
                </div>
            </div>
        </section >
    )
}
