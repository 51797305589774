import React from 'react';
import { Link } from 'react-router-dom';

export default function OneDayBg() {
    return (
        <section className="onedaybg">
            <div className="container">
                <div className='blog_hone_text'>
                    <Link className='blog_home' to="/">Home/</Link>
                    <div className="blog_home">Tours/</div>
                    <div className="blog_home">One Day Tours</div>
                </div>
                <hr />
                <h1 className='blog_title'>Kyrgyzstan One Day Tours</h1>
                <p className='bike_siptitle'>Create unforgettable memories with One Day Tours in Kyrgyzstan</p>
            </div>
        </section>
    )
}
