import React from 'react';
import { Link } from 'react-router-dom';
// react awesome
import { LuClock9 } from "react-icons/lu";
import Cardimg26 from '../../images/CardImg/card_img_26.jpg';
import Cardimg27 from '../../images/CardImg/card_img_27.jpg';
import Cardimg14 from '../../images/CardImg/card_img_14.jpg';
import Cardimg28 from '../../images/CardImg/card_img_28.jpg';
import Cardimg29 from '../../images/CardImg/card_img_29.jpg';
import Cardimg19 from '../../images/CardImg/card_img_19.jpg';
import Cardimg30 from '../../images/CardImg/card_img_30.jpg';
import Cardimg13 from '../../images/CardImg/card_img_13.jpg';
import Cardimg31 from '../../images/CardImg/card_img_31.jpg';
import Cardimg6 from '../../images/CardImg/card_img_6.jpg';




export default function HorsebackTours() {
    return (
        <section className='horsebacktours'>

            <h1 className="toptravel_title">Top Horseback travel deals</h1>

            <div className="toptravelscrol">

                <div className="toptravel_block">
                    <h2 className="toptravel_departng">Departing</h2>
                    <h2 className="toptravel_trip">Trip</h2>
                    <h2 className="toptravel_fotos">Fotos</h2>
                    <h2 className="toptravel_days">Days</h2>
                    <h2 className="toptravel_from">From USD</h2>
                </div>

                <div className="toptravel_bg">

                    <div className="toptravel_card">
                        <div className="toptravel_card_num">02 Feb 2024</div>
                        <div className="toptravel_card_trip">
                            <h1 className="toptravel_card_title">Okavango Experience</h1>
                            <p className="toptravel_card_suptitle">Johannesburg → Victoria Falls</p>
                        </div>
                        <div className="toptravel_card_img">
                            <img src={Cardimg29} alt="" />
                        </div>
                        <div className="toptravel_card_day">10</div>
                        <div className="toptravel_card_price">
                            <span className="toptravel_card_discount">1,500</span>
                            <div className="toptravel_card_cost">$1,299</div>
                        </div>

                        <div className="toptravel_card_view">View trip ›</div>
                    </div>

                    <div className="toptravel_card toptravel_card_border">
                        <div className="toptravel_card_num">02 Feb 2024</div>
                        <div className="toptravel_card_trip">
                            <h1 className="toptravel_card_title">Okavango Experience</h1>
                            <p className="toptravel_card_suptitle">Johannesburg → Victoria Falls</p>
                        </div>
                        <div className="toptravel_card_img">
                            <img src={Cardimg30} alt="" />
                        </div>
                        <div className="toptravel_card_day">10</div>
                        <div className="toptravel_card_price">
                            <span className="toptravel_card_discount">1,500</span>
                            <div className="toptravel_card_cost">$1,299</div>
                        </div>

                        <div className="toptravel_card_view">View trip ›</div>
                    </div>
                </div>
            </div>

            <div className="biketours_block">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg26} alt="" />
                    </div>

                    <h1 className='biketours_text'>“Tranquil Treasures: Discovering Kol-Ukok and Taldy Valley in the Kyrgyz Mountains”</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Trekking</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">4 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg27} alt="" />
                    </div>

                    <h1 className='biketours_text'>Alay Heights</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Trekking</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">6 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg14} alt="" />
                    </div>

                    <h1 className='biketours_text'>Winter adventure tour in Kyrgyzstan.</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Horse trek</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">8 days</div>
                    </div>
                </Link>
            </div>

            <div className="biketours_block pt-[30px]">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg28} alt="" />
                    </div>

                    <h1 className='biketours_text'>The mountain Lake Kol-Ukok</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Horse trek</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">4 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg29} alt="" />
                    </div>

                    <h1 className='biketours_text'>Lake Alatay & Sary-Chelek, horse trek.</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Horse trek</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">6 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg19} alt="" />
                    </div>

                    <h1 className='biketours_text'>The tallest waterfall in Central Asia “Shar”.</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Milti-active tour</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">6 days</div>
                    </div>
                </Link>
            </div>

            <div className="biketours_block pt-[30px]">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg30} alt="" />
                    </div>

                    <h1 className='biketours_text'>Travelling from Kichik-Alay to Pamir-Alay.</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Alay mountains, Lenin peak panorama</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">7 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg13} alt="" />
                    </div>

                    <h1 className='biketours_text'>Nomadic Horse Trek</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Horseback tour</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">9 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg31} alt="" />
                    </div>

                    <h1 className='biketours_text'>Trekking from Chui Valley to Issyk-Kul</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Horseback or trekking tour</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">3 days</div>
                    </div>
                </Link>
            </div>

            <div className="biketours_block pt-[30px]">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg6} alt="" />
                        <div className="deals_geen">from <br />$150</div>
                    </div>

                    <h1 className='biketours_text'>Lake Kol-Ukok -nomad’s life</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Horseback tour</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">2 days</div>
                    </div>
                </Link>
            </div>
        </section>
    )
}
