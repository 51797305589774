import React from 'react';
import { Link } from 'react-router-dom';
// react awesome
import { LuClock9 } from "react-icons/lu";
// img
import Cardimg7 from '../../images/CardImg/card_img_7.jpg'
import Cardimg8 from '../../images/CardImg/card_img_8.jpg'
import Cardimg9 from '../../images/CardImg/card_img_9.jpg'
import Cardimg10 from '../../images/CardImg/card_img_10.jpg'

export default function CentralAsiaTours() {
    return (
        <section className='centralasiatours'>

            <h1 className="toptravel_title">Top Central Asia travel deals</h1>

            <div className="toptravelscrol">

                <div className="toptravel_block">
                    <h2 className="toptravel_departng">Departing</h2>
                    <h2 className="toptravel_trip">Trip</h2>
                    <h2 className="toptravel_fotos">Fotos</h2>
                    <h2 className="toptravel_days">Days</h2>
                    <h2 className="toptravel_from">From USD</h2>
                </div>

                <div className="toptravel_bg">

                    <div className="toptravel_card">
                        <div className="toptravel_card_num">02 Feb 2024</div>
                        <div className="toptravel_card_trip">
                            <h1 className="toptravel_card_title">Okavango Experience</h1>
                            <p className="toptravel_card_suptitle">Johannesburg → Victoria Falls</p>
                        </div>
                        <div className="toptravel_card_img">
                            <img src={Cardimg8} alt="" />
                        </div>
                        <div className="toptravel_card_day">10</div>
                        <div className="toptravel_card_price">
                            <span className="toptravel_card_discount">1,500</span>
                            <div className="toptravel_card_cost">$1,299</div>
                        </div>

                        <div className="toptravel_card_view">View trip ›</div>
                    </div>

                    <div className="toptravel_card toptravel_card_border">
                        <div className="toptravel_card_num">02 Feb 2024</div>
                        <div className="toptravel_card_trip">
                            <h1 className="toptravel_card_title">Okavango Experience</h1>
                            <p className="toptravel_card_suptitle">Johannesburg → Victoria Falls</p>
                        </div>
                        <div className="toptravel_card_img">
                            <img src={Cardimg9} alt="" />
                        </div>
                        <div className="toptravel_card_day">10</div>
                        <div className="toptravel_card_price">
                            <span className="toptravel_card_discount">1,500</span>
                            <div className="toptravel_card_cost">$1,299</div>
                        </div>

                        <div className="toptravel_card_view">View trip ›</div>
                    </div>
                </div>
            </div>

            <div className="biketours_block">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg7} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Adventure-KZ,KG,UZ</h1>
                    <p className='biketours_suptitle'><p class="excerpt"></p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">18 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg8} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road#11. Uzbekistan, Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Central Asia</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">14 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg9} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road#4. Uzbekistan – Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Central Asia</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">14 days</div>
                    </div>
                </Link>
            </div>

            <div className="biketours_block pt-[30px]">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg10} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road#3. Kazakstan – Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Central Asia</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">12 days</div>
                    </div>
                </Link>
            </div>

            <div className="centralasiacard mt-[30px]">
                <p>Central Asia is a huge country with a rich history and fascinating nature. Here steppes meet mountains and ancient cities meet modern times. Central Asia is famous for the Great Silk Road, which connected Asia and Europe. The Pamir Mountains of Tajikistan, the deserts of Turkmenistan and Uzbekistan, and the lakes of Kyrgyzstan adorn it, giving peculiarity to such a long path. The heritage of this period is well preserved and attracts travelers to Central Asia.</p>
                <p>When traveling to Central Asia, tourists visit such amazing places as:</p>
                <p>
                    Kazakhstan<br />
                    Kyrgyzstan<br />
                    Tajikistan<br />
                    Turkmenistan<br />
                    Uzbekistan
                </p>
                <p>Although all of these countries are historically connected, they are very different and each one deserves attention.</p>
                <p>Why it is worth traveling to Central Asia</p>
                <p>Tourism in Central Asia is a great choice for a great trip full of beautiful sights and insightful content. The coverage of countries and places to visit is quite large. Let&#8217;s find out what makes it so interesting and worth trying:</p>
                <p>The great historical legacy. The Silk Road made Central Asia a unique mix of different cultures and knowledge, as it historically represented a connection between two different worlds. In this way, the people of Central Asia saved many traditions, craftsmanship and other riches from the past.<br />
                    Incredible landscapes. Have you ever been to a desert or visited mountains? Central Asia is rich in mountains, vast deserts and cliffs, as well as rivers and even seas. Places like Tian Shan Mountain or the Kyzylkum Desert are breathtaking.<br />
                    Warm memories for your whole life. Tours to Central Asia are always carried out in high quality. Experience the nomadic lifestyle, taste the local cuisine, participate in street trading and have fun!<br />
                    Cost of Central Asia Tours</p>
                <p>A client can opt for a group tour on any conditions provided by our team or arrange a personal meeting at their preferred time and place. The tours on the coast of Central Asia are correspondingly different. Among all the trips offered, there are some that are the most popular among customers.</p>
                <p>Trekking tour in Tajikistan</p>
                <p>A great opportunity to take part in a trip to Central Asia, ideally in summer. Costs range from $80 to $200 for a group trip and $150-300 for an individual trip. Visit many of Dushanbe&#8217;s attractions such as the Antiquities Museum or the Hissar Fortress. Camping and many great views such as the oasis near the Gaza village or Lake Dushokha and many other great landscapes are also included.</p>
                <p>Silk Road No. 1. Kyrgyzstan, Tajikistan, Kazakhstan</p>
                <p>Try a 12-day trip and visit the most remarkable places on the Silk Road. Such a trip costs $100 for a group trip and $200 for an individual trip. You will enjoy many great views such as the Alay Range mountains, the Pamir Mountains and so on. City trips and luxury hotels are also included in the price.</p>

            </div>

        </section>
    )
}
