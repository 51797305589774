import React from 'react'
import Header from '../Home/Header'
import ExperienceBg from './ExperienceBg'
import ExperienceTours from './ExperienceTours'
import RightModalExperience from './RightModalExperience'
import Footer from '../Home/Footer'
import '../../css/tours.css'
import BackToTopButton from '../Home/BackToTopButton'




export default function Experience() {
  return (
    <div>
      <Header />
      <ExperienceBg />
      <div className="container displaycontainer">
        <ExperienceTours />
        <RightModalExperience />
      </div>
      <Footer />
      <BackToTopButton />
    </div>
  )
}
