import React from 'react';
import RightModal from '../../RightModal';
import '../../../css/rightmodal.css';
// img
import Cardimg19 from '../../../images/CardImg/card_img_19.jpg';
import Cardimg11 from '../../../images/CardImg/card_img_11.jpg';
import Cardimg13 from '../../../images/CardImg/card_img_13.jpg';
// react awesome
import { LuClock9 } from "react-icons/lu";


export default function RightModalUzbekistan() {
    return (
        <section className='rightmodaluzbekistan'>
            <RightModal />

            <h4 className="contact_title">Most popular tours</h4>

            <div className="contact_bg_block">
                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg19} alt="" />
                    </div>

                    <h1 className='contact_text'>The tallest waterfall in Central Asia “Shar”.</h1>
                    <p className='contact_suptitle'><p class="excerpt">Milti-active tour</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="contact_num">9 days</div>
                    </div>
                </div>

                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg11} alt="" />
                    </div>

                    <h1 className='contact_text'>Discover the Lakes Issyk-Kol, Song-Kol and Kel-Suu.</h1>
                    <p className='contact_suptitle'><p class="excerpt">Milti-active tour</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="contact_num">10 days</div>
                    </div>
                </div>

                <div className="contact_card_bg">
                    <div className="contact_img">
                        <img src={Cardimg13} alt="" />
                    </div>

                    <h1 className='contact_text'>Nomadic Horse Trek</h1>
                    <p className='contact_suptitle'><p class="excerpt">Horseback tour</p></p>

                    <hr className='contact_hr' />
                    <div className="contact_day_num">
                        <div className="contact_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="contact_num">9 days</div>
                    </div>
                </div>
            </div>
        </section>
    )
}
