import React from 'react';
import HeaderTwo from '../../Home/HeaderTwo';
import PermitsSwiper from './PermitsSwiper';
import ProjectTravel from './ProjectTravel';
import Footer from '../../Home/Footer';
import BackToTopButton from '../../Home/BackToTopButton';

export default function Projects() {
    return (
        <div>
            <HeaderTwo />
            <PermitsSwiper />
            <ProjectTravel />
            <Footer />
            <BackToTopButton />
        </div>
    )
}
