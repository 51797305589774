import React from 'react'
import Header from '../Home/Header'
import '../../css/tours.css'
import CentralAsiaBg from './CentralAsiaBg'
import CentralAsiaTours from './CentralAsiaTours'
import RightModalCentralAsia from '../CentralAsia/RightModalCentralAsia'
import Footer from '../Home/Footer'
import BackToTopButton from '../Home/BackToTopButton'

export default function CentralAsia() {
    return (
        <div>
            <Header />
            <CentralAsiaBg />
            <div className="container displaycontainer">
                <CentralAsiaTours />
                <RightModalCentralAsia />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
