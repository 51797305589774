import React from 'react';
import '../../css/blog.css'
import Header from '../Home/Header'
import BlogBg from './BlogBg'
import ArchivePage from './ArchivePage';
import RightModalBlog from './RightModalBlog'
import Footer from '../Home/Footer'
import BackToTopButton from '../Home/BackToTopButton'

export default function BlogPage() {
    return (
        <div>
            <Header />
            <BlogBg />
            <div className="container displaycontainer">
                <ArchivePage />
                <RightModalBlog />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
