import React from 'react'

export default function Latest() {
    return (
        <section className='latest'>
            <div className="container">
                <div className="latest_left_rgiht">
                    <div className="latest_left">
                        <h1 className='latest_title'>LATEST POST</h1>
                        <hr className='latest_hr' />

                        <div className="latest_img">
                            <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2023/09/Samsa-555x360.jpg" alt="" />
                            <div className="latest_shadow"></div>
                        </div>

                        <div className="latest_bg">
                            <h2 className='latest_bg_title'>Central Asian Delight: Samsa – Savory Silk Road Pastries</h2>
                            <p className='latest_bg_suptitle'>Samsa is a delectable savory pastry that holds a cherished place in Central Asian cuisines, reflecting the rich culinary traditions […]</p>
                            <center>
                                <div className="latest_bg_btn">
                                    <button>Read more →</button>
                                </div>
                            </center>
                        </div>
                    </div>

                    <div className="latest_right">
                        <h1 className='latest_title'>REVIEWS</h1>
                        <hr className='latest_hr' />

                        <div className="latest_right_icon_card">
                            <div className="latest_icon">
                                <img src="https://kyrgyzstan-tourism.com/wp-content/uploads/2019/03/Antonio-Castro.jpg" alt="" />
                            </div>

                            <div className="latest_right_card">
                                <h1 className="latest_right_card_name">Antonio Castro</h1>
                                <h2 className="latest_right_card_work">TRAVELER</h2>
                                <p className='latest_right_card_suptitle'>The friends of Native Kyrgyzstan have been very helpful during my journey in Kyrgyzstan. They have organised the visits to […]</p>
                                <a className='latest_right_card_more' href="#">Read more ...</a>
                            </div>
                        </div>

                        <div className="latest_right_icon_card">
                            <div className="latest_icon">
                                <img style={{ height: '116px' }} src="https://kyrgyzstan-tourism.com/wp-content/uploads/2019/03/400969_101519606676593_902175698_n.jpg" alt="" />
                            </div>

                            <div className="latest_right_card">
                                <h1 className="latest_right_card_name">Marianne Carrière</h1>
                                <h2 className="latest_right_card_work">LIVING IN THE NETHERLANDS, TRAVELER AND VOLUNTEER IN KYRGYZSTAN</h2>
                                <p className='latest_right_card_suptitle'>“Kyrgyzstan is great country to discover! High mountains with endless trails to explore by foot or on a horseback. Nothing […]</p>
                                <a className='latest_right_card_more' href="#">Read more ...</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
