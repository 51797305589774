import React from 'react'

export default function InspirationTravel() {
    return (
        <section className='inspirationtravel'>
            <div className="inspirationtravelbg">
                <div className="inspirationtravel_text">
                    <h4 className="inspirationtravel_suptitle">Kyrgyzstan is a beautiful mountain country in Central Asia. We have a great and long history from ancient times. Kyrgyz nationality has a nomad culture and from the beginning, we respect nature and wildlife.  We love the mountains and green valleys.</h4>
                </div>
                <div className="inspirationtravel_text">
                    <h4 className="inspirationtravel_suptitle">There is a legend among us and our neighboring countries.</h4>
                </div>
                <div className="inspirationtravel_text">
                    <h4 className="inspirationtravel_suptitle">“One day when God decided to give the land among all nationalities. When this day came and every nationality got their invaluable gifts. But Kyrgyz slept and missed the right time to be there. When Kyrgyz showed up and wanted to get his land God said: “Unfortunately I don’t have land for you, all lands were distributed and there is no piece of land belong to you on the Word”. Kyrgyz was desperate and would be happy even at least to have some sort of his own corner. At long last God took pity on the Kyrgyz and presented one piece of land from the paradise”.</h4>
                </div>
                <div className="inspirationtravel_text">
                    <h4 className="inspirationtravel_suptitle">Since then, the Kyrgyz people have been living on the earth where all seasons reign: snow-white winter, blooming spring, sunny summer, golden autumn. The fertile valleys of Chui and Ferghana, the celestial snowy mountains of the Tien Shan, crystal clear rivers, mountain pearls of lakes, and green forests.</h4>
                </div>
                <div className="inspirationtravel_text">
                    <h4 className="inspirationtravel_suptitle">We try to capture and convey those beauties through our movies and we hope you get inspiration and pleasure from watching our video materials for you.
                        Of course, it’s impossible to convey the feeling and spirit that can be felt directly being in our paradise. Therefore, we invite everyone to travel to fabulous Kyrgyzstan.</h4>
                </div>
                <div className="inspirationtravel_text">
                    <h5 className="inspirationtravel_title">All movies are filmed and directed by Mirzabek Ozubekov.</h5>
                </div>
                <iframe className='yt_vidyo' width="100% " height="400" src="https://www.youtube.com/embed/O80hZHwiO9c?si=X7ntCZ4WX3A5x9J5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                <hr className='inspirationhr' />
                <div className="inspiration_btn">
                    <button>VIEW ALL TOURS</button>
                </div>
            </div>
        </section>
    )
}
