import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './assets/companents/Home/Home';
import GroupTours from './assets/companents/Group_tours/GroupTours'
import Blog from './assets/companents/Blog/Blog';
import BlogPage from './assets/companents/Blog/BlogPage';
import Bike from './assets/companents/Bike/Bike';
import KgMain from './assets/companents/KgMain/KgMain';
import CentralAsia from './assets/companents/CentralAsia/CentralAsia';
import Culture from './assets/companents/Culture/Culture'
import Experience from './assets/companents/Experience/Experience';
import Horseback from './assets/companents/Horseback/Horseback';
import Multi from './assets/companents/Multi/Multi';
import Road from './assets/companents/Road/Road';
import OneDay from './assets/companents/OneDay/OneDay';
import Photo from './assets/companents/Photo/Photo';
import Trekking from './assets/companents/Trekking/Trekking';
import Wildlife from './assets/companents/Wildlife/Wildlife';
import Winter from './assets/companents/Winter/Winter';
import Kazakstan from './assets/companents/Destinations/Kazakstan/Kazakstan';
import Kyrgyzstan from './assets/companents/Destinations/KyrgyzstanTours/KyrgyzstanTours';
import KyrgyzstanPage from './assets/companents/Destinations/KyrgyzstanTours/KyrgyzstanPage';
import Tajikistan from './assets/companents/Destinations/Tajikistan/Tajikistan';
import Uzbekistan from './assets/companents/Destinations/Uzbekistan/Uzbekistan';
import Turkmenistan from './assets/companents/Destinations/Turkmenistan/Turkmenistan';
import KyrgyzstanAbout from './assets/companents/Kyrgyzstan/KyrgizstanAbout/KyrgyzstanAbout';
import Events from './assets/companents/Kyrgyzstan/Eventss/Events';
import Around from './assets/companents/Kyrgyzstan/Around/Around';
import Projects from './assets/companents/Kyrgyzstan/Projects/Projects';
import Permits from './assets/companents/Kyrgyzstan/Permits/Permits';
import Inspiration from './assets/companents/Kyrgyzstan/Inspiration/Inspiration';
import Gallery from './assets/companents/Kyrgyzstan/Gallery/Gallery';
import Aboutus from './assets/companents/About/Aboutus/Aboutus';
import Sustainable from './assets/companents/About/Sustainable/Sustainabel';
import Contactus from './assets/companents/About/Contactus/Contactus';
import Datas from './assets/companents/Datas/DatasPage1';
import DatasPage2 from './assets/companents/Datas/DtasaPage2'
import DatasPage3 from './assets/companents/Datas/DatasPage3';

function App() {
  return (
    <div className="App">

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/grouptours' element={<GroupTours />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blogpage' element={<BlogPage />} />
        <Route path='/kgmain' element={<KgMain />} />
        <Route path='/bike' element={<Bike />} />
        <Route path='/centralasia' element={<CentralAsia />} />
        <Route path='/culture' element={<Culture />} />
        <Route path='/experience' element={<Experience />} />
        <Route path='/horseback' element={<Horseback />} />
        <Route path='/multi' element={<Multi />} />
        <Route path='/road' element={<Road />} />
        <Route path='/OneDay' element={<OneDay />} />
        <Route path='/photo' element={<Photo />} />
        <Route path='/trekking' element={<Trekking />} />
        <Route path='/wildlife' element={<Wildlife />} />
        <Route path='/winter' element={<Winter />} />
        <Route path='/kazakstan' element={<Kazakstan />} />
        <Route path='/kyrgyzstan' element={<Kyrgyzstan />} />
        <Route path='/kyrgyzstanpage' element={<KyrgyzstanPage />} />
        <Route path='/tajikistan' element={<Tajikistan />} />
        <Route path='/uzbekistan' element={<Uzbekistan />} />
        <Route path='/turkmenistan' element={<Turkmenistan />} />
        <Route path='/kyrgyzstanabout' element={<KyrgyzstanAbout />} />
        <Route path='/events' element={<Events />} />
        <Route path='/around' element={<Around />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/permits' element={<Permits />} />
        <Route path='/inspiration' element={<Inspiration />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/aboutus' element={<Aboutus />} />
        <Route path='/sustainable' element={<Sustainable />} />
        <Route path='/contactus' element={<Contactus />} />
        <Route path='/datas' element={<Datas />} />
        <Route path='/dataspage2' element={<DatasPage2 />} />
        <Route path='/dataspage3' element={<DatasPage3 />} />
      </Routes>


    </div>
  );
}

export default App;
