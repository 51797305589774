import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "../../../css/Swipercss/permitsswiper.css";
// img
import Permitsimg1 from '../../../images/PermisImgs/permits_img_1.jpg'
import Permitsimg2 from '../../../images/PermisImgs/permits_img_2.jpg'
import Permitsimg3 from '../../../images/PermisImgs/permits_img_3.jpg'
import Permitsimg4 from '../../../images/PermisImgs/permits_img_4.jpg'

// import required modules
import { Pagination, Navigation } from 'swiper/modules';


export default function PermitsSwiper() {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 760);

  useEffect(() => {
      const handleResize = () => {
          setIsWideScreen(window.innerWidth >= 400);
      };

      window.addEventListener('resize', handleResize);

      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={isWideScreen}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide style={{ height: "470px" }}>
          <div style={{ width: '100%', height: '470px', objectFit: 'cover' }} className='main_imgs'><img src={Permitsimg1} alt="" /></div>
          <div className="projets_swipet_block" >
            <h2 className="projets_swipet_title">Kyrgyzstan Tourism</h2>
            <p className="projets_swipet_suptitle">Planning our project goals in tourism and development of schedule with steps that help us to promote tourism in every corner of our country.</p>
          </div>
        </SwiperSlide>
        <SwiperSlide style={{ height: "470px" }}>
          <div style={{ width: '100%', height: '470px' }} className='main_imgs'><img src={Permitsimg2} alt="" /></div>
          <div className="projets_swipet_block" >
            <h2 className="projets_swipet_title">Networking</h2>
            <p className="projets_swipet_suptitle">Involving ourselves with target groups and other organizations where like-minded professionals meet is one of the priorities of our work.</p>
          </div>
        </SwiperSlide>
        <SwiperSlide style={{ height: "470px" }}>
          <div style={{ width: '100%', height: '470px' }} className='main_imgs'><img src={Permitsimg3} alt="" /></div>
          <div className="projets_swipet_block" >
            <h2 className="projets_swipet_title">Community</h2>
            <p className="projets_swipet_suptitle">Our experience to help others and get motivated to grow together</p>
          </div>
        </SwiperSlide>
        <SwiperSlide style={{ height: "470px" }}>
          <div style={{ width: '100%', height: '470px' }} className='main_imgs'><img src={Permitsimg4} alt="" /></div>
          <div className="projets_swipet_block" >
            <h2 className="projets_swipet_title">Effectiveness</h2>
            <p className="projets_swipet_suptitle">We are involved in every aspect of our projects and focused on the final results.</p>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  )
}
