import React from 'react';
import { Link } from 'react-router-dom';

export default function AboutusBg() {
    return (
        <section className='aboutusbg'>
            <div className="container">
                <div className='blog_hone_text'>
                    <Link className='blog_home' to="/">Home/</Link>
                    <div className="blog_home">About us</div>
                </div>
                <hr />
                <h1 className='blog_title'>About us</h1>
            </div>
        </section>
    )
}
