import React from 'react'

export default function Trip() {
    return (
        <section className='trip'>
            <div className="container_trip">
                <h3 className='trip_title'>
                    <a href="">“Discovering Chatkal: Unveiling Nature’s Hidden Treasures Expedition”</a>
                </h3>
                <div className="div">
                    <div className="trip_bg_green">
                        <div className="trip_depature">Departure</div>
                        <div className="trip_end">End</div>
                        <div className="trip_status">Status</div>
                        <div className="trip_price">Price</div>
                        <div className="trip_book">Book</div>
                    </div>
                    <div className="trip_white_1">
                        <div className="trip_depature_white">Jun 18, 2024 - Tue</div>
                        <div className="trip_end_white">Jun 22, 2024 - Sat</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">580 USD</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                </div>

                <h3 className='trip_title'>
                    <a href="">Nomadic horse trek</a>
                </h3>
                <div className="div">
                    <div className="trip_bg_green">
                        <div className="trip_depature">Departure</div>
                        <div className="trip_end">End</div>
                        <div className="trip_status">Status</div>
                        <div className="trip_price">Price</div>
                        <div className="trip_book">Book</div>
                    </div>
                    <div className="trip_white_1">
                        <div className="trip_depature_white">Jul 29, 2024 - Mon</div>
                        <div className="trip_end_white">Aug 6, 2024 - Tue</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">$1500</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                </div>
                <h3 className='trip_title'>
                    <a href="">Trip to Song-Kol & Karakol every week</a>
                </h3>
                <div className="div">
                    <div className="trip_bg_green">
                        <div className="trip_depature">Departure</div>
                        <div className="trip_end">End</div>
                        <div className="trip_status">Status</div>
                        <div className="trip_price">Price</div>
                        <div className="trip_book">Book</div>
                    </div>
                    <div className="trip_white_1">
                        <div className="trip_depature_white">May 29, 2024 - Wed</div>
                        <div className="trip_end_white">Jun 2, 2024 - Sun</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">$390</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                    <div className="trip_white_2">
                        <div className="trip_depature_white">Jun 5, 2024 - Wed</div>
                        <div className="trip_end_white">Jun 9, 2024 - Sun</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">$390</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                    <div className="trip_white_1">
                        <div className="trip_depature_white">Jun 12, 2024 - Wed</div>
                        <div className="trip_end_white">Jun 16, 2024 - Sun</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">$390</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                    <div className="trip_white_2">
                        <div className="trip_depature_white">Jun 19, 2024 - Wed</div>
                        <div className="trip_end_white">Jun 23, 2024 - Sun</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">$390</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                    <div className="trip_white_1">
                        <div className="trip_depature_white">Jun 26, 2024 - Wed</div>
                        <div className="trip_end_white">Jun 30, 2024 - Sun</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">$390</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                    <div className="trip_white_2">
                        <div className="trip_depature_white">Jul 3, 2024 - Wed</div>
                        <div className="trip_end_white">Jul 7, 2024 - Sun</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">$390</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                    <div className="trip_white_1">
                        <div className="trip_depature_white">Jul 10, 2024 - Wed</div>
                        <div className="trip_end_white">Jul 14, 2024 - Sun</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">$390</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                    <div className="trip_white_2">
                        <div className="trip_depature_white">Jul 17, 2024 - Wed</div>
                        <div className="trip_end_white">Jul 21, 2024 - Sun</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">$390</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                    <div className="trip_white_1">
                        <div className="trip_depature_white">Jul 24, 2024 - Wed</div>
                        <div className="trip_end_white">Jul 28, 2024 - Sun</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">$390</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                    <div className="trip_white_2">
                        <div className="trip_depature_white">Jul 31, 2024 - Wed</div>
                        <div className="trip_end_white">Aug 4, 2024 - Sun</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">$390</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                    <div className="trip_white_1">
                        <div className="trip_depature_white">Aug 7, 2024 - Wed</div>
                        <div className="trip_end_white">Aug 11, 2024 - Sun</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">$390</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                    <div className="trip_white_2">
                        <div className="trip_depature_white">Aug 14, 2024 - Wed</div>
                        <div className="trip_end_white">Aug 18, 2024 - Sun</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">$390</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                    <div className="trip_white_1">
                        <div className="trip_depature_white">Aug 21, 2024 - Wed</div>
                        <div className="trip_end_white">Aug 25, 2024 - Sun</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">$390</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                    <div className="trip_white_2">
                        <div className="trip_depature_white">Aug 28, 2024 - Wed</div>
                        <div className="trip_end_white">Sep 1, 2024 - Sun</div>
                        <div className="trip_status_white">Available</div>
                        <div className="trip_price_white">$390</div>
                        <a href='#book' className="trip_book_white"><button>Book</button></a>
                    </div>
                </div>
            </div>
        </section>
    )
}
