import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MenuBurger from '../MenuBurger/MenuBurger';
// react awesome
import { FaGlobeAsia } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { TfiYoutube } from "react-icons/tfi";
import { LiaTripadvisor } from "react-icons/lia";
import { GiHamburgerMenu } from "react-icons/gi";
import Search from './Search';

function Header() {

    const [isopen, setIsopen] = useState(false);

    const [hiderShow, setHiderShow] = useState({});

    const handMenu = () => {

        setIsopen((prev) => !prev);

        if (isopen) {
            setHiderShow(() => {
                return {}
            })
        } else {
            setHiderShow(() => {
                return { display: "initial" }
            })
        }
    }


    return (
        <header>
            <div className="container">
                <div className="header_left_right">
                    <div className="header_left"><a href="tel:+996706363738">+996 706 363738</a></div>

                    <div className='header_right'>
                        <Link to="https://www.facebook.com/kgTourism/"><FaFacebookF className="header_icon_1" /></Link>
                        <Link to="https://www.instagram.com/kyrgyztourism_/"><FaInstagram className="header_icon_1" /></Link>
                        <Link to="https://www.youtube.com/channel/UCUpfM9AjbXgLe_IGKSDtqRA"><TfiYoutube className="header_icon_1" /></Link>
                        <Link to="https://www.tripadvisor.ru/Attraction_Review-g293948-d17433303-Reviews-Kyrgyz_Tourism-Bishkek.html"><LiaTripadvisor className="header_icon_1" /></Link>
                        <div className="header_icon_socila"></div>
                        <Search />
                    </div>
                </div>

                <hr />

                <div className="nav_left_right">
                    <div className="logo_icon">
                        <Link to="/" className="logo"><img src="https://kyrgyzstan-tourism.com/wp-content/themes/native/img/logo_white.png" alt="" /></Link>

                        {isopen ? <button className="burder " onClick={handMenu}><IoCloseOutline /></button> : <button className="burder" onClick={handMenu}><GiHamburgerMenu /></button>}
                    </div>

                    <div className='menubugerNav' style={hiderShow}><MenuBurger /></div>

                    <nav>

                        <ul className='navbar'>
                            <li><Link to="/">home</Link></li>
                            <li><Link>tours▾</Link>

                                <ul className="down">
                                    <Link to="/bike" className='navdown_1'><a>bike tours</a></Link>
                                    <Link to="/centralasia" className='navdown_1'><a>centralasia tours</a></Link>
                                    <Link to="/culture" className='navdown_1'><a>Culture tours</a></Link>
                                    <Link to="/experience" className='navdown_1'><a>Experience tours</a></Link>
                                    <Link to="/horseback" className='navdown_1'><a>Horseback tours</a></Link>
                                    <Link to="/multi" className='navdown_1'><a>Multi-active tours</a></Link>
                                    <Link to="/road" className='navdown_1'><a>Off road tours</a></Link>
                                    <Link to="/oneday" className='navdown_1'><a>One Day Tours</a></Link>
                                    <Link to="/photo" className='navdown_1'><a>photo tours</a></Link>
                                    <Link to="/trekking" className='navdown_1'><a>Trekking Kyrgyzstan</a></Link>
                                    <Link to="/wildlife" className='navdown_1'><a>Wildlife Kyrgyzstan</a></Link>
                                    <Link to="/winter" className='navdown_1'><a>Winter tours</a></Link>
                                </ul>
                            </li>
                            <li><Link>destionations▾</Link>
                                <ul className="down">
                                    <Link to="/kazakstan" className="navdown_2"><Link to="/kazakstan">kazakhstan tourism</Link></Link>
                                    <Link to="/kyrgyzstan" className="navdown_2"><Link to="/kyrgyzstan">kyrgyzstan</Link></Link>
                                    <Link to="/tajikistan" className="navdown_2"><Link to="/tajikistan">tajicistan tourism</Link></Link>
                                    <Link to="/turkmenistan" className="navdown_2"><Link to="/turkmenistan">turkmenistan tourism</Link></Link>
                                    <Link to="/uzbekistan" className="navdown_2"><Link to="/uzbekistan">uzbecistan tourism</Link></Link>
                                </ul>
                            </li>
                            <li><Link to="/grouptours">grouptours</Link></li>

                            <li><Link to="/blog">blog</Link></li>
                            <li><Link>kyrgyzstan▾</Link>
                                <ul className="down">
                                    <Link to="/kyrgyzstanabout" className="navdown_3"><Link to="/kyrgyzstanabout">About Kyrgyzstan</Link></Link>
                                    <Link to="/events" className="navdown_3"><Link to="/events">Events 2024</Link></Link>
                                    <Link to="/projects" className="navdown_3"><Link to="/projects  ">Projects</Link></Link>
                                    <Link to="/around" className="navdown_3"><Link to="/around">Getting around</Link></Link>
                                    <Link to="/permits" className="navdown_3"><Link to="/permits">Permits</Link></Link>
                                    <Link to="/inspiration" className="navdown_3"><Link to="/inspiration">Inspiration for visiting Kyrgyzstan</Link></Link>
                                    <Link to="/gallery" className="navdown_3"><Link to="/gallery">Photo gallery</Link></Link>
                                </ul>
                            </li>
                            <li><Link>about▾</Link>
                                <ul className="down">
                                    <Link to="/aboutus" className="navdown_4"><Link to="/aboutus">About us</Link></Link>
                                    <Link to="/sustainable" className="navdown_4"><Link to="/sustainable">Sustainable tourism</Link></Link>
                                    <Link to="/contactus" className="navdown_4"><Link to="/contactus">Contact us</Link></Link>
                                </ul>
                            </li>
                            <li><Link className='nav_globe_icon'><FaGlobeAsia /> en ▾</Link>
                                <ul className="down">
                                    <Link to="" className="navdown_5"><Link to="">de</Link></Link>
                                </ul>
                            </li>
                        </ul>

                    </nav>
                </div>
            </div>

        </header>
    )
}

export default Header