import React from 'react';

export default function PermitsTravel() {
    return (
        <section className="permitstravel">
            <div className="permitstravelbg">
                <div className="permitstravel_text">
                    <p className="permitstravel_suptitle">Kyrgyzstan has borders with Kazakhstan, Tajikistan, Uzbekistan, and China. From the Soviet times, because of the border security issues of the USSR, the most protected line was the Kyrgyz-Chinese one.</p>
                </div>
                <div className="permitstravel_text">
                    <p className="permitstravel_suptitle">After the Independence, the borders between ex-USSR countries were delimitated, but because of inconsiderate division, till these days’ some part of the borderlines of the CIS countries under the discussion.</p>
                </div>
                <div className="permitstravel_text">
                    <p className="permitstravel_suptitle">Today border crossing process regulated by the Decree of the Government of the Kyrgyz Republic dated from April 2016. Where point out border zones, crossing rules, and border regime.</p>
                </div>
                <div className="permitstravel_text">
                    <p className="permitstravel_suptitle">Most of the territories are famous with its beautiful and untouched nature, places abandoned by people, territories of national parks and special protected zones.  There are territories with glaciers, snow-capped peaks, meadows, and alpine lakes.</p>
                </div>
                <iframe className='permitstravel_globe' src="https://www.google.com/maps/d/embed?mid=1xhLgRQMug4fNipc9sDl4PWLvEFRf8j8&ehbc=2E312F" width="640" height="480"></iframe>
                    <div className="permitstravel_text">
                    <p className="permitstravel_suptitle">We would be happy to help you with getting border permits to border zone protected areas.</p>
                </div>
                <h3 className="permitstravel_title">Batken Oblast (Leilek, Kara-Bulak, Aidarken rayon)</h3>
                <ul className="permitstravel_ul">
                    <li className="permitstravel_li">Leilek Check point</li>
                    <li className="permitstravel_li">Kyzyl-Bel Check point</li>
                </ul>
                <h3 className="permitstravel_title">Naryn Oblast (At-Bashy rayon, Naryn rayon)</h3>
                <ul className="permitstravel_ul">
                    <li className="permitstravel_li">Ak-Sai high mountainous valley</li>
                    <li className="permitstravel_li">Arpa high mountainous valley</li>
                    <li className="permitstravel_li">Chatyr-Kol Lake</li>
                    <li className="permitstravel_li">Kol-Suu Lake</li>
                    <li className="permitstravel_li">Torugart check point</li>
                </ul>
                <h3 className="permitstravel_title">Issyk-Kul Oblast (Jety-Oguz, Ak-Suu, Tyup, and Issykkul rayon)</h3>
                <ul className="permitstravel_ul">
                    <li className="permitstravel_li">Ak-Shiyrak village</li>
                    <li className="permitstravel_li">Echkilitash check point</li>
                    <li className="permitstravel_li">Enilchek</li>
                    <li className="permitstravel_li">Karasay check point</li>
                    <li className="permitstravel_li">Karkyra high mountainous valley</li>
                    <li className="permitstravel_li">Karkyra check point</li>
                    <li className="permitstravel_li">Khan-Tengri National Park</li>
                    <li className="permitstravel_li">Sary-Jaz area</li>
                    <li className="permitstravel_li">Sarychat-Ertash Reserve</li>
                </ul>
                <h3 className="permitstravel_title">Chui Oblast (Kemin rayon)</h3>
                <ul className="permitstravel_ul">
                    <li className="permitstravel_li">Chon-Kemin National Park</li>
                    <li className="permitstravel_li">Ak-Jol check point</li>
                </ul>
                <h3 className="permitstravel_title">Talas Oblast (Talas, Manas, Karabuura rayon)</h3>
                <ul className="permitstravel_ul">
                    <li className="permitstravel_li">Chong-Kapka Check point</li>
                    <li className="permitstravel_li">Chaldybar Check point</li>
                </ul>
                <h3 className="permitstravel_title">Osh Oblast (Kara-Kulja, Alay and Chong-Alay rayon)</h3>
                <ul className="permitstravel_ul">
                    <li className="permitstravel_li">Irkeshtam Check point</li>
                    <li className="permitstravel_li">Bor-Dobo Check point</li>
                </ul>
                <h3 className="permitstravel_title_1">How to get a border permit?</h3>
                <ul className="permitstravel_ul">
                    <li className="permitstravel_li">Send us information about your plans to visit border zone areas</li>
                    <li className="permitstravel_li">Give dates of visit to be sure to have a valid permit</li>
                    <li className="permitstravel_li">Send us your scanned passport copy</li>
                    <li className="permitstravel_li">Make an online permit</li>
                    <li className="permitstravel_li">Have confirmation on accepted by our office</li>
                    <li className="permitstravel_li">Within the 2 weeks, you permit would be ready.</li>
                </ul>

                <p className="permitstravel_paragraph">
                    NOTE: The travelers want to get only permits and take individual tours to the areas independently should take all responsibility by themselves. Before getting the original of permits from our office we will kindly ask you to fill up the claim waiver form to “Kyrgyz Tourism LTD”.
                </p>

                <center><h3 className="permits_input_title">Needs border permits? Please send us to request the email:  gotripkg@gmail.com  or fill out the contact form below.</h3></center>

                <div className="permits_input">
                    <div className="permits_input_search"><input type="search" placeholder='Your name' /></div>
                    <div className="permits_input_search"><input type="search" placeholder='Your email' /></div>
                    <div className="permits_input_search"><input type="search" placeholder='Tel (WhatsApp)' /></div>
                    <div className="permits_input_search"><input type="search" placeholder='Area of visiting' /></div>
                    <div className="permits_input_date"><input type="date" /></div>
                    <div className="permits_input_date"><input type="date" /></div>
                    <div className="permits_input_mesage"><input type="search" placeholder='Massage' /></div>
                    <div className="permits_input_file_text">
                        <div className="permits_input_file"><input type="text" /></div>
                        <label className='permits_input_text'><span>Choose file</span></label>
                    </div>
                    <div className="permits_input_btn"><button>Send</button></div>
                </div>

                <hr className='permitshr' />
                <div className="permits_btn">
                    <button>VIEW ALL TOURS</button>
                </div>
            </div>
        </section>
    )
}
