import React, { useRef, useState, } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../../css//mainswiper.css';
import { Autoplay, Pagination, Navigation, Keyboard } from 'swiper/modules';
// react icons
import { IoEyeSharp } from "react-icons/io5";
// import img 
import Mainbg1 from '../../images/MainSwiperimg/main_1.jpg';
import Mainbg2 from '../../images/MainSwiperimg/main_2.jpg';
import Mainbg3 from '../../images/MainSwiperimg/main_3.jpg';
import Mainbg4 from '../../images/MainSwiperimg/main_4.jpg';
import Mainbg5 from '../../images/MainSwiperimg/main_5.jpg';
import Mainbg6 from '../../images/MainSwiperimg/main_6.jpg';
import Mainbg7 from '../../images/MainSwiperimg/main_7.jpg';
import Mainbg8 from '../../images/MainSwiperimg/main_8.jpg';
import Mainbg9 from '../../images/MainSwiperimg/main_9.jpg';
import Mainbg10 from '../../images/MainSwiperimg/main_10.jpg';
import Mainbg11 from '../../images/MainSwiperimg/main_11.jpg';
import vidyo from '../../images/turizm.mp4'


export default function MainSwiper() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: false,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation,]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div style={{ width: '100%', height: '500px' }} className='main_imgs'>
            <video style={{ width: '100%', height: '500px' }} src={vidyo} autoPlay muted loop></video>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: '100%', height: '500px' }} className='main_imgs'><img src={Mainbg1} alt="" /></div>
          <div className="main_block" >
            <h2 className="main_title">Kyrgyz Tourism</h2>
            <p className="main_suptitle">Explore. Dream. Discover.</p>
            <Link to="/kgmain" className="main_btn">
              <center> <button><IoEyeSharp /> Kyrgyzstan Travel Guide</button></center>
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: '100%', height: '500px' }} className='main_imgs'><img src={Mainbg2} alt="" /></div>
          <div className="main_block">
            <h2 className="main_title">Horseback tours</h2>
            <p className="main_suptitle">Find your tour today</p>
            <Link to="horseback" className="main_btn">
              <center> <button><IoEyeSharp />view tours</button></center>
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: '100%', height: '500px' }} className='main_imgs'><img src={Mainbg3} alt="" /></div>
          <div className="main_block">
            <h2 className="main_title">Trekking tours</h2>
            <p className="main_suptitle">Find your tour today</p>
            <Link to="/trekking" className="main_btn">
              <center><button><IoEyeSharp />view tours</button></center>
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: '100%', height: '500px' }} className='main_imgs'><img src={Mainbg4} alt="" /></div>
          <div className="main_block">
            <h2 className="main_title">Photo tours</h2>
            <p className="main_suptitle">Find your tour today</p>
            <Link to="/photo" className="main_btn">
              <center><button><IoEyeSharp />view tours</button></center>
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: '100%', height: '500px' }} className='main_imgs'><img src={Mainbg5} alt="" /></div>
          <div className="main_block">
            <h2 className="main_title">Experience tours</h2>
            <p className="main_suptitle">Find your tour today</p>
            <Link to="/experience" className="main_btn">
              <center><button><IoEyeSharp />view tours</button></center>
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: '100%', height: '500px' }} className='main_imgs'><img src={Mainbg6} alt="" /></div>
          <div className="main_block">
            <h2 className="main_title">Multi-active tours</h2>
            <p className="main_suptitle">Find your tour today</p>
            <Link to="/multi" className="main_btn">
              <center><button><IoEyeSharp />view tours</button></center>
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: '100%', height: '500px' }} className='main_imgs'><img src={Mainbg7} alt="" /></div>
          <div className="main_block">
            <h2 className="main_title">Culture-tours</h2>
            <p className="main_suptitle">Find your tour today</p>
            <Link to="/culture" className="main_btn">
              <center><button><IoEyeSharp />view tours</button></center>
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: '100%', height: '500px' }} className='main_imgs'><img src={Mainbg8} alt="" /></div>
          <div className="main_block">
            <h2 className="main_title">Off-road tours</h2>
            <p className="main_suptitle">Find your tour today</p>
            <Link to="/road" className="main_btn">
              <center><button><IoEyeSharp />view tours</button></center>
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: '100%', height: '500px' }} className='main_imgs'><img src={Mainbg9} alt="" /></div>
          <div className="main_block">
            <h2 className="main_title">Wildlife adventure</h2>
            <p className="main_suptitle">Find your tour today</p>
            <Link to="/wildlife" className="main_btn">
              <center><button><IoEyeSharp />view tours</button></center>
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: '100%', height: '500px' }} className='main_imgs'><img src={Mainbg10} alt="" /></div>
          <div className="main_block">
            <h2 className="main_title">Winter adventure tours</h2>
            <p className="main_suptitle">Select activities</p>
            <Link to="/winter" className="main_btn">
              <center><button><IoEyeSharp />view tours</button></center>
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: '100%', height: '500px' }} className='main_imgs'><img src={Mainbg11} alt="" /></div>
          <div className="main_block">
            <h2 className="main_title">Birdwatching</h2>
            <p className="main_suptitle">Find birding hotspots</p>
            <div className="main_btn">
              <center><button><IoEyeSharp />view tours</button></center>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
