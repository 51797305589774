import React from 'react';
import { Link } from 'react-router-dom';
import Cardimg7 from '../../../images/CardImg/card_img_7.jpg';
// react awesome
import { LuClock9 } from "react-icons/lu";

export default function TajikistanDestinations() {
  return (
    <section className="tajikistandestinations">
      <div className="biketours_block">
        <Link to="/datas"  className="biketours_card_bg">
          <div className="biketours_img">
            <img src={Cardimg7} alt="" />
          </div>

          <h1 className='biketours_text'>Silk Road Adventure-KZ,KG,UZ</h1>
          <p className='biketours_suptitle'><p class="excerpt"></p></p>

          <hr className='biketours_hr' />
          <div className="biketours_day_num">
            <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
            <div className="biketours_num">18 days</div>
          </div>
        </Link>

      </div>
      <div className="tajikistancard">

        <p>Tajikistan is a small country located in the world in Central Asia. Nevertheless, it is interesting for tourists from all over the world as it can boast several UNESCO World Heritage objects, great nature including the Fann and Pamir mountains, numerous cultural touring objects like the Buddha temple and the Khujand fortress, and many others. The country is very friendly, so be ready to meet kind and open-minded people keeping ancient world traditions.</p>
        <p>Tajikistan tourism is very interesting and can impress anyone. For example, tourists can visit Penjikent, a 5000-year-old city that was part of the Great Silk Road. It keeps the heritage of the Sogdiana civilization that existed thousands of years ago. Another great example is Khujand with its ruins, but you can start by reaching the Ak-Su massif from it.</p>
        <p>Every city of Tajikistan has its great landmarks worthy to see.</p>
        <p>Tajikistan tourism packages cost</p>
        <p>The cost of tours to Tajikistan may vary depending on the travel itinerary, its details, and the type of tour. It may be a group tour that follows the standard terms, and a personal one in which a client can set a preferable time and other points suitable for them. Let’s check out several popular tours.</p>

      </div>
    </section>
  )
}

