import React from 'react';
import Header from '../../Home/Header';
import UzbekistanBg from './UzbekistanBg';
import UzbekistanDestinations from './UzbekistanDestinations';
import RightModalUzbekistan from './RightModalUzbekistan';
import Footer from '../../Home/Footer';
import '../../../css/destinations/destinations.css';
import BackToTopButton from '../../Home/BackToTopButton';

export default function Uzbekistan() {
    return (
        <div>
            <Header />
            <UzbekistanBg />
            <div className="container displaycontainer">
                <UzbekistanDestinations />
                <RightModalUzbekistan />
            </div>
            <Footer />
            <BackToTopButton />
        </div>
    )
}
