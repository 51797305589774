import React from 'react';
import { Link } from 'react-router-dom';
// img
import Cardimg7 from '../../../images/CardImg/card_img_7.jpg';
import Cardimg10 from '../../../images/CardImg/card_img_10.jpg';
import Cardimg18 from '../../../images/CardImg/card_img_18.jpg';
// react awesome
import { LuClock9 } from "react-icons/lu"

export default function KazakstanDestinations() {
    return (
        <section className='kazakstandestinations'>
            <div className="biketours_block">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg7} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Adventure-KZ,KG,UZ</h1>
                    <p className='biketours_suptitle'><p class="excerpt"></p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">18 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg10} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road#3. Kazakstan – Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Central Asia</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">12 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg18} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Tour:Kazakhstan and Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Kyrgyzstan and Kazakhstan</p></p>
                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">9 days</div>
                    </div>
                </Link>
            </div>

            <div className="kazakstancard">
                <p>Kazakhstan is a large and old country with a rich history, a remarkable culture, and amazing nature. These lands are imbued with the past, but full of the present as well making it a unique connection of different ages. Taking a tour of Kazakhstan, everyone can find something interesting and impressive for.</p>
                <p>There is a beautiful and diverse nature in Kazakhstan. It can boast mountains, lakes, forests, rivers, a sea, reserves, Eco parks, and many others. Although Kazakhstan was a land of nomads and wanderers of the steppe, it’s not just a boring wasteland. Be ready to see amazing sights and unforgettable landscapes.</p>
                <p>Are you interested in it? Kazakhstan tourism is waiting for you! Take part in a group tour, or design an individual one. Memorable holidays and adventure in the legendary Silk Road, which covers a pretty big part of Kazakhstan, will be a great experience that you will never regret.</p>
                <p>Why Should You Visit Kazakhstan?</p>
                <p>It’s hard to find tourists who didn’t enjoy visiting Kazakhstan. However, for someone who has never been in this land, it’s pretty hard to imagine what is waiting for them on a trip. Let’s take a look at the list of main benefits of Kazakhstan tourism:</p>
                <p>Connecting with the history of Central Asia and the heritage of the past. Visit the Central State Museum, Museum of Antiquities, or the Rode-Kah-Kaha fortress. It’s the best way to see how life was here centuries ago.<br />
                    Beautiful nature. No one will remain indifferent to these amazing national parks. Breathtaking mountains and crystal-clear lakes and rivers are the true wealth of these lands.<br />
                    Great entertainment. Get in the atmosphere of the local life of Kazakhstan. Familiarize yourself with the modern culture of local people, and try Oriental sweets, kumis, beshbarmak, and other Kazakhstan meals. Visit museums, cafés, ski resorts, or ice-skating rinks – you won’t be bored for sure. It’s great fun to try a piece of life from another culture.<br />
                    Kazakhstan Tourism Packages Cost</p>
                <p>Our clients can take numerous trips to the Silk Road as it’s large and there are a lot of places that should be visited for sure. However, among the offered tours in Kazakhstan, there are some of the most popular.</p>
            </div>
        </section>
    )
}
