import React from 'react'
import { Link } from 'react-router-dom'
// react awesome
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { TfiYoutube } from "react-icons/tfi";
import { LiaTripadvisor } from "react-icons/lia";

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="footer_blcok">
          <div className="footer_card">
            <Link to="/" className="footer_logo">
              <img src="https://kyrgyzstan-tourism.com/wp-content/themes/native/img/logo_white.png" alt="" />
            </Link>

            <div className="footer_icosn">
              <Link to="https://www.facebook.com/kgTourism/"><FaFacebookF className="footer_icon_1" /></Link>
              <Link to="https://www.instagram.com/kyrgyztourism_/"><FaInstagram className="footer_icon_1" /></Link>
              <Link to="https://www.youtube.com/channel/UCUpfM9AjbXgLe_IGKSDtqRA"><TfiYoutube className="footer_icon_1" /></Link>
              <Link to="https://www.tripadvisor.ru/Attraction_Review-g293948-d17433303-Reviews-Kyrgyz_Tourism-Bishkek.html"><LiaTripadvisor className="footer_icon_1" /></Link>
            </div>
          </div>

          <div className="footer_card">
            <h6 className='footer_title'>Contacts</h6>
            <a className="foter_suptitle_a" href="tel:+996706363738">+996 706 363738</a>
            <a className="foter_suptitle_a" href="tel:+996777970779">+996 777 970779</a>
            <a className="foter_suptitle_a" href="mailto:info@kyrgyzstan-tourism.com">info@kyrgyzstan-tourism.com</a>
          </div>

          <div className="footer_card">
            <p className="foter_suptitle">Kyrgyz Republic. Bishkek city</p>
            <p className="foter_suptitle">Mon – Sat 9.00 – 18.00 Sunday CLOSED</p>
          </div>

          <div className="footer_card">
            <a className="foter_suptitle_a" href="">Sitemap</a>

          </div>
        </div>

      </div>
      <hr className='footer_hr' />
      <div className="container">
        <p className='footer_text'>©<span>SEBEP-TRAVEL. </span>Все права защищены. Разработано <span className='american'><Link>Sebeptravel.com</Link></span>  </p>
      </div>
    </footer>
  )
}
