import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// img
import Cardimg7 from '../../../images/CardImg/card_img_7.jpg';
import Cardimg10 from '../../../images/CardImg/card_img_10.jpg';
import Cardimg18 from '../../../images/CardImg/card_img_18.jpg';
import Kyrgyzstancardimg from '../../../images/CardImg/card_img_24.jpg';
// react awesome
import { LuClock9 } from "react-icons/lu";

export default function KyrgyzstranDestinations() {
    
  

    return (
        <section className='kyrgyzstandestinations'>

            <div className="biketours_block">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg7} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Adventure-KZ,KG,UZ</h1>
                    <p className='biketours_suptitle'><p class="excerpt"></p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">18 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg10} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road#3. Kazakstan – Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Central Asia</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">12 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg18} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Tour:Kazakhstan and Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Kyrgyzstan and Kazakhstan</p></p>
                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px"/></div>
                        <div className="biketours_num">9 days</div>
                    </div>
                </Link>
            </div>

            <div className="biketours_block pt-[30px]">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg7} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Adventure-KZ,KG,UZ</h1>
                    <p className='biketours_suptitle'><p class="excerpt"></p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">18 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg10} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road#3. Kazakstan – Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Central Asia</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">12 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg18} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Tour:Kazakhstan and Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Kyrgyzstan and Kazakhstan</p></p>
                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">9 days</div>
                    </div>
                </Link>
            </div>

            <div className="biketours_block pt-[30px]">
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg7} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Adventure-KZ,KG,UZ</h1>
                    <p className='biketours_suptitle'><p class="excerpt"></p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">18 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg10} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road#3. Kazakstan – Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Central Asia</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">12 days</div>
                    </div>
                </Link>
                <Link to="/datas"  className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg18} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Tour:Kazakhstan and Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Kyrgyzstan and Kazakhstan</p></p>
                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">9 days</div>
                    </div>
                </Link>
            </div>

            <div className="biketours_block pt-[30px]">
                <div className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg7} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Adventure-KZ,KG,UZ</h1>
                    <p className='biketours_suptitle'><p class="excerpt"></p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">18 days</div>
                    </div>
                </div>
                <div className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg10} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road#3. Kazakstan – Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Central Asia</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">12 days</div>
                    </div>
                </div>
                <div className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg18} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Tour:Kazakhstan and Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Kyrgyzstan and Kazakhstan</p></p>
                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">9 days</div>
                    </div>
                </div>
            </div>

            <div className="biketours_block pt-[30px]">
                <div className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg7} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Adventure-KZ,KG,UZ</h1>
                    <p className='biketours_suptitle'><p class="excerpt"></p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">18 days</div>
                    </div>
                </div>
                <div className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg10} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road#3. Kazakstan – Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Central Asia</p></p>

                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">12 days</div>
                    </div>
                </div>
                <div className="biketours_card_bg">
                    <div className="biketours_img">
                        <img src={Cardimg18} alt="" />
                    </div>

                    <h1 className='biketours_text'>Silk Road Tour:Kazakhstan and Kyrgyzstan</h1>
                    <p className='biketours_suptitle'><p class="excerpt">Kyrgyzstan and Kazakhstan</p></p>
                    <hr className='biketours_hr' />
                    <div className="biketours_day_num">
                        <div className="biketours_day"><LuClock9 color="#005271" size="18px" /></div>
                        <div className="biketours_num">9 days</div>
                    </div>
                </div>
            </div>

            <div className="kyrgyzstancard">
                <p>Kyrgyzstan is a land of untamed beauty and rugged terrain, where adventure tourism is waiting to be explored. From the soaring peaks of the Tien Shan mountains to the crystal-clear waters of Lake Issyk-Kul, there&#8217;s no shortage of natural wonders to discover. Here are some wise and inspiring words about the adventure tourism potential of Kyrgyzstan.</p>
                <p>The beauty of Kyrgyzstan is not just in its landscapes, but in the challenge, it poses to the adventurous soul. Dare to explore, and you will be rewarded with memories that will last a lifetime.</p>
                <div className='kyrgyzstancardimg'><img src={Kyrgyzstancardimg} alt="" /></div>
            </div>

            
            <div className="button_block">
                <button className='btn_1'>1</button>
                <Link to="/kyrgyzstanpage" className='btn_2'>2</Link>
                <Link to="/kyrgyzstanpage"  className="btn_next">Next »</Link>
            </div>


        </section>
    )
}
