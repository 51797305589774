import React from 'react';
import { Link } from 'react-router-dom';
// fonawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
// react icons
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
// img
import KgMainFoto1 from '../../images/KgMainFoto/kgmainfoto_1.jpg';
import KgMainFoto2 from '../../images/KgMainFoto/kgmainfoto_2.jpg';
import KgMainFoto3 from '../../images/KgMainFoto/kgmainfoto_3.jpg';
import KgMainFoto4 from '../../images/KgMainFoto/kgmainfoto_4.jpg';
import KgMainFoto5 from '../../images/KgMainFoto/kgmainfoto_5.jpg';
import KgMainFoto6 from '../../images/KgMainFoto/kgmainfoto_6.jpg';
import KgMainFoto7 from '../../images/KgMainFoto/kgmainfoto_7.jpg';
import KgMainFoto8 from '../../images/KgMainFoto/kgmainfoto_8.jpg';
import KgMainFoto9 from '../../images/KgMainFoto/kgmainfoto_9.jpg';
import KgMainFoto10 from '../../images/KgMainFoto/kgmainfoto_10.jpg';
import KgMainFoto11 from '../../images/KgMainFoto/kgmainfoto_11.jpg';
import KgMainFoto12 from '../../images/KgMainFoto/kgmainfoto_12.jpg';
import KgMainFoto13 from '../../images/KgMainFoto/kgmainfoto_13.jpg';
import KgMainFoto14 from '../../images/KgMainFoto/kgmainfoto_14.jpg';
import KgMainFoto15 from '../../images/KgMainFoto/kgmainfoto_15.jpg';
import KgMainFoto16 from '../../images/KgMainFoto/kgmainfoto_16.jpg';
import KgMainFoto17 from '../../images/KgMainFoto/kgmainfoto_17.jpg';
import KgMainFoto18 from '../../images/KgMainFoto/kgmainfoto_18.png';
import KgMainFoto19 from '../../images/KgMainFoto/kgmainfoto_19.jpg';
import KgMainFoto20 from '../../images/KgMainFoto/kgmainfoto_20.jpg';

export default function KgMainTours() {
    return (
        <section className='kgmain_tours'>
            <div className="kgmain_tours_bg">
                <div className="kgmain_tours_bg_text">
                    <p className="kgmain_tours_bg_suptitle">
                        My country, the Republic of Kyrgyzstan, is a very ancient land. Most of it is occupied by mountains – the majestic Tien Shan and Pamir, the masters of heaven and earth, towering above the clouds. Nomads, free children of the mountains have lived here since ancient times. They left the carved runes on large stones – ancient writing, which was then lost. The bowels of the earth here are preserved gold and uranium, which in modern times people began to mine.
                    </p>
                    <p className="kgmain_tours_bg_suptitle">The Great Silk Road, along which traders in textiles and spices wandered, also left its mark in the history of Kyrgyzstan. And now the people of Kyrgyzstan are easy to climb. They are used to traveling, traveling on long trips to work, and still patiently waiting for loved ones to return home.</p>
                    <p className="kgmain_tours_bg_suptitle">Our republic from the time of the Soviet Union has another name – Kyrgyzstan. Our population is small – less than six million people. Some American metropolis has a larger population. But among nature, a person always sees and notices another person, does not lose him.</p>
                    <p className="kgmain_tours_bg_suptitle">Residents of Kyrgyzstan are excellent traders, livestock breeders. The harsh conditions of the mountains taught people to be hardy, to do their job wisely. Now the country’s economy is going through hard times. Our population is poor. But difficult times teach people not to shy away from any work, but also to fight for their rights, to protect the fruits of their labor, to seek a better fate.</p>
                    <div className="kgmain_tours_bg_suptitle_1">
                        And finally, I want to say again about the main asset of our country – nature. The fifth deepest lake in the world is in Kyrgyzstan. This pearl is Issyk-Kul. On the shores of the lake are wonderful places of recreation that attract tourists. Even during the Soviet Union, sanatoriums were built here, where you can improve your health after hard work. Another magical lake Merzbacher meets with icebergs on the water surface. The diversity of Kyrgyz nature amazes everyone who somehow penetrates its treasures
                    </div>
                </div>

                <div className="kgmain_tours_bg_card">

                    <div className="kgmain_img_card">
                        <a href={KgMainFoto1} className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto1} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </a>
                    </div>

                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto2} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto3} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto4} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto5} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto6} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>
                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto7} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto8} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto9} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto10} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto11} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto12} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto13} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto14} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto15} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto16} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                    <div className="kgmain_img_card">
                        <div className="kgmain_img">
                            <FontAwesomeIcon className='foto_search' icon={faMagnifyingGlass} size="xl" style={{ color: "#fff", }} />
                            <img src={KgMainFoto17} alt="" />
                            <div className="aboutus_img_shadow"></div>
                        </div>
                    </div>

                </div>

                <div className="datas_icons mt-[20px]">
                    <Link to="https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3Dhttps%253A%252F%252Fkyrgyzstan-tourism.com%252Ftours%252Fsingletrack-expedition-mountain-biking-marvels-in-kyrgyzstan%252F&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=ru_RU"><FaFacebookF className='datas_react_icons' /></Link>
                    <Link to="https://twitter.com/intent/tweet?via=NativeKyrgyzst1&text=%22Singletrack%20Expedition%3A%20Mountain%20Biking%20Marvels%20in%20Kyrgyzstan%22&url=https%3A%2F%2Fkyrgyzstan-tourism.com%2Ftours%2Fsingletrack-expedition-mountain-biking-marvels-in-kyrgyzstan%2F"><FaTwitter className='datas_react_icons' /></Link>
                    <Link to="https://www.instagram.com/kyrgyzstan_tourism/"><FaInstagram className='datas_react_icons' /></Link>
                    <Link to="https://kyrgyzstan-tourism.com/tours/singletrack-expedition-mountain-biking-marvels-in-kyrgyzstan/"><FaPinterestP className='datas_react_icons' /></Link>
                    <Link to="https://api.whatsapp.com/send?text=%22Singletrack%20Expedition%3A%20Mountain%20Biking%20Marvels%20in%20Kyrgyzstan%22%20https%3A%2F%2Fkyrgyzstan-tourism.com%2Ftours%2Fsingletrack-expedition-mountain-biking-marvels-in-kyrgyzstan%2F "><FaWhatsapp className='datas_react_icons' /></Link>
                </div>

                <div className="kgmain_bg_block">
                    <div className="kgmain_bg_card">Kyrgyzstan</div>
                    <div className="kgmain_bg_card">nature</div>
                    <div className="kgmain_bg_card">The Kyrgyz Republic</div>
                    <div className="kgmain_bg_card">the Silk Road</div>
                </div>
            </div>

            <div className="kgmain_related">
                <h1 className="kgmain_related_title">Related posts</h1>

                <div className="kgmain_related_block">
                    <div className="kgmain_card_bg">
                        <div className="kgmain_card">
                            <img src={KgMainFoto18} alt="" />
                        </div>

                        <h4 className='kgmain_related_text'>Bird of the year 2021</h4>
                        <p className='kgmain_related_suptitle'>The <br /> bearded vulture (Gypaetus barbatus) A bearded vulture is a large [...]</p>
                        <div className="kgmain_related_read">Read more →</div>
                    </div>

                    <div className="kgmain_card_bg">
                        <div className="kgmain_card">
                            <img src={KgMainFoto19} alt="" />
                        </div>

                        <h4 className='kgmain_related_text'>Burana tower</h4>
                        <p className='kgmain_related_suptitle'>The Burana Tower is a historical and architectural monument that dates [...]</p>
                        <div className="kgmain_related_read">Read more →</div>
                    </div>

                    <div className="kgmain_card_bg">
                        <div className="kgmain_card">
                            <img src={KgMainFoto20} alt="" />
                        </div>

                        <h4 className='kgmain_related_text'>The Silk Road</h4>
                        <p className='kgmain_related_suptitle'>The Silk Road is the only land route, from the end of the 1st century [...]</p>
                        <div className="kgmain_related_read">Read more →</div>
                    </div>
                </div>
            </div>
        </section>
    )
}